<template>
  <van-tabbar v-model="active" active-color="#f58195" inactive-color="#000" fixed route>
    <van-tabbar-item replace to="/home/maincontent" icon="wap-home">
      <span>首页</span>
      <!--      这是另一种写法，可以显示自定义图标，但是图标地址必须是网址-->
      <!--      <template #icon="props">-->
      <!--        <img :src="props.active ? icon.active : icon.inactive">-->
      <!--      </template>-->
    </van-tabbar-item>
    <van-tabbar-item replace to="/home/message" icon="chat" badge="3">
      <span>消息</span>
      <!--      <template #icon="props">-->
      <!--        <img :src="props.active ? icon2.active : icon2.inactive">-->
      <!--      </template>-->
    </van-tabbar-item>
    <van-tabbar-item replace to="/home/business" icon="bookmark">
      <span>社区事务</span>
      <!--      <template #icon="props">-->
      <!--        <img :src="props.active ? icon3.active : icon3.inactive">-->
      <!--      </template>-->
    </van-tabbar-item>
    <van-tabbar-item replace to="/home/mine" icon="manager">
      <span>我的</span>
      <!--      <template #icon="props">-->
      <!--        <img :src="props.active ? icon4.active : icon4.inactive">-->
      <!--      </template>-->
    </van-tabbar-item>
  </van-tabbar>
</template>
<script>
import {ref} from "vue";
import {Tabbar, TabbarItem} from 'vant';

export default {
  name: "Footer",
  setup() {
    const active = ref(0);
    const icon = {
      active: 'https://s3.bmp.ovh/imgs/2021/12/55cf6cfb831b4fc2.png',
      inactive: 'https://s3.bmp.ovh/imgs/2021/12/331ea483a440a781.png',
    };
    const icon2 = {
      active: 'https://s3.bmp.ovh/imgs/2021/12/dfd88b921d8710ee.png',
      inactive: 'https://s3.bmp.ovh/imgs/2021/12/b78ba068e7379bf8.png',
    };
    const icon3 = {
      active: 'https://s3.bmp.ovh/imgs/2021/12/8a052443da0eb0d5.png',
      inactive: 'https://s3.bmp.ovh/imgs/2021/12/f7b89076f94968dd.png',
    };
    const icon4 = {
      active: 'https://s3.bmp.ovh/imgs/2021/12/1b3276e65553aa1a.png',
      inactive: 'https://s3.bmp.ovh/imgs/2021/12/5b378d4f00b29d9c.png',
    };
    return {
      icon,
      icon2,
      icon3,
      icon4,
      active,
    };
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
}
</script>

<style scoped>

</style>
