import { createRouter, createWebHashHistory } from 'vue-router'
import Index from "../views/LoginAndRegister/Index"
import Home from "../views/Home/Home";
import MainContent from "../views/Home/components/MainContent/MainContent";
import Message from "../views/Message/Message";
import Business from "../views/Business/Business";
import Mine from "../views/Mine/Mine";
import ShareMessage from "../views/Home/components/ShareMessage";
import UserInfo from "../views/Mine/components/UserInfo";
import About from "../views/About";
import CommunityCertification from "../views/Mine/components/CommunityCertification";
import Administration from "../views/Mine/components/Administration/Administration";
import BusinessEdit from "../views/Mine/components/Administration/components/BusinessEdit";
import BusinessProcessing from "../views/Mine/components/Administration/components/BusinessProcessing/BusinessProcessing";
import MemberManage from "../views/Mine/components/Administration/components/MemberManage";
import BusinessProcessingList from "../views/Mine/components/Administration/components/BusinessProcessing/BusinessProcessingList";
import LoginLog from "../views/Mine/components/LoginLog";
import OperationLog from "../views/Mine/components/OperationLog";
import RegisterEmailVerification from "../views/RegisterEmailVerification";
import WriteLetterToMe from "../views/Mine/components/WriteLetterToMe";
import MyMail from "../views/Mine/components/MyMail";
import AccountSystemSetting from "../views/Mine/components/AccountSystemSetting";
import PunishUser from "../views/Mine/components/Administration/components/PunishUser";
import Inform from "../views/Mine/components/Administration/components/Inform";
import ForgetPassword from "../views/LoginAndRegister/components/ForgetPassword";

const routes = [
  {
    path: '/',
    redirect: "/index"
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404 not found',
      isLogin: false
    },
    component: () => import('../views/404')
  },
  // 所有未定义路由，全部重定向到404页
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/404')
  },
  //无网络时跳转
  {
    path: '/networkError',
    component: require('../views/NetworkError').default,
    name: 'NetworkError',
    meta: { title: '网络异常' }
  },
  // 注册邮箱验证
  {
    path: '/registerEmailVerification',
    name: 'RegisterEmailVerification',
    component: RegisterEmailVerification,
    meta: {title: "注册邮箱验证"},
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {title: "泡泡的InfinitySpace", index: 6}
  },
  {
    path: '/home',
    redirect: "/home/maincontent"
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
    meta: {title:"海里", index: 0}
  },
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {title: "重置密码", index: 0}
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta:{title: "主页",index: 1},
    children:[
      {
        path: '/home/sharemessage',
        name: 'ShareMessage',
        component: ShareMessage,
        meta: {title: "发帖 ", index: 6}
      },
      {
        path: '/home/maincontent',
        name: 'MainContent',
        component: MainContent,
        meta:{title: "首页", index: 2},
      },
      {
        path: '/home/message',
        name: 'Message',
        component: Message,
        meta: {title: "消息", index: 3},
      },
      {
        path: '/home/business',
        name: 'Business',
        component: Business,
        meta: {title: "业务办理", index: 4}
      },
      {
        path: '/home/mine',
        name: 'Mine',
        component: Mine,
        meta: {title: "我的", index: 5},
      },
      {
        path: '/home/mine/userinfo',
        name: 'UserInfo',
        component: UserInfo,
        meta: {title: "个人资料", index: 6}
      },
      {
        path: '/home/mine/communityCertification',
        name: 'CommunityCertification',
        component: CommunityCertification,
        meta: {title: "社区认证", index: 6}
      },
      {
        path: '/home/mine/writeLetterToMe',
        name: 'WriteLetterToMe',
        component: WriteLetterToMe,
        meta: {title: "给我写信", index: 6}
      },
      {
        path: '/home/mine/myMail',
        name: 'MyMail',
        component: MyMail,
        meta: {title: "我的信件", index: 6}
      },
      {
        path: '/home/mine/accountSystemSetting',
        name: 'AccountSystemSetting',
        component: AccountSystemSetting,
        meta: {title: "账号设置", index: 6}
      },
      {
        path: '/home/mine/administration',
        name: 'Administration',
        component: Administration,
        meta: {title: "管理员选项", index: 6},
      },
      {
        path: '/home/mine/LoginLog',
        name: 'LoginLog',
        component: LoginLog,
        meta: {title: "登录记录", index: 6},
      },
      {
        path: '/home/mine/OperationLog',
        name: 'OperationLog',
        component: OperationLog,
        meta: {title: "操作记录", index: 6},
      },
      {
        path: '/home/mine/administration/memberManage',
        name: 'MemberManage',
        component: MemberManage,
        meta: {title: "成员管理", index: 7},
      },
      {
        path: '/home/mine/administration/inform',
        name: 'Inform',
        component: Inform,
        meta: {title: "投诉举报", index: 7},
      },
      {
        path: '/home/mine/administration/punishUser',
        name: 'PunishUser',
        component: PunishUser,
        meta: {title: "封禁用户", index: 7},
      },
      {
        path: '/home/mine/administration/businessEdit',
        name: 'BusinessEdit',
        component: BusinessEdit,
        meta: {title: "事务编辑", index: 7},
      },
      {
        path: '/home/mine/administration/businessProcessing',
        name: 'BusinessProcessing',
        component: BusinessProcessing,
        meta: {title: "事务处理", index: 7},
      },
      {
        path: '/home/mine/administration/businessProcessingList',
        name: 'BusinessProcessingList',
        component: BusinessProcessingList,
        meta: {title: "事务申请列表", index: 8},
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  //给当前激活的一个路由添加一个类
  linkActiveClass:"is-active",
})
// 添加路由守卫，动态的设置标题
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})

export default router
