<template>
  <div>
    <van-nav-bar
        title=好友列表
        left-arrow
        fixed
        @click-left="onClickLeft"
        @click-right="onClickRight"
    >
      <template v-if="isMyFriendList === '0'" #right>
        <van-icon name="friends" size="18" />
      </template>
    </van-nav-bar>

    <div style="margin-top: 50px"></div>

    <van-empty v-if="friendList.length === 0 && !showSkeleton" description="还没有好友哦">
      <van-button v-if="isMyFriendList === '0'" round type="primary" class="bottom-button" @click="onClickRight">加一个？</van-button>
    </van-empty>

    <van-skeleton v-if="showSkeleton" avatar-size="50px" title-width="100%" round title avatar :row="1" />
    <van-swipe-cell v-else v-for="(item, index) in friendList" :key="index">
<!--      <template #left>-->
<!--        <van-button square type="primary" text="选择" />-->
<!--      </template>-->
      <van-cell @click="goSingleChatting(item.mobilePhone, item.username, item.userHead)" :title="item.username" :value="item.characterSignature" title-style="text-align: left" />
      <template #right>
        <van-button @click="deleteFriend" square type="danger" text="删除" />
      </template>
    </van-swipe-cell>

  </div>
</template>

<script>
import {NavBar, SwipeCell, Cell, Notify, Skeleton, Empty, Icon} from 'vant';
export default {
  name: "FriendsList",
  components:{
    [NavBar.name]: NavBar,
    [SwipeCell .name]: SwipeCell,
    [Cell .name]: Cell,
    [Empty .name]: Empty,
    [Icon .name]: Icon,
    [Skeleton .name]: Skeleton,
  },
  props:{
    isMyFriendList: String
  },
  data(){
    return{
      showFriendsListDialog: true,
      showSkeleton: true,
      friendList: [],
      toUserMobilePhone: '',
      showAddFriendDialog: false,
    }
  },
  methods:{
    onClickLeft(){
      this.showFriendsListDialog = false
      this.$emit('showFriendsListDialog', this.showFriendsListDialog)
    },
    onClickRight(){
      this.$emit('showAddFriendDialog')
    },
    getFriendList(){
      this.$axios
          .get("/friend/getFriendList")
          .then(successResponse => {
            this.friendList = []
            for (let i = 0;i < successResponse.data.data.length;i++){
              this.friendList.push(successResponse.data.data[i])
            }
            this.showSkeleton = false
          })
          .catch(() => {
            Notify('登陆过期，请重新登录')
          })
    },
    deleteFriend(){
      Notify('就不让你删')
    },
    goSingleChatting(mobilePhone, username, userHead){
      this.$emit("changeSingleChattingDialog", mobilePhone, username, userHead)
    },
  },
}
</script>

<style scoped>

</style>