<template>
  <div>
    <van-nav-bar
        title=好友申请
        left-arrow
        fixed
        @click-left="onClickLeft"
    >
    </van-nav-bar>

    <div style="margin-top: 50px"></div>

    <van-skeleton v-if="showSkeleton" style="margin-top: 50px" avatar-size="50px" title-width="100%" round title avatar :row="1" />
    <van-cell v-else v-for="(item, index) in friendApplyList" :key="index" :title="item.applyFromUsername" style="text-align: left;font-size: 1.1rem">
      <!-- 使用 right-icon 插槽来自定义右侧图标 -->
      <template #extra>
        <van-button :disabled="complete" @click="agreeApply(item.applyFromMobilePhone, item.applyToMobilePhone, index)" style="height: 30px;width: 60px;margin-right: 5px" round type="primary">
          同意
        </van-button>
        <van-button :disabled="complete" @click="refuseApply(item.id, item.applyFromMobilePhone, item.applyToMobilePhone, index)" style="height: 30px;width: 60px" round type="warning">
          拒绝
        </van-button>
      </template>
    </van-cell>


  </div>
</template>

<script>
import {Skeleton, Cell, CellGroup, NavBar, Notify} from 'vant';
export default {
  name: "FriendsApply",
  components:{
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Skeleton.name]: Skeleton,
  },
  data(){
    return{
      showSkeleton: true,
      showFriendsApplyDialog: true,
      complete: false,
      friendApplyList: [],
    }
  },
  methods:{
    onClickLeft(){
      this.showFriendsApplyDialog = false
      this.$emit('showFriendsApplyDialog',this.showFriendsApplyDialog)
    },
    getFriendApply(){
      this.$axios
          .get("/friend/getFriendApply")
          .then(successResponse => {
            this.friendApplyList = []
            for (let i = 0;i < successResponse.data.data.length;i++){
              this.friendApplyList.push(successResponse.data.data[i])
            }
            this.showSkeleton = false
          })
          .catch(() => {
            Notify('登陆过期，请重新登录')
          })
    },
    agreeApply(applyFromMobilePhone, applyToMobilePhone, index){
      this.complete = true
      this.$axios
          .post("/friend/agreeApply", {
            applyFromMobilePhone: applyFromMobilePhone,
            applyToMobilePhone: applyToMobilePhone,
          })
          .then(() => {
            for (let i = 0; i < this.friendApplyList.length; i++) {
              if (this.friendApplyList[i].applyFromMobilePhone === applyFromMobilePhone && this.friendApplyList[i].applyToMobilePhone === applyToMobilePhone){
                this.friendApplyList.splice(index, 1)
              }
            }
            let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
            userInfo.friendNum ++
            sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
            this.complete = false
          })
          .catch(() => {
            Notify('登陆过期，请重新登录')
          })
    },
    refuseApply(id, applyFromMobilePhone, applyToMobilePhone, index){
      this.complete = true
      this.$axios
          .post("/friend/refuseApply", {
            id: id
          })
          .then(() => {
            for (let i = 0; i < this.friendApplyList.length; i++) {
              if (this.friendApplyList[i].applyFromMobilePhone === applyFromMobilePhone && this.friendApplyList[i].applyToMobilePhone === applyToMobilePhone){
                this.friendApplyList.splice(index, 1)
              }
            }
            let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
            userInfo.friendNum--
            sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
            this.complete = false
          })
          .catch(() => {
            Notify('登陆过期，请重新登录')
          })
    },
  },
}
</script>

<style scoped>

</style>