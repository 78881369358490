<template>
  <div class="main">
    <van-nav-bar
        title="事务处理"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <div style="margin-top: 5px"></div>
    <van-skeleton style="height: 40px;background-color: #e2e5ea;" v-if="showSkeleton" avatar-size="35px" title-width="100%" round title avatar :row="0"/>
    <van-skeleton style="height: 40px;background-color: #e2e5ea;" v-if="showSkeleton" avatar-size="35px" title-width="100%" round title avatar :row="0"/>
    <van-cell-group v-else v-for="(item, index) in communityList" :key="index">
      <van-cell :title="item.communityName" @click="goProcessBusinessList(item.communityName)" title-style="text-align: left"/>
    </van-cell-group>

  </div>
</template>

<script>
//vue-router里的方法只能在setup中使用
import { useRouter } from 'vue-router';
import {Skeleton, Cell, CellGroup, NavBar, Notify} from 'vant';

export default {
  name: "BusinessProcessing",
  setup() {
    const router = useRouter()
    const onClickLeft = () => history.back();
    const goProcessBusinessList = (value) => {
      //vue-router在4.1版本以后，路由传参出现了大变动，一般来讲，name搭配params传参会失败
      router.push({path: 'businessProcessingList', query: {communityName: value}})
    }

    return {
      router,
      onClickLeft,
      goProcessBusinessList
    };
  },
  components: {
    [NavBar.name]: NavBar,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Skeleton.name]: Skeleton,
  },
  data() {
    return {
      completeSubmit: false,
      showSkeleton: true,
      communityList: [],
    }
  },
  mounted() {
    this.getCommunityList();
  },
  methods: {
    getCommunityList() {
      this.$axios
          .get("/community/getCommunity")
          .then(successResponse => {
            this.communityList = []
            for (let i = 0; i < successResponse.data.data.length; i++) {
              this.communityList.push(successResponse.data.data[i])
            }
            this.showSkeleton = false
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    },
  },
}
</script>

<style scoped>
.main {
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
}
</style>