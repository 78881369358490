<template>
  <div class="main">
    <van-nav-bar
        title="加入社区"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <br>

    <van-form>
      <van-cell-group inset>
        <van-field
            v-model="community"
            type="textarea"
            readonly
            is-link
            style="height: 50px"
            label="社区"
            placeholder="选择社区"
            @click="showPicker = true"
        />
        <van-popup v-model:show="showPicker" round position="bottom">
          <van-picker
              title="社区"
              :columns="columns"
              @cancel="showPicker = false"
              @confirm="onConfirm"
          />
        </van-popup>
        <van-field style="height: 50px" type="textarea" v-model="identityNumber" label="识别码" placeholder="输入社区识别码"/>
      </van-cell-group>

      <div style="margin: 16px;">
        <van-button @click="submit" :disabled="completeSubmit" style="height: 40px" round block type="primary">
          提交认证
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import router from "@/router";
import {ref} from 'vue';
import {Form, Field, CellGroup, Notify, Popup, NavBar, Picker} from 'vant';

export default {
  name: "CommunityCertification",
  setup() {
    const onClickLeft = () => history.back();
    const identityNumber = ref('')

    const showPicker = ref(false);
    const community = ref('')
    const columns = ['温州商学院', '信阳', '温州', '碧海名居'];

    const onConfirm = (value) => {
      community.value = value
      showPicker.value = false;
    };

    return {
      identityNumber,
      onClickLeft,
      columns,
      showPicker,
      onConfirm,
      community
    };
  },
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  data() {
    return {
      completeSubmit: false,
    }
  },
  methods: {
    submit() {
      if (this.community === '') {
        Notify('要先选择想要加入的社区哦')
      } else if (this.identityNumber === '') {
        Notify('填写社区识别码才能加入新社区哦')
      } else {
        this.completeSubmit = true
        this.$axios
            .post("/community/certificate", {
              communityName: this.community,
              communityIdentityNumber: this.identityNumber,
            })
            .then(successResponse => {
              if (successResponse.data.data.code === 200) {
                router.replace({path: '/home/mine'})
                Notify({type: 'success', message: '保存成功'})
              } else if (successResponse.data.data.msg === '社区识别码错误') {
                Notify('社区识别码错误')
              } else if (successResponse.data.data.msg === '社区加入重复') {
                Notify('您已经是该社区成员了，不用重复加入啦')
              }
              this.completeSubmit = false
            })
            .catch(() => {
              Notify('登录过期，请重新登录')
              this.completeSubmit = false
            })
      }
    },
  },
}
</script>

<style scoped>
.main {
  height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
}
</style>