<template>
  <div class="main">
    <van-nav-bar
        title="投诉举报"
        left-text="返回"
        fixed
        style="padding-top: 50px"
        left-arrow
        @click-left="onClickLeft"
    />

    <van-pull-refresh style="padding-top: 45px;background-color: #ffd45d" pulling-text="别拉我！" loosing-text="畜生 快松开我！" loading-text="哎呦歪" v-model="loading" @refresh="pullFresh">
      <div style="min-height: calc(100vh - 140px);background-color: #f2f4f5;padding-top: 5px">
        <van-skeleton style="height: 70px;background-color: #e2e5ea;" v-if="showSkeleton" avatar-size="45px" title-width="100%" round title avatar :row="3" />
        <van-cell-group v-else v-for="(item, index) in informProcessingList" :key="index">
          <van-cell :class="item.isProcessed === -1 ? 'notRead':'read'" :title="item.informType" :value="item.informerUsername" label="查看详情" @click="goProcessInform(item, index)" title-style="text-align: left" />
        </van-cell-group>
      </div>
    </van-pull-refresh>

    <van-dialog :show-confirm-button="false" style="min-height: 70%;width: 85%" v-model:show="showDialog" title="举报详情">
      <van-cell-group style="text-align: left">
        <van-cell title="举报人" :value="informDetails.informerUsername"/>
        <van-cell title="被举报人" :value="informDetails.informedUsername"/>
        <van-cell title="举报类型" :value="informDetails.informType"/>
        <van-cell title="详细描述" :value="informDetails.informDetails"/>
        <van-cell title="被举报帖子" value="去处理" @click="getInformedBlog" />
        <van-cell title="举报时间" :value="informDetails.informTime" @click="getInformedBlog" />
      </van-cell-group>
      <div v-if="this.informDetails.isProcessed === -1" style="width: 100%;position: absolute;bottom: 45px;left: 0">
        <van-button @click="agreeInform" :disabled="buttonDisabled" style="width: 50%;" icon="checked" type="primary">同意</van-button>
        <van-button @click="refuseInform" style="width: 50%;" color="indianred" icon="clear" type="warning">驳回</van-button>
      </div>
      <van-button v-if="this.informDetails.isProcessed === -1" @click="showDialog = false" style="width: 100%;position: absolute;bottom: 0;left: 0" type="default">暂不处理</van-button>
      <van-button v-else @click="showDialog = false" style="width: 100%;position: absolute;bottom: 0;left: 0" type="default">已处理</van-button>
    </van-dialog>

    <van-dialog :show-confirm-button="false" style="min-height: 30%;width: 85%"  title="请填写处理回复" v-model:show="showDialog2">
      <van-field type="textarea" v-model="this.informDetails.reply" :rows="15" maxlength="200" />
      <van-button @click="processInform" :disabled="buttonDisabled" style="width: 50%;position: absolute;bottom: 0;left: 0" icon="checked" type="primary">确定</van-button>
      <van-button @click="cancelProcess" :disabled="buttonDisabled" style="width: 50%;position: absolute;bottom: 0" color="indianred" icon="clear" type="warning">取消</van-button>
    </van-dialog>

    <van-dialog :show-confirm-button="false" style="min-height: 70%;width: 85%" v-model:show="showDialog3" title="被举报帖子">
      <van-cell-group style="text-align: left">
        <van-cell title="所属社区" :value="blog.belongCommunity"/>
        <van-cell title="发帖人" :value="blog.postedUsername"/>
        <van-cell title="帖子内容" :value="blog.blogContent"/>
        <van-cell title="帖子评论" :value="blog.blogComments"/>
        <van-cell title="帖子图片" value="懒得做" />
      </van-cell-group>
      <div style="width: 100%;position: absolute;bottom: 45px;left: 0">
        <van-button @click="punishUser" :disabled="buttonDisabled" style="width: 50%;" icon="checked" type="primary">封禁用户</van-button>
        <van-button @click="warningUser" style="width: 50%;" color="indianred" icon="clear" type="warning">警告用户</van-button>
      </div>
      <van-button @click="showDialog3 = false" style="width: 100%;position: absolute;bottom: 0;left: 0" type="default">返回</van-button>
    </van-dialog>

    <van-dialog :show-confirm-button="false" style="min-height: 30%;width: 85%"  title="请填写处理通知" v-model:show="showDialog4">
      <van-field type="textarea" v-model="this.informDetails.punishReason" :rows="15" />
      <van-button @click="submitPunishUser" :disabled="buttonDisabled" style="width: 50%;position: absolute;bottom: 0;left: 0" icon="checked" type="primary">确定</van-button>
      <van-button @click="this.showDialog4 = false" :disabled="buttonDisabled" style="width: 50%;position: absolute;bottom: 0" color="indianred" icon="clear" type="warning">取消</van-button>
    </van-dialog>

  </div>
</template>

<script>
import {Skeleton, Cell, CellGroup, NavBar, Notify, Toast, PullRefresh, Icon, Dialog, Field} from 'vant';
import {ref} from "vue";
export default {
  name: "Inform",
  setup() {
    const onClickLeft = () => history.back();
    //下拉刷新
    const loading = ref(false);

    return {
      onClickLeft,
      loading,
    };
  },
  components:{
    [NavBar.name]: NavBar,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Skeleton.name]: Skeleton,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
  },
  data(){
    return{
      showSkeleton: true,
      showDialog: false,
      showDialog2: false,
      showDialog3: false,
      showDialog4: false,
      buttonDisabled: false,
      informProcessingList: [],
      informDetails: {},
      communityName: '',
      businessName: '',
      businessApplyId: '',
      index: 0,
      punishOrWarning: 0,
      blog: {},
    }
  },
  mounted() {
    this.getInformProcessingList()
  },
  methods:{
    getInformProcessingList(){
      this.showSkeleton = true
      this.$axios
          .get("/inform/getInformList")
          .then(successResponse => {
            this.informProcessingList = successResponse.data.data
            this.showSkeleton = false
          })
          .catch(failResponse => {
            console.log(failResponse)
            Notify('登录过期，请重新登录')
          })
    },
    pullFresh(){
      this.showSkeleton = true
      this.loading = true
      this.getInformProcessingList()
      setTimeout(() => {
        Toast({
          message: '刷新成功',
          icon: 'like-o',
        });
        this.loading = false;
      }, 500);
    },
    goProcessInform(item, index){
      this.informDetails = item
      this.index = index
      this.showDialog = true
    },
    agreeInform(){
      this.informDetails.isProcessed = 1
      this.showDialog2 = true
    },
    refuseInform(){
      this.informDetails.isProcessed = 0
      this.showDialog2 = true
    },
    processInform(){
      this.buttonDisabled = true
      this.$axios
          .post("/inform/processInform", this.informDetails)
          .then(successResponse => {
            this.showDialog2 = false
            this.informProcessingList[this.index].isProcessed = this.informDetails.isProcessed
            this.buttonDisabled = false
            if (successResponse.data.code === 5007) {
              Notify({ type: 'warning', message: '投诉已被其他管理员处理' });
              this.getInformProcessingList()
            } else {
              Notify({ type: 'success', message: '已处理投诉' });
            }
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
            this.buttonDisabled = false
          })
    },
    cancelProcess(){
      this.informDetails.isProcessed = -1
      this.showDialog2 = false
    },
    getInformedBlog(){
      this.$axios
          .get("/inform/getInformedBlog", {
            params:{
              informedBlogId: this.informDetails.informedBlogId,
            }
          })
          .then(successResponse => {
            this.blog = successResponse.data.data
            this.showDialog3 = true
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
            this.buttonDisabled = false
          })
    },
    punishUser(){
      this.punishOrWarning = 1
      this.showDialog4 = true
    },
    warningUser(){
      this.punishOrWarning = 0
      this.showDialog4 = true
    },
    submitPunishUser(){
      this.buttonDisabled = true
      this.informDetails.punishOrWarning = this.punishOrWarning
      this.$axios
          .post("/inform/punishUser", this.informDetails)
          .then(successResponse => {
            this.showDialog4 = false
            Notify({ type: 'success', message: '已发送并同时删除该帖' });
            this.deleteBlog()
            this.buttonDisabled = false
            console.log(successResponse.status)
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
            this.buttonDisabled = false
          })
    },
    deleteBlog(){
      this.$axios.get("/blog/delete", {
            params:{
              id: this.informDetails.informedBlogId,
            }
          })
    },
  },
}
</script>

<style scoped>
.main{
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
}
.notRead{
  background-color: lightblue
}
</style>