<template>
  <div class="main">
    <van-nav-bar
        title="我的信件"
        left-text="返回"
        fixed
        style="padding-top: 50px"
        left-arrow
        @click-left="onClickLeft"
    />

    <van-empty v-if="myMailList.length === 0 && !showSkeleton" description="还没有收到邮件哦，快给作者写信，就会收到回复啦">
      <van-button round type="primary" class="bottom-button" @click="this.$router.push({name: 'WriteLetterToMe'})">写一个？</van-button>
    </van-empty>

    <van-pull-refresh v-else style="padding-top: 45px;background-color: #ffd45d" pulling-text="别拉我！" loosing-text="畜生 快松开我！" loading-text="哎呦歪" v-model="loading" @refresh="pullFresh">
      <div style="min-height: calc(100vh - 140px);background-color: #f2f4f5;padding-top: 5px">
        <van-skeleton style="height: 70px;background-color: #e2e5ea;" v-if="showSkeleton" avatar-size="45px" title-width="100%" round title avatar :row="3" />
        <van-cell-group v-else v-for="(item, index) in myMailList" :key="index">
          <van-cell :class="item.isRead ? 'read':'notRead'" :title="item.letterType" :value="item.fromName" :label="item.postedTime" @click="goProcessMail(index)" title-style="text-align: left" />
        </van-cell-group>
      </div>
    </van-pull-refresh>

    <van-dialog :show-confirm-button="false" style="min-height: 70%;width: 85%" v-model:show="showDialog" title="邮件内容">
      <van-cell-group style="text-align: left">
        <van-cell title="发送人" title-style="max-width: 50px" :value="this.fromName"/>
        <van-cell title="邮箱" title-style="max-width: 50px" :value="this.fromEmail"/>
        <van-cell title="时间" title-style="max-width: 50px" :value="this.postedTime"/>
        <van-field readonly type="textarea" v-model="this.content" label="内容" :rows="10" maxlength="3000" show-word-limit />
      </van-cell-group>
      <div style="width: 100%;position: absolute;bottom: 0;left: 0">
        <van-button v-if="!this.myMailList[this.index].isReply" @click="handleReplyMail" :disabled="buttonDisabled" style="width: 50%;" icon="invitation" type="primary">回信</van-button>
        <van-button v-else :disabled="true" style="width: 50%;" icon="invitation" type="primary">已回信</van-button>
        <van-button v-if="!this.myMailList[this.index].isRead" @click="readMail" style="width: 50%;" color="indianred" icon="checked" type="warning">已阅</van-button>
        <van-button v-else @click="this.showDialog = false" style="width: 50%;" color="indianred" icon="clear" type="warning">返回</van-button>
      </div>
    </van-dialog>

    <van-dialog :show-confirm-button="false" style="min-height: 70%;width: 85%"  title="输入回复内容" v-model:show="showDialog2">
      <van-field type="textarea" v-model="replyContent" maxlength="3000" :rows="15" />
      <van-button @click="replyMail" :disabled="buttonDisabled" style="width: 50%;position: absolute;bottom: 0;left: 0" icon="checked" type="primary">确定</van-button>
      <van-button @click="this.showDialog2 = false" style="width: 50%;position: absolute;bottom: 0" color="indianred" icon="clear" type="warning">取消</van-button>
    </van-dialog>

  </div>
</template>

<script>
import {Skeleton, Cell, CellGroup, NavBar, Notify, Toast, PullRefresh, Icon, Dialog, Field, Empty} from 'vant';
import {ref} from "vue";
export default {
  name: "MyMail",
  setup() {
    const onClickLeft = () => history.back();
    //下拉刷新
    const loading = ref(false);

    return {
      onClickLeft,
      loading,
    };
  },
  components:{
    [NavBar.name]: NavBar,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Skeleton.name]: Skeleton,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [Empty.name]: Empty,
  },
  data(){
    return{
      showSkeleton: true,
      showDialog: false,
      showDialog2: false,
      buttonDisabled: false,
      myMailList: [],
      replyContent: '',
      fromName: '',
      fromEmail: '',
      fromMobilePhone: '',
      postedTime: '',
      content: '',
      index: 0,
    }
  },
  mounted() {
    this.getMyMailList()
  },
  methods:{
    getMyMailList(){
      this.showSkeleton = true
      this.$axios
          .get("/mail/getMyMailList")
          .then(successResponse => {
            this.myMailList = successResponse.data.data
            this.showSkeleton = false
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    },
    pullFresh(){
      this.showSkeleton = true
      this.loading = true
      this.getMyMailList()
      setTimeout(() => {
        Toast({
          message: '刷新成功',
          icon: 'like-o',
        });
        this.loading = false;
      }, 500);
    },
    goProcessMail(index){
      this.index = index
      this.showDialog = true
      this.fromName = this.myMailList[index].fromName
      this.fromEmail = this.myMailList[index].fromEmail
      this.fromMobilePhone = this.myMailList[index].fromMobilePhone
      this.postedTime = this.myMailList[index].postedTime
      this.content = this.myMailList[index].content
    },
    handleReplyMail(){
      this.showDialog2 = true
    },
    replyMail(){
      this.buttonDisabled = true
      this.$axios
          .post("/mail/replyMyMail",{
            toEmail: this.fromEmail,
            toName: this.fromName,
            toMobilePhone: this.fromMobilePhone,
            content: this.replyContent,
          })
          .then(() => {
            this.showDialog2 = false
            this.showDialog = false
            Notify({ type: 'success', message: '已回复邮件' });
            this.buttonDisabled = false
            this.myMailList[this.index].isRead = true
            this.myMailList[this.index].isReply = true
            this.readMail()
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
            this.buttonDisabled = false
          })
    },
    readMail(){
      this.myMailList[this.index].isRead = true
      this.showDialog = false
      this.$axios.post("/mail/readMyMail", this.myMailList[this.index])
    },
  },
}
</script>

<style scoped>
.main{
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
}
.notRead{
  background-color: lightblue
}
</style>