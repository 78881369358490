<template>
  <div class="main">
    <van-nav-bar
        title="账号设置"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <br>

    <van-cell-group inset>
      <van-field style="height: 50px" type="textarea" v-model="mobilePhone" label="账号" placeholder="修改手机号"/>
      <van-field style="height: 50px" type="textarea" v-model="email" label="邮箱" placeholder="修改邮箱"/>
      <van-field style="height: 50px" type="textarea" v-model="password" label="密码" placeholder="为空不会修改密码"/>
      <van-field style="height: 50px" type="textarea" v-model="rePassword" label="确认密码" placeholder="再次输入密码"/>
    </van-cell-group>

    <div style="margin: 16px;">
      <van-button @click="submit" :disabled="completeSubmit" style="height: 40px;margin-bottom: 20px" round block type="primary">
        提交
      </van-button>
      <van-button @click="cancellation" style="height: 40px" round block type="warning">
        注销账号
      </van-button>
    </div>

  </div>
</template>

<script>
import router from "@/router";
import {Field, CellGroup, Notify, Dialog} from 'vant';
import {NavBar} from 'vant';
//qs.parse()是将URL解析成对象的形式
//qs.stringify()是将对象 序列化成URL的形式，以&进行拼接
import Qs from 'qs';
import {SM4Util} from "@/utils/sm4";

export default {
  name: "AccountSystemSetting",
  setup() {
    const onClickLeft = () => history.back();

    return {
      onClickLeft
    };
  },
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar
  },
  data() {
    return {
      completeSubmit: false,
      user: {},
      password: '',
      rePassword: '',
      mobilePhone: '',
      email: '',
    }
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.mobilePhone = this.user.mobilePhone
    this.email = this.user.email
  },
  methods: {
    submit() {
      this.completeSubmit = true
      let mobilePhone = this.mobilePhone.replaceAll(' ', '')
      let email = this.email.replaceAll(' ', '')
      let password = this.password.replaceAll(' ', '')
      const phoneReg = new RegExp(/^\d{11}$/);
      if (email === '' || !email.includes('@')) {
        Notify('请输入正确的邮箱')
        this.completeSubmit = false
      } else if (!phoneReg.test(mobilePhone)) {
        Notify('仅支持中国大陆手机号')
        this.completeSubmit = false
      } else if (password !== this.rePassword.replaceAll(' ', '')) {
        Notify('两次输入的密码不同')
        this.completeSubmit = false
      } else {
        if (mobilePhone === this.user.mobilePhone && email === this.user.email && password === '') {
          router.go(-1)
          Notify({type: 'success', message: '好像什么都没有修改哦'})
        } else {
          const sm4 = new SM4Util()
          this.user.password = sm4.encryptDefault_CBC(password)
          this.user.mobilePhone = mobilePhone
          this.user.email = email
          this.$axios
              .put("/user/accountSystemSetting", this.user)
              .then(successResponse => {
                if (successResponse.data.code === 5005) {
                  Notify('校验未通过，请检查邮箱手机号等')
                } else {
                  router.replace({path: '/index'})
                  sessionStorage.removeItem('userInfo')
                  sessionStorage.removeItem('user')
                  Notify({type: 'success', message: '修改成功，请重新登录'})
                }
                this.completeSubmit = true
              })
              .catch(() => {
                this.completeSubmit = true
                Notify('登陆过期，请重新登录')
              })
        }
      }
    },
    cancellation() {
      Dialog.confirm({
        message: '确定要删除账号吗',
      })
          .then(() => {
            this.$axios
                .put("/user/cancellation",
                    //localStorage.getItem("mobilePhone")是{mobilePhone:18238264105}json形式，后台用@RequestParam接收，可以用Qs
                    Qs.stringify({
                      mobilePhone: localStorage.getItem("mobilePhone")
                    }))
                .then(() => {
                  localStorage.removeItem("mobilePhone")
                  localStorage.removeItem("password")
                  sessionStorage.removeItem('user')
                  sessionStorage.removeItem('user')
                  router.replace({name: 'Index'})
                  Notify({type: 'warning', message: '账号删除成功，后悔请联系我'})
                })
                .catch(failResponse => {
                  Notify('登陆过期，请重新登录')
                  console.log(failResponse)
                })
          })
          .catch(() => {
            // on cancel
          });
    },
  }
}
</script>

<style scoped>
.main {
  height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
}
</style>