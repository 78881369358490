<template>
  <div class="chatting">
    <van-nav-bar
        :title="receiveUsername"
        right-text="更多"
        left-arrow
        fixed
        @click-left="onClickLeft"
        @click-right="getUserInfoAndRelationship"
    >
      <template #right>
        <van-icon name="wap-nav" size="21" />
      </template>
    </van-nav-bar>

    <div style="height: 80vh">
      <div id="message"></div>
    </div>

    <div class="foot">
      <input type="text" v-model="postedMsg">
      <van-button style="width: 12%;height: 40px;margin-right: 1%" @click="send" icon="guide-o" round type="primary"></van-button>
    </div>
  </div>

  <van-dialog teleport="#app" :show-confirm-button="false" style="min-height: 70%;width: 85%" v-model:show="showDialog" title="用户资料">
    <div class="main">
      <div v-if="dialogShowSkeleton" class="imgAndName">
        <van-skeleton avatar-size="50px" title-width="100%" round title avatar :row="1" />
      </div>

      <div class="imgAndName" v-else>
        <img :src="userInfo1.userHead" height="50" width="50" alt=""/>
        <h1>{{ userInfo1.username }}</h1>
      </div>
    </div>
    <van-grid class="grid">
      <van-grid-item>
        <p style="font-size: 1.5rem">{{ userInfo1.communityNum }}</p>
        <p>TA的社区</p>
      </van-grid-item>
      <van-grid-item>
        <p style="font-size: 1.5rem">{{ userInfo1.blogNum }}</p>
        <p>TA的帖子</p>
      </van-grid-item>
      <van-grid-item>
        <p style="font-size: 1.5rem">{{ userInfo1.friendNum }}</p>
        <p>我的好友</p>
      </van-grid-item>
      <van-grid-item>
        <p style="font-size: 1.5rem">{{ userInfo1.businessNum }}</p>
        <p>事务记录</p>
      </van-grid-item>
    </van-grid>
    <van-cell-group style="text-align: left">
      <van-cell title="性别" :value="userInfo1.sex"/>
      <van-cell title="家乡" :value="userInfo1.homeTown"/>
      <van-cell title="个性签名" :value="userInfo1.characterSignature"/>
      <van-cell title="关系" :value="userInfo1.isFriend"/>
    </van-cell-group>
    <div style="width: 100%;position: absolute;bottom: 45px;left: 0">
      <van-button :disabled="addFriendButtonDisabled" style="width: 50%;" icon="plus" type="primary">加好友</van-button>
      <van-button @click="releaseFriend" style="width: 50%;" color="palevioletred" icon="envelop-o" type="primary">删除好友</van-button>
    </div>

    <van-button @click="this.showDialog = false" style="width: 100%;position: absolute;bottom: 0;left: 0" type="default">知道了</van-button>

  </van-dialog>
</template>

<script>
import {Dialog, NavBar, Notify, Cell, CellGroup, Skeleton, Grid, GridItem} from 'vant';
import { Toast } from 'vant';
import { Icon } from 'vant';
import {sendNotify} from "@/js/globalWebSocket";
export default {
  emits: ['showSingleChattingDialog'],
  name: "SingleChatting",
  data() {
    return {
      webSocket: null,
      message: '',
      postedMsg: '',
      messageLib: {},
      showSingleChattingDialog: true,
      disabledPullFresh: false,
      messageLibList: [],
      lastMessage: '',
      userInfo: {},
      userInfo1: {},
      showDialog: false,
      dialogShowSkeleton: true,
      addFriendButtonDisabled: true,
      storageKey: ''
    }
  },
  props:{
    receiveMobilePhone: String,
    receiveUsername: String,
    receiveUserHead: String,
  },
  components:{
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Skeleton.name]: Skeleton,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    //心跳检测
    this.timer = setInterval(this.start, 55000);
    this.storageKey = this.userInfo.mobilePhone + 'to' + this.receiveMobilePhone
  },
  methods: {
    getUserInfoAndRelationship(){
      this.showDialog = true
      this.dialogShowSkeleton = true
      this.addFriendButtonDisabled = true
      if (this.showDialog){
        this.$axios
            .get("/user/getUserInfoAndRelationship",{
              params:{
                mobilePhone: this.receiveMobilePhone
              }
            })
            .then(successResponse => {
              this.userInfo1 = successResponse.data.data
              this.addFriendButtonDisabled = this.userInfo1.isFriend === '好友';
              this.dialogShowSkeleton = false
            })
            .catch(() => {
              Notify('登录过期，请重新登录')
            })
      }
    },
    releaseFriend(){
      Notify('就不让你删')
    },
    getSingleMessageLib(){
      let messageBox = document.getElementById('message');
      while (messageBox.hasChildNodes()){
        messageBox.removeChild(messageBox.firstChild)
      }
      this.messageLibList = []
      if (JSON.parse(sessionStorage.getItem(this.storageKey)) === null) {
        this.$axios
            .get("/message/getSingleMessageLib", {
              params: {
                receiveMobilePhone: this.receiveMobilePhone,
              }
            })
            .then(successResponse => {
              for (let i = 0; i < successResponse.data.data.length; i++) {
                this.messageLibList.push({
                  sendUsername: successResponse.data.data[i].sendUsername,
                  sendMobilePhone: successResponse.data.data[i].sendMobilePhone,
                  message: successResponse.data.data[i].message,
                  messageTime: successResponse.data.data[i].messageTime,
                })
              }
              this.pushMessage(messageBox)
              sessionStorage.setItem(this.storageKey, JSON.stringify(this.messageLibList))
            })
            .catch(() => {
              Notify('登录过期，请重新登录')
            })
      } else {
        this.messageLibList = JSON.parse(sessionStorage.getItem(this.storageKey))
        this.pushMessage(messageBox)
      }
    },
    //推送消息到屏幕
    pushMessage(messageBox) {
      let length = this.messageLibList.length
      let cssCode = '';
      for (let i = length - 1; i > -1; i--) {
        let sendMobilePhoneLength = this.messageLibList[i].sendMobilePhone.length
        //判断是否是自己发送的消息，自己的消息放右边，对方放左边，系统消息居中
        if (sendMobilePhoneLength === 11 && this.messageLibList[i].sendMobilePhone === this.userInfo.mobilePhone) {
          cssCode = '<div style="float: right;width: 100%;margin-bottom: 15px"><p style="text-align:right;margin-right: 20px">' + this.messageLibList[i].sendUsername + '</p>' + '<div style="min-height: 30px;max-width: 60%;word-break: break-all;float: right;margin-right: 10px;padding: 5px 15px;border-radius: 20px;background-color: pink">' + '<div style="text-align:left">' + this.messageLibList[i].message + '</div>' + '</div></div>';
          messageBox.innerHTML += cssCode;
        } else if (sendMobilePhoneLength === 11 && this.messageLibList[i].sendMobilePhone !== this.userInfo.mobilePhone) {
          cssCode = '<div style="float: left;width: 100%;margin-bottom: 15px"><p style="text-align:left;margin-left: 20px">' + this.messageLibList[i].sendUsername + '</p>' + '<div style="min-height: 30px;max-width: 60%;word-break: break-all;float: left;margin-left: 10px;padding: 5px 15px;border-radius: 20px;background-color: pink">' + '<div style="text-align:left">' + this.messageLibList[i].message + '</div>' + '</div></div>';
          messageBox.innerHTML += cssCode;
        }
        this.nextTick()
      }
    },
    //消息自动滚动到底部
    nextTick(){
      this.$nextTick(() => {
        let msg = document.getElementById('message')
        // 滚动高度
        msg.scrollTop = msg.scrollHeight
      })
    },
    onClickLeft(){
      this.$emit('showSingleChattingDialog', this.lastMessage)
    },
    connectWebSocket(){
      //判断当前浏览器是否支持WebSocket
      if ('WebSocket' in window) {
        // this.webSocket = new WebSocket('ws://127.0.0.1:8081/undersea/myWebsocket/' + this.userInfo.mobilePhone);
        if (this.webSocket == null) {
          this.webSocket = new WebSocket('wss://undersea.xuyijie.icu/undersea/myWebsocket/' + this.userInfo.mobilePhone);
        }
      } else {
        Notify('该设备不支持websocket！')
      }
      //连接发生错误的回调方法
      this.webSocket.onerror = function() {
        this.message = "连接错误";
        //document.getElementById('message').innerHTML += this.message + '<br/>';
      }
      //连接成功建立的回调方法
      // this.webSocket.onopen = function() {
      //   this.message="Loc MSG: 成功建立连接";
      //   document.getElementById('message').innerHTML += this.message + '<br/>';
      // }
      //接收到消息的回调方法
      this.webSocket.onmessage = function(event) {
        let messageArray = event.data
        func1(messageArray)
      }
      //func1和func2是为了解决websocket无法直接调用vue的方法的问题的
      let func2 = function func3(messageArray) {
        //在此处即可同时使用websocket的数据和data数据，method函数
        let cssCode = '';
        let messageBox = document.getElementById('message');
        let mobilePhoneAndUsername = messageArray.substring(0, messageArray.lastIndexOf("："))
        let mobilePhone = mobilePhoneAndUsername.substring(0, 11)
        // let username = mobilePhoneAndUsername.substring(11, mobilePhoneAndUsername.length)
        let message = messageArray.substring(messageArray.lastIndexOf("：")).replace('：','')
        //判断是否是自己发送的消息，自己的消息放右边，对方放左边，系统消息居中
        if (mobilePhoneAndUsername === '系统消息' || mobilePhoneAndUsername.startsWith('恭喜')){
          // document.getElementById('message').innerHTML += event.data + '<br/>';
        }else if (mobilePhone !== this.userInfo.mobilePhone){
          cssCode = '<div style="float: left;width: 100%;margin-bottom: 15px"><p style="text-align:left;margin-left: 20px">' + this.receiveUsername + '</p>' + '<div style="min-height: 30px;max-width: 60%;word-break: break-all;float: left;margin-left: 10px;padding: 5px 15px;border-radius: 20px;background-color: pink">' + '<div style="text-align:left">' + message + '</div>' + '</div></div>';
          messageBox.innerHTML += cssCode;
        }else if (mobilePhone === this.userInfo.mobilePhone) {
          // cssCode = '<div style="float: right;width: 100%;margin-bottom: 15px"><p style="text-align:right;margin-right: 20px">' + username + '</p>' + '<div style="min-height: 30px;max-width: 60%;word-break: break-all;float: right;margin-right: 10px;padding: 5px 15px;border-radius: 20px;background-color: pink">' + '<div style="text-align:left">' + message + '</div>' + '</div></div>';
          // messageBox.innerHTML += cssCode;
        }
        this.nextTick()
      }
      let func1 = func2.bind(this)
      //连接关闭的回调方法
      this.webSocket.onclose = function() {
        this.message="Loc MSG:关闭连接";
        //document.getElementById('message').innerHTML += this.message + '<br/>';
      }
      //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      // window.onbeforeunload = function() {
      //   this.webSocket.close();
      // }
    },
    //关闭连接
    closeWebSocket() {
      this.webSocket.close();
    },
    //发送消息
    send() {
      if (this.message === '连接错误' || this.message === 'Loc MSG:关闭连接') {
        this.connectWebSocket()
        console.log('连接断开， 尝试重连')
      }
      this.messageLib.type = '1';
      this.messageLib.message = this.postedMsg
      this.messageLib.sendMobilePhone = this.userInfo.mobilePhone
      this.messageLib.sendUsername = this.userInfo.username
      this.messageLib.sendUserHead = this.userInfo.userHead
      this.messageLib.receiveMobilePhone = this.receiveMobilePhone
      this.messageLib.receiveUsername = this.receiveUsername
      this.messageLib.receiveUserHead = this.receiveUserHead
      let messageLibString = JSON.stringify(this.messageLib)
      this.webSocket.send(messageLibString);
      sendNotify(messageLibString)
      //推送消息到屏幕
      let messageBox = document.getElementById('message');
      let cssCode = '<div style="float: right;width: 100%;margin-bottom: 15px"><p style="text-align:right;margin-right: 20px">' + this.userInfo.username + '</p>' + '<div style="min-height: 30px;max-width: 60%;word-break: break-all;float: right;margin-right: 10px;padding: 5px 15px;border-radius: 20px;background-color: pink">' + '<div style="text-align:left">' + this.postedMsg + '</div>' + '</div></div>';
      messageBox.innerHTML += cssCode;
      this.nextTick()
      this.lastMessage = this.postedMsg
      this.postedMsg = ''
      let messageLibList = JSON.parse(sessionStorage.getItem(this.storageKey))
      messageLibList.unshift(this.messageLib)
      sessionStorage.setItem(this.storageKey, JSON.stringify(messageLibList))
    },
    // 心跳检测, 每隔一段时间检测连接状态，如果处于连接中，
    // 就向server端主动发送消息，来重置server端与客户端的最大连接时间，如果已经断开了，发起重连。
    start() {
      if (this.message === '连接错误' || this.message === 'Loc MSG:关闭连接') {
        this.connectWebSocket()
        console.log('连接断开， 尝试重连')
      } else {
        this.messageLib = {}
        this.messageLib.type = '心跳检测'
        this.webSocket.send(JSON.stringify(this.messageLib));
      }
    }
  }

}
</script>

<style scoped>
.chatting{
  background-color: white;
  padding-top: 50px;
  height: 100%;
}
#message{
  height: 90%;
  margin-top:10px;
  overflow: auto;
}
.foot{
  position: fixed;
  bottom: 0;
  width: 100%;
}
.foot input{
  border-radius: 20px;
  width: 85%;
  height: 40px;
  margin-right: 1%;
  margin-left: 1%
}
</style>
