<template>
  <div class="main">
    <van-nav-bar
        title="给我写信"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <br>

    <van-form>
      <van-cell-group inset>
        <van-popup v-model:show="showPicker" round position="bottom">
          <van-picker
              title="信件类型"
              :columns="columns"
              @cancel="showPicker = false"
              @confirm="onConfirm"
          />
        </van-popup>
        <van-field
            v-model="letterType"
            type="textarea"
            readonly
            is-link
            style="height: 50px"
            label="信件类型"
            placeholder="想告诉我些什么呢"
            @click="showPicker = true"
        />
        <van-field style="height: 50px;" type="textarea" v-model="name" maxlength="10" label="你的名字" placeholder="不想告诉我也没关系哦" />
        <van-field autosize type="textarea" v-model="content" label="内容" :rows="15" maxlength="3000" show-word-limit placeholder="在这里告诉我你想说的哦" />
      </van-cell-group>

      <div style="margin: 16px;">
        <van-button @click="submit" :disabled="completeSubmit" style="height: 40px" round block type="primary">
          发送邮件
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import router from "@/router";
import { ref } from 'vue';
import {Form, Field, CellGroup, Notify, Popup, NavBar, Picker} from 'vant';

export default {
  name: "WriteLetterToMe",
  setup() {
    const onClickLeft = () => history.back();
    const content = ref('')
    const letterType = ref('')

    const showPicker = ref(false);
    const name = ref('')
    const columns = ['谈谈心', '新的想法？', '投诉！！！', '就是想骂我而已'];

    const onConfirm = (value) => {
      letterType.value = value
      showPicker.value = false;
    };

    return {
      content,
      onClickLeft,
      columns,
      showPicker,
      onConfirm,
      name,
      letterType,
    };
  },
  components:{
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  data(){
    return{
      completeSubmit: false,
      user: {},
    }
  },
  methods:{
    submit(){
      if (this.letterType === ''){
        Notify('要先选择信件类型哦')
      }else {
        this.user = JSON.parse(sessionStorage.getItem('user'))
        this.completeSubmit = true
        this.$axios
            .post("/mail/writeLetterToMe", {
              fromEmail: this.user.email,
              fromMobilePhone: this.user.mobilePhone,
              fromName : this.name,
              letterType: this.letterType,
              content : this.content,
            })
            .then(() => {
              router.replace({path:'/home/mine'})
              this.completeSubmit = false
              Notify({type: 'success', message:'我很快就会回信哦'})
            })
            .catch(() => {
              this.completeSubmit = false
              Notify('登录过期，请重新登录')
            })
      }
    },
  },
}
</script>

<style scoped>
.main{
  height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
}
</style>