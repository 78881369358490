<template>
  <div>
    <van-nav-bar
        title=登录记录
        left-text="返回"
        fixed
        left-arrow
        style="padding-top: 50px"
        @click-left="onClickLeft"
    />

    <van-pull-refresh style="background-color: #ffd45d;padding-top: 95px" pulling-text="别拉我！" loosing-text="畜生 快松开我！" loading-text="哎呦歪" v-model="loading" @refresh="pullFresh">

      <div style="min-height: calc(100vh - 140px);background-color: #f2f4f5;padding-top: 5px">
        <van-cell-group>
          <van-cell @click="showDialog(index)" :title="'账号：' + item.mobilePhone" title-style="text-align: left"
                    :label="item.applyTime" :value="'设备：' + item.device" v-for="(item, index) in loginLogList"
                    :key="item.id"
          />
        </van-cell-group>
        <div style="height: 50px;line-height: 50px;margin-bottom: 50px">
          <p v-if="(this.loginLogList.length < this.pageTotal) && !this.showLoading" @click="getLoginLog">点击加载更多</p>
          <p v-if="this.loginLogList.length >= this.pageTotal">我也是有底线的</p>
          <van-loading v-if="this.showLoading" color="#1989fa"/>
        </div>
      </div>

    </van-pull-refresh>

    <van-dialog teleport="#app" v-model:show="show" title="登录详情">
      <van-cell-group>
        <van-cell title="登录账号" :value="loginLogList[this.index].mobilePhone" title-style="text-align: left"/>
        <van-cell title="登录IP" :value="loginLogList[this.index].ip" title-style="text-align: left"/>
        <van-cell title="IP属地" :value="loginLogList[this.index].ipLocation" title-style="text-align: left"/>
        <van-cell title="卫星定位" :value="loginLogList[this.index].gpsLocation" title-style="text-align: left"/>
        <van-cell title="登录设备" :value="loginLogList[this.index].device" title-style="text-align: left"/>
        <van-cell title="登录时间" :value="loginLogList[this.index].applyTime" title-style="text-align: left"/>
        <van-cell title="登录结果" :value="loginLogList[this.index].isSuccessful ? '成功' : '失败'" title-style="text-align: left"/>
        <van-cell v-if="!loginLogList[this.index].isSuccessful" title="失败原因" :value="loginLogList[this.index].failReason" title-style="text-align: left"/>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import {ref} from "vue";
import {Notify, PullRefresh, Toast, Dialog, Cell, CellGroup, Field, NavBar, Loading} from "vant";

export default {
  name: "LoginLog",
  setup() {
    const onClickLeft = () => history.back();
    //下拉刷新
    const count = ref(0);
    const loading = ref(false);

    return {
      onClickLeft,
      count,
      loading,
    };
  },
  data() {
    return {
      loginLogList: [],
      show: false,
      index: 0,
      pageNum: 1,
      pageQuantity: 10,
      pageTotal: 0,
      searchString: '',
      showLoading: false,
    }
  },
  components: {
    [PullRefresh.name]: PullRefresh,
    [Toast.name]: Toast,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [NavBar.name]: NavBar,
    [Loading.name]: Loading,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
  },
  mounted() {
    this.getLoginLog()
  },
  methods: {
    showDialog(index) {
      this.show = true
      this.index = index
    },
    pullFresh() {
      this.pageNum = 1
      this.loginLogList = []
      this.getLoginLog()
      setTimeout(() => {
        Toast({
          message: '刷新成功',
          icon: 'like-o',
        });
        this.loading = false;
      }, 500);
    },
    getLoginLog() {
      this.showLoading = true
      this.$axios
          .get("/loginAndOperationLog/getLoginLog", {
            params: {
              searchString: '',
              pageNum: this.pageNum++,
              pageQuantity: this.pageQuantity
            }
          })
          .then(successResponse => {
            this.pageTotal = successResponse.data.data.total
            for (let i = 0; i < successResponse.data.data.records.length; i++) {
              this.loginLogList.push(successResponse.data.data.records[i])
            }
            this.showLoading = false
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    }
  }
}
</script>

<style scoped>

</style>
