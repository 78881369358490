<template>
  <div class="main">
    <van-nav-bar
        title="重置密码"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <br>

    <van-cell-group inset>
      <van-field style="height: 55px" type="textarea" v-model="mobilePhone" label="账号" placeholder="输入手机号"/>
      <van-field
          style="height: 55px;"
          v-model="verificationCode"
          type="textarea"
          label="短信验证"
          placeholder="输入验证码"
      >
        <template #button>
          <van-button v-if="afterGetCode" @click="getVerificationCode" size="small" type="primary" style="margin-bottom: 30px" >获取验证码</van-button>
          <van-button v-else size="small" type="primary" style="margin-bottom: 30px" disabled>
            {{ remainSecond }}s再次获取
          </van-button>
        </template>
      </van-field>
      <van-field style="height: 55px" type="textarea" v-model="password" label="密码" placeholder="请输入新密码"/>
      <van-field style="height: 55px" type="textarea" v-model="rePassword" label="确认密码" placeholder="再次输入密码"/>
    </van-cell-group>

    <div style="margin: 16px;">
      <van-button @click="submit" :disabled="completeSubmit" style="height: 40px;margin-bottom: 20px" round block type="primary">
        提交
      </van-button>
    </div>

  </div>
</template>

<script>
import router from "@/router";
import {Field, CellGroup, Notify, NavBar} from 'vant';
import {SM4Util} from "sm4util";
import {ref, watch} from "vue";

export default {
  name: "ForgetPassword",
  setup() {
    const onClickLeft = () => history.back();
    const afterGetCode =  ref(true)
    const remainSecond =  ref(60)
    //用于清除定时器
    const timer =  ref()

    const second = () => {
      if (remainSecond.value > 0) {
        timer.value = setInterval(() => {
          remainSecond.value--
        }, 1000)
      }
    }

    watch(remainSecond, (newValue) => {
      if (newValue === 0) {
        afterGetCode.value = true
        clearInterval(timer.value)
        remainSecond.value = 60
      }
    });

    return {
      onClickLeft,
      afterGetCode,
      remainSecond,
      second
    };
  },
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar
  },
  data() {
    return {
      completeSubmit: false,
      password: '',
      rePassword: '',
      mobilePhone: '',
      verificationCode: '',
    }
  },
  mounted() {

  },
  methods: {
    getVerificationCode() {
      const phoneReg = new RegExp(/^\d{11}$/);
      if (this.mobilePhone === '') {
        Notify('请先输入手机号再获取验证码')
        return
      }else if (!phoneReg.test(this.mobilePhone)) {
        Notify('仅支持中国大陆手机号')
        return
      }
      Notify({type: 'success', message: '验证码已发送至您的手机'})
      this.$axios
          .get("/sms/getVerificationCode", {
            params:{
              mobilePhone: this.mobilePhone
            }
          })
          .then(successResponse => {
            if (successResponse.data.code === 5005) {
              Notify('获取失败，请检查手机号')
              return
            } else if (successResponse.data.code === 5004) {
              Notify('每分钟只可获取一次哦')
              return
            } else if (successResponse.data.code !== 2000) {
              Notify(successResponse.data.message)
              return
            }
            this.afterGetCode = false
            this.second()
            sessionStorage.setItem("verifyMobilePhone", this.mobilePhone)
          })
          .catch(() => {
            Notify('出错了，请稍后重试')
          })
    },
    submit() {
      this.completeSubmit = true
      let password = this.password.replaceAll(' ', '')
      if (this.verificationCode === '') {
        Notify('请输入验证码后再提交')
        this.completeSubmit = false
      } else if (password !== this.rePassword.replaceAll(' ', '')) {
        Notify('两次输入的密码不同')
        this.completeSubmit = false
      } else {
          const sm4 = new SM4Util()
          this.$axios
              .put("/user/resetPassword", {
                verificationCode: this.verificationCode,
                password: sm4.encryptDefault_CBC(password),
                mobilePhone: sessionStorage.getItem("verifyMobilePhone")
              })
              .then(successResponse => {
                if (successResponse.data.code === 5005) {
                  Notify('校验未通过，检查手机号')
                } else if (successResponse.data.code === 5008) {
                  Notify('验证码已过期')
                } else if (successResponse.data.code === 5009) {
                  Notify('验证码错误')
                } else{
                  router.replace({path: '/index'})
                  Notify({type: 'success', message: '密码重置成功，请登录'})
                }
                this.completeSubmit = true
                sessionStorage.removeItem("verifyMobilePhone")
              })
              .catch(() => {
                this.completeSubmit = true
                Notify('出错了，请稍后重试')
              })
      }
    },
  }
}
</script>

<style scoped>
.main {
  height: 100vh;
  background-color: white;
  overflow: auto;
}
</style>
