<template>
  <div class="main">
    <van-nav-bar
        title="事务记录"
        fixed
        left-arrow
        @click-left="onClickLeft"
    />

    <van-empty v-if="businessProcessingList.length === 0 && !showSkeleton" description="还没有申请过事务哦">
      <van-button round type="primary" class="bottom-button" @click="this.$router.push({name: 'Business'})">申一个？</van-button>
    </van-empty>

    <van-pull-refresh v-else style="background-color: #ffd45d" pulling-text="别拉我！" loosing-text="畜生 快松开我！" loading-text="哎呦歪" v-model="loading" @refresh="pullFresh">
      <div style="min-height: calc(100vh - 140px);background-color: #f2f4f5;padding-top: 5px">
        <van-skeleton style="height: 70px;background-color: #e2e5ea;" v-if="showSkeleton" avatar-size="45px" title-width="100%" round title avatar :row="3" />
        <van-swipe-cell v-else v-for="(item, index) in businessProcessingList" :key="index">
          <van-cell :class="{'notRead':item.processingResult === '暂未处理', 'refuseApply':item.processingResult === '已拒绝'}" :title="item.businessName" :value="item.processingResult" :label="item.businessBelongCommunity" @click="goMyBusinessDetail(index)" title-style="text-align: left" />
          <template #right>
            <van-button v-if="item.processingResult === '已撤回'" style="height: 65px" @click="rePostBusinessApply(item, index)" square type="primary" text="重新申请" />
            <van-button v-if="item.processingResult === '暂未处理'" style="height: 65px" @click="withdrawBusinessApply(item, index)" square type="danger" text="撤回" />
          </template>
        </van-swipe-cell>
      </div>
    </van-pull-refresh>

    <van-dialog teleport="#app" :show-confirm-button="false" style="min-height: 70%;width: 85%" v-model:show="showDialog" :title="businessName">
      <van-cell-group v-for="(value, key, index) in businessApplyDetails.applyDetails" :key="index" style="text-align: left">
        <van-cell v-if="key === '申请材料' && value !== ''" :title="key" value="点击查看" :url="value" />
        <van-cell v-else :title="key" :value="value"/>
      </van-cell-group>
      <van-cell title-style="text-align: left" title="申请时间" :value="businessApplyDetails.applyTime"/>
      <van-cell title-style="text-align: left" v-if="businessApplyDetails.processingResult === '已拒绝'" title="驳回理由" :value="businessApplyDetails.refusedReason"/>
      <van-button @click="showDialog = false" style="width: 100%;position: absolute;bottom: 0;left: 0" type="default">返回</van-button>
    </van-dialog>

  </div>
</template>

<script>
import {Skeleton, Cell, CellGroup, NavBar, Notify, Toast, PullRefresh, Icon, Dialog, SwipeCell, Empty} from 'vant';
import {ref} from "vue";
export default {
  name: "MyBusiness",
  setup() {
    //下拉刷新
    const loading = ref(false);

    return {
      loading,
    };
  },
  components:{
    [NavBar.name]: NavBar,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Skeleton.name]: Skeleton,
    [PullRefresh.name]: PullRefresh,
    [SwipeCell.name]: SwipeCell,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [Empty.name]: Empty,
  },
  data(){
    return{
      showSkeleton: true,
      showDialog: false,
      businessProcessingList: [],
      businessApplyDetails: {},
      businessName: '',
      businessApplyId: '',
    }
  },
  methods:{
    getMyBusinessApply(){
      this.showSkeleton = true
      this.$axios
          .get("/businessApply/getMyBusinessApply")
          .then(successResponse => {
            this.businessProcessingList = successResponse.data.data
            this.showSkeleton = false
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    },
    pullFresh(){
      this.showSkeleton = true
      this.loading = true
      this.getMyBusinessApply()
      setTimeout(() => {
        Toast({
          message: '刷新成功',
          icon: 'like-o',
        });
        this.loading = false;
      }, 500);
    },
    goMyBusinessDetail(index){
      this.showDialog = true;
      this.businessName = this.businessProcessingList[index].businessName
      this.businessApplyId = this.businessProcessingList[index].id
      this.$axios
          .get("/businessApply/getBusinessApplyDetails",{
            params:{
              id: this.businessApplyId,
            }
          })
          .then(successResponse => {
            this.businessApplyDetails = successResponse.data.data
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    },
    rePostBusinessApply(businessApplyDetails, index){
      Dialog.confirm({
        message: '确实要重新申请吗',
      })
          .then(() => {
            this.$axios
                .post("/businessApply/rePostBusinessApply", businessApplyDetails)
                .then(() => {
                  this.businessProcessingList[index].processingResult = '暂未处理'
                  Notify({type: 'success', message: '已重新申请'})
                })
                .catch(() => {
                  Notify('登录过期，请重新登录')
                })
          })
          .catch(() => {
            // on cancel
          });
    },
    withdrawBusinessApply(businessApplyDetails, index){
      Dialog.confirm({
        message: '确实要撤回吗',
      })
          .then(() => {
            this.$axios
                .post("/businessApply/withdrawBusinessApply", businessApplyDetails)
                .then(successResponse => {
                  if (successResponse.data.code === 5007) {
                    Notify({ type: 'warning', message: '此申请已被管理员处理' });
                    this.getMyBusinessApply()
                  } else {
                    this.businessProcessingList[index].processingResult = '已撤回'
                    Notify({type: 'success', message: '已撤回'})
                  }
                })
                .catch(() => {
                  Notify('登录过期，请重新登录')
                })
          })
          .catch(() => {
            // on cancel
          });
    },
    onClickLeft(){
      this.$emit('showMyBusinessDialog', false)
    },
  },
}
</script>

<style scoped>
.main{
  min-height: 500px;
}
.notRead{
  background-color: lightblue
}
.refuseApply{
  background-color: lightpink;
}
</style>