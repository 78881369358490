<template>
  <div>
    <h2>登录</h2>
    <input class="a" type="text" inputmode="tel" placeholder="请输入手机号" v-model="mobilePhone">
    <input class="a" type="password" placeholder="请输入密码" v-model="password">
    <button @click="login" value="登录" :disabled="disabled">登录</button>
    <van-checkbox style="padding-left: 5%" v-model="isSaved">记住密码</van-checkbox>
    <p @click="forgetPassword" style="float: right;margin-right: 5%">忘记密码？</p>
  </div>
</template>

<script>
import router from "@/router";
import {SM4Util} from '@/utils/sm4';
import {Notify} from 'vant';
import {Checkbox, CheckboxGroup} from 'vant';

export default {
  name: "Login",
  data() {
    return {
      mobilePhone: '18238264105',
      password: '123456',
      //用于存储账号密码，记住密码功能
      isSaved: false,
      disabled: false
    }
  },
  components: {
    [Notify.Component.name]: Notify.Component,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  mounted() {
    // sm4加密
    const sm4 = new SM4Util
    let mobilePhone = localStorage.getItem('mobilePhone');
    if (mobilePhone) {
      this.mobilePhone = localStorage.getItem('mobilePhone');
      if (localStorage.getItem('password') != null) {
        this.password = sm4.decryptDefault_CBC(localStorage.getItem('password'));
      }
      this.isSaved = true
    }
  },
  methods: {
    login() {
      this.disabled = true
      let mobilePhone = this.mobilePhone.replaceAll(' ', '')
      let password = this.password.replaceAll(' ', '')
      const phoneReg = new RegExp(/^\d{11}$/);
      const sm4 = new SM4Util
      if (this.isSaved) {
        localStorage.setItem('mobilePhone', mobilePhone);
        localStorage.setItem('password', sm4.encryptDefault_CBC(password));
      } else {
        localStorage.removeItem('mobilePhone');
        localStorage.removeItem('password');
      }
      if (!phoneReg.test(mobilePhone)) {
        Notify('账号为11位手机号')
        this.disabled = false
      } else if (password === '') {
        Notify('密码呢？')
        this.disabled = false
      } else {
        // sm4加密
        this.$axios
            .put("/user/login", {
              mobilePhone: mobilePhone,
              password: sm4.encryptDefault_CBC(password),
            })
            .then(successResponse => {
              if (successResponse.data.code === 2000) {
                //要先获取userInfo再跳转页面，因为页面需要userInfo中的信息
                this.getUserInfo()
                localStorage.setItem('mobilePhone', mobilePhone);
                sessionStorage.setItem("user", JSON.stringify(successResponse.data.data))
                switch (mobilePhone) {
                  case '19817562150':
                    Notify({message: '哦~亲爱的兰兰姐😘', color: '#ad0000', background: '#ffe1e1'})
                    break
                  case '18568270909':
                    Notify({message: '哦~亲爱的珂珂😘', color: '#ad0000', background: '#ffe1e1',})
                    break
                  case '18157421833':
                    Notify({message: '哦~亲爱的莹莹😘', color: '#ad0000', background: '#ffe1e1',})
                    break
                  default:
                    Notify({message: '哦~亲爱的' + successResponse.data.data.username + '😘', color: '#ad0000', background: '#ffe1e1',})
                }
              } else if (successResponse.data.code === 5001) {
                Notify('账号不存在，注册一个吧')
              } else if (successResponse.data.code === 5002) {
                Notify('密码错了哦')
              } else if (successResponse.data.code === 5003) {
                Notify('账号处在封禁中')
              } else if (successResponse.data.code === 5004) {
                Notify('不要点的太快啦！')
              }
              this.disabled = false
            })
            .catch(() => {
              Notify('哎呀网有点慢，好像没有登录上呢，稍后再点一次吧')
              this.disabled = false
            })
      }
    },
    getUserInfo() {
      this.$axios
          .get("/user/getUserInfo")
          .then(successResponse => {
              sessionStorage.setItem('userInfo', JSON.stringify(successResponse.data.data))
              router.replace({path: '/home'})
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    },
    forgetPassword() {
      router.push('ForgetPassword')
    }
  }
}

</script>

<style scoped>
h2 {
  font-size: 2rem;
}

.a {
  width: 90%;
  height: 35px;
  border-radius: 7px;
  border: 1px solid;
}

input {
  margin-top: 20px;
  outline: 0 solid;
  display: inline-block;
  transition: all 0.3s ease;
}

button {
  width: 90%;
  height: 35px;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border: 1px solid;
  outline: 0 solid;
  display: block;
  margin: 20px auto;
  transition: all 0.3s ease;
}

/*input:hover{*/
/*  box-shadow: 0 0 30px pink;*/
/*  background-color:paleturquoise;*/
/*  transform:scale(1.05,1.05);*/
/*  transition:all 0.3s ease;*/
/*}*/
input:focus {
  box-shadow: 0 0 30px pink;
  background-color: paleturquoise;
  transform: scale(1.05, 1.05);
  transition: all 0.3s ease;
}

button:focus {
  box-shadow: 5px 5px 10px pink;
  background-color: paleturquoise;
}

button:hover {
  box-shadow: 5px 5px 10px pink;
  background-color: paleturquoise;
  transform: scale(1.05, 1.05);
  transition: all 0.5s ease;
}

button:active {
  box-shadow: 0 0 10px yellow;
  background-color: pink;
  transform: scale(1, 1);
  transition: all 0.05s ease;
}

@keyframes change {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
