/**
 * 图片转码、修复ios旋转、压缩一把梭
 */

//heic格式转换,npm install heic2any
import heic2any from "heic2any";
//图片压缩
import {compressImage, convertBase64UrlToBlob} from "@/utils/CompressImageUtils.js";
//修复苹果手机图片旋转问题,npm install compressorjs
import Compressor from "compressorjs";
import {Notify} from "vant";

const imageTypeList = ["image/png", "image/jpg", "image/jpeg", "image/gif", "image/bmp", "image/xbm", "image/tif", "image/tiff", "image/jfif", "image/webp", "image/svg", "image/svgz", "image/ico", "image/pjpeg", "image/avif", "image/pjp"]

/**
 * 处理图片方法入口，会返回 FileDataResult 对象数据
 * @param file 文件对象
 */
function handleImage(file) {
    return new Promise(resolve => {
        const rawFile = file.file
        const base64 = file.content
        //判断是不是heic图片，base64的前缀为octet-stream，文件type一般为''
        if (imageTypeList.includes(rawFile.type)) {
            fixAndCompressImage(rawFile, base64).then(fileDataResult => {
                Notify({type: 'success', message:'图片压缩嗖的一下就完成了'})
                resolve(fileDataResult)
            })
        } else {
            Notify({type: 'warning', message:'正在进行heic转码，请等待缩略图成功显示'})
            let blob = rawFile
            heic2any({
                blob,  // 将heic转换成一个buffer数组的图片
                toType: 'image/png', //要转化成具体的图片格式，可以是png/gif
                quality: 1   // 图片的质量，参数在0-1之间，转换完成后图片巨大，要压缩
            }).then(result => {
                let file = new FileReader();
                file.readAsDataURL(result);
                file.onload = function (imageFile) {
                    let imgBase64 = imageFile.target.result
                    fixAndCompressImage(file, imgBase64).then(fileDataResult => {
                        Notify({type: 'success', message:'转码成功，缩略图可能无法显示，不影响上传后的效果'})
                        resolve(fileDataResult)
                    })
                }
            }).catch(error => {
                console.log(error)
                Notify({type: 'success', message:'转码失败，您仍可上传，不保证可显示'})
                fixAndCompressImage(rawFile, base64).then(fileDataResult => {
                    resolve(fileDataResult)
                })
            })
        }
    })
}

/**
 * 图片格式转换完成后，开始修复和压缩
 * @param file 文件对象
 * @param base64 图片base64
 */
async function fixAndCompressImage(file, base64) {
    //修复苹果手机图片旋转问题，不支持heic格式的base64，要先转换格式再修复
    file = await fixRotate(file, base64)
    //图片压缩，格式转换后图片很大，要先转换格式再压缩
    base64 = await compressAndAddFile(base64)
    return {file: file, base64: base64}
}

/**
 * 压缩图片，返回压缩后的base64
 * @param base64 图片base64
 */
function compressAndAddFile(base64) {
    //大于1MB的图片才进入压缩
    if (base64.length > 1024000) {
        return new Promise(resolve => {
            compressImage(base64).then(result => {
                if (result.length > base64.length) {
                    //压缩后比原来更大，则将原图上传
                    resolve(base64)
                } else {
                    //压缩后比原来小，上传压缩后的
                    resolve(result)
                }
            }).catch(failResponse => {
                console.log(failResponse)
                resolve(base64)
            })
        })
    } else {
        return base64
    }
}

/**
 * 修复ios图片旋转问题，返回修复后的文件对象
 * @param file 文件对象
 * @param base64 图片base64
 */
function fixRotate(file, base64) {
    return new Promise(resolve => {
        new Compressor(convertBase64UrlToBlob(base64), {
            quality: 1, //这个东西设小一点也能压缩图片，但是效果不好，我们不用，设为1
            success(data) {
                resolve(data)
            },
            error(err) {
                console.log(err.message);
                resolve(file)
            },
        });
    })
}

export default handleImage
