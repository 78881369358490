import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import {Button} from 'vant';
import {Lazyload} from 'vant';

import '../public/lib/mui/css/mui.css'
import '../public/lib/mui/fonts/mui.ttf'
import './registerServiceWorker'

const app = createApp(App)
//挂载组件到app.vue
app.use(Lazyload, {lazyComponent: true}).use(Button).use(store).use(router).mount('#app')
//初始化全局axios
app.config.globalProperties.$axios = axios;
//本地启动这里请求到vue.config的代理配置，线上直接请求的是nginx
axios.defaults.baseURL = '/undersea'

//下面这段代码含义请看registerServiceWorker.js
// const serviceWorker = navigator.serviceWorker;
//
// serviceWorker.getRegistrations ? serviceWorker.getRegistrations().then(function(sws) {
//     sws.forEach(function(sw) {
//         sw.unregister().then(r => console.log(r));
//         console.log('sw unregister 1');
//     });
// }) : serviceWorker.getRegistration && serviceWorker.getRegistration().then(function(sw) {
//     sw && sw.unregister();
//     console.log('sw unregister 2');
// });