<template>
  <span style="font-size: 13px">{{ content }}</span>
  <!--  计算日期间隔的工具组件-->
  <!--  用法：使用date传值 传年月日时分秒-->
  <!--        <TimeDiff date="2020-11-28 12:00:00"/>-->
  <!--        使用time 传时间戳-->
  <!--        <TimeDiff time="1606560899952"/>-->
</template>

<script>
export default {
  name: "TimeDiff",
  data() {
    return {
      content: '',
    }
  },
  props: {
    date: {
      type: String,
      default: new Date().toString()
    },
    time: {
      type: Number,
      default: 0,
      dateTimeStamp: ''
    }
  },
  mounted() {
    this.diffTime()
  },
  methods: {
    diffTime() {
      this.dateTimeStamp = this.time
      if (!this.dateTimeStamp) {
        if (!this.date) {
          return '刚刚'
        }
        this.dateTimeStamp = new Date(this.date).getTime()
      }
      const minute = 1000 * 60;
      const hour = minute * 60;
      const day = hour * 24;
      // var halfamonth = day * 15;
      const month = day * 30;

      const now = new Date().getTime();
      const diffValue = now - this.dateTimeStamp;

      const monthC = diffValue / month;
      // var weekC =diffValue/(7*day);
      const dayC = diffValue / day;
      const hourC = diffValue / hour;
      const minC = diffValue / minute;
      if (parseInt(monthC) >= 1) {
        this.content = parseInt(monthC) + '个月前'
      } else if (parseInt(dayC) > 1) {
        this.content = parseInt(dayC) + '天前'
      } else if (parseInt(dayC) === 1) {
        this.content = '昨天'
      } else if (parseInt(hourC) >= 1) {
        this.content = parseInt(hourC) + "小时前";
      } else if (parseInt(minC) >= 1) {
        this.content = parseInt(minC) + "分钟前";
      } else {
        this.content = "刚刚";
      }
    }
  }
}
</script>

<style>

</style>