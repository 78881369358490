<template>
  <div class="main">
    <van-nav-bar
        title="管理员选项"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <div style="margin-top: 5px"></div>
    <van-cell title="成员管理" to="/home/mine/administration/memberManage" title-style="text-align: left" is-link/>
    <van-cell title="事务处理" to="/home/mine/administration/businessProcessing" title-style="text-align: left" is-link/>
    <van-cell title="创建事务" to="/home/mine/administration/businessEdit" title-style="text-align: left" is-link/>
    <van-cell title="投诉举报" to="/home/mine/administration/inform" title-style="text-align: left" is-link/>
    <van-cell title="封禁用户" to="/home/mine/administration/punishUser" title-style="text-align: left" is-link/>

  </div>
</template>

<script>
import {Cell, CellGroup} from 'vant';
import {NavBar} from 'vant';

export default {
  name: "Administration",
  setup() {
    const onClickLeft = () => history.back();

    return {
      onClickLeft,
    };
  },
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      completeSubmit: false,
    }
  },
  methods: {},
}
</script>

<style scoped>
.main {
  height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
}
</style>