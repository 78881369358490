<template>
  <router-view v-slot="{ Component }">
    <transition mode="out-in">
      <component :is="Component"/>
    </transition>
  </router-view>
</template>
<script>
import router from "@/router";
import {clickHeart} from "@/js/clickHeart";
import {Dialog} from 'vant';

export default {
  name: "App",
  data() {
    return {
      screenWidth: document.documentElement.clientWidth,
      screenHeight: document.documentElement.clientHeight
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  mounted() {
    clickHeart()
    //屏幕尺寸变化就重新赋值
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.documentElement.clientWidth
        this.screenHeight = document.documentElement.clientHeight
      })()
    }
    window.addEventListener('offline', () => {
      // 网络由正常到异常时触发
      sessionStorage.locationUrl = window.location.href;
      router.replace('/networkError')
    });
    window.addEventListener('online', () => {
      window.location.href = sessionStorage.locationUrl
    });
  },
  methods: {
    pageScale() {
      if (this.screenHeight / this.screenWidth < 1.7) {
        Dialog({message: '本系统为手机软件，您当前页面比例不适合本系统页面布局，使用手机访问或F12模拟设备体验更佳'});
      }
    }
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if (!this.timer) {
          // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
          this.screenWidth = val
          this.timer = true
          // 定时器里用this会报错
          let that = this
          setTimeout(function () {
            // 打印screenWidth变化的值
            console.log('宽度：' + that.screenWidth)
            that.pageScale()
            that.timer = false
          }, 1500)
        }
      },
      // 是否为深度监听，多用于对象等深层次的数据
      //deep: true,
      //当值第一次绑定的时候，不会执行监听函数，只有值发生改变才会执行。如果需要最初绑定值的时候也执行，需要用immediate属性
      immediate: true,
    },
    screenHeight: {
      handler: function (val) {
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if (!this.timer) {
          // 一旦监听到的screenHeight值改变，就将其重新赋给data里的screenHeight
          this.screenHeight = val
          this.timer = true
          // 定时器里用this会报错
          let that = this
          setTimeout(function () {
            // 打印screenHeight变化的值
            console.log('高度：' + that.screenHeight)
            that.timer = false
          }, 1500)
        }
      },
      // 是否为深度监听，多用于对象等深层次的数据
      //deep: true,
      //当值第一次绑定的时候，不会执行监听函数，只有值发生改变才会执行。如果需要最初绑定值的时候也执行，需要用immediate属性
      immediate: true,
    },
  }
}
</script>
<style>
body {
  height: 100%;
  background-image: url("assets/backgroundImage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-color: white !important;
  z-index: -999;
}

.v-enter-from, .v-leave-to {
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.4s ease-in-out;
}

.v-enter-active, .v-leave-active {
  transition: all 0.4s ease-in-out;
}
</style>
