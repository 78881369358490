<template>

  <van-pull-refresh style="border-radius: 25px;background-color: #ffd45d;" pulling-text="别拉我！" loosing-text="畜生 快松开我！" loading-text="哎呦歪" v-model="loading" @refresh="pullFresh">

  <van-empty v-if="this.exposureList.length === 0 && !showSkeleton" style="background-color: white;height: calc(100vh - 140px)" description="还没有帖子哦">
    <van-button round type="primary" class="bottom-button" @click="this.$router.push({name: 'ShareMessage'})">发一个？</van-button>
  </van-empty>
  <div v-else class="mui-content">

    <van-skeleton v-if="showSkeleton" style="height: 300px;background-color: #e2e5ea;border-radius: 10px;padding-top: 20px;margin: 5px 5px 15px;" round title :row="5" />
    <van-skeleton v-if="showSkeleton" style="height: 300px;background-color: #e2e5ea;border-radius: 10px;padding-top: 20px;margin: 5px 5px 15px;" round title :row="5" />
    <van-skeleton v-if="showSkeleton" style="height: 300px;background-color: #e2e5ea;border-radius: 10px;padding-top: 20px;margin: 5px 5px 15px;" round title :row="5" />

    <div v-else class="mui-card" v-for="(item, index) in exposureList" :key="index">
      <!--背景懒加载-->
      <lazy-component>
        <div v-if="item.blogImgList.length > 0" @click="open(index)" class="mui-card-header mui-card-media" :style="{backgroundImage:'url(' + item.blogImgList[0] + ')'}"></div>
      </lazy-component>
      <div class="mui-card-content">
        <div class="mui-card-content-inner">
          <div @click="fatherPopDialog(item.postedUserMobilePhone)" style="height: 40px">
            <img style="margin-right: 10px" :src="item.postedUserHead" height="30" width="30" alt="">
            <p style="margin-right: 20px;color: black;font-weight: bold;display: inline-block;font-size: 1.1rem;position: absolute">{{ item.postedUsername }}</p>
            <p style="margin-right: 20px;display: inline-block;"><TimeDiff :date="item.postedTime"></TimeDiff></p>
          </div>
          <van-icon v-if="this.user.isSuperAdmin || this.userInfo.isCommunityAdmin" @click="deleteBlog(item, index)" style="float: right;position: absolute;top: 0;right: 20px;padding: 20px 10px" size="16px" color="palevioletred" name="weapp-nav" />
          <van-icon v-else @click="inform(item)" style="float: right;position: absolute;top: 0;right: 20px;padding: 20px 10px" size="16px" color="palevioletred" name="weapp-nav" />
          <p style="color: #333;max-height: 150px;overflow: auto">{{item.blogContent}}</p>
        </div>
      </div>
      <div class="mui-card-footer">
        <!--点赞改变样式-->
        <a v-if="!item.isUp" class="mui-card-link"><van-icon :class="item.isUp ? 'good':'notGood'" @click="getGoodStatus(index);upBlog(item.id,index)" size="1.5rem" name="good-job" /><span style="margin-left: 5px;color: palevioletred">{{item.blogUpNum}}</span></a>
        <a v-else class="mui-card-link"><van-icon :class="item.isUp ? 'good':'notGood'" @click="getGoodStatus(index);cancelUpBlog(item.id,index)" size="1.5rem" name="good-job" /><span style="margin-left: 5px;color: palevioletred">{{item.blogUpNum}}</span></a>
        <a class="mui-card-link"><van-icon @click="item.isComments = !item.isComments" size="1.5rem" name="more" color="black"/><span style="margin-left: 5px;color: palevioletred">{{item.blogCommentsNum}}</span></a>
        <a class="mui-card-link" @click="fatherPopShare"><van-icon size="1.5rem" name="share" color="black" /></a>
      </div>
      <div style="width: 100%">
        <div v-if="item.isComments">
          <input v-model="comment" type="text" placeholder="评论一下 TA会爱上你" style="width: 80%;height: 30px;border-radius: 20px;font-size: 14px;margin: 0 5px 5px 5px">
          <van-button :disabled="completeSubmit" style="height: 30px;width: 30px" icon="success" round type="primary" @click="submitComment(item.id, index)"/>
        </div>
        <p v-for="(item2,index2) in item.blogComments" :key="index2" style="text-align: left;margin-left: 15px">{{ item2 }}</p>
      </div>
    </div>
    <div style="height: 50px;line-height: 50px">
      <p v-if="(this.exposureList.length < this.pageTotal) && !this.showLoading" @click="getBlog">点击加载更多</p>
      <p v-if="this.exposureList.length >= this.pageTotal">我也是有底线的</p>
      <van-loading v-if="this.showLoading" color="#1989fa" />
    </div>
  </div>

    <van-dialog teleport="#app" :show-confirm-button="false" style="min-height: 30%;width: 85%"  title="请填写举报信息" v-model:show="showDialog2">
      <van-cell-group inset>
        <van-popup v-model:show="showPicker" round position="bottom">
          <van-picker
              title="举报类型"
              :columns="columns"
              @cancel="showPicker = false"
              @confirm="onConfirm"
          />
        </van-popup>
        <van-field
            v-model="informType"
            type="textarea"
            readonly
            is-link
            style="height: 50px"
            label="举报类型"
            @click="showPicker = true"
        />
        <van-field autosize type="textarea" v-model="informDetails" placeholder="详细描述" :rows="15" maxlength="200" show-word-limit />
      </van-cell-group>
      <van-button @click="postInform" :disabled="buttonDisabled" style="width: 50%;position: absolute;bottom: 0;left: 0" icon="checked" type="primary">提交</van-button>
      <van-button @click="this.showDialog2 = false" style="width: 50%;position: absolute;bottom: 0" color="indianred" icon="clear" type="warning">取消</van-button>
    </van-dialog>

  </van-pull-refresh>
</template>

<script>
import {Dialog, ImagePreview, Notify, Toast} from "vant";
import {Icon, Skeleton, Empty, PullRefresh, Loading, CellGroup, Picker, Popup, Field} from 'vant';
import {ref} from "vue";
import TimeDiff from "@/views/TimeDiff";
export default {
  name: "Exposure",
  setup(){
    //下拉刷新缓冲动画
    const loading = ref(false);
    const informDetails = ref('')
    const informType = ref('')

    const showPicker = ref(false);
    const columns = ['少儿不宜', '政治错误', '血腥暴力', '三观不正'];

    const onConfirm = (value) => {
      informType.value = value
      showPicker.value = false;
    };

    return {
      loading,
      informDetails,
      columns,
      showPicker,
      onConfirm,
      informType,
    };
  },
  data(){
    return{
      exposureList: [],
      showSkeleton: true,
      completeSubmit: false,
      comment: '',
      isComments: false,
      pageNum: 1,
      pageQuantity: 10,
      pageTotal: 0,
      showLoading: false,
      isSubmitComment: false,
      showDialog: false,
      showDialog2: false,
      buttonDisabled: false,
      user: {},
      userInfo: {},
      blog: {},
    }
  },
  props:{
    postedUserMobilePhone: String,
  },
  components: {
    TimeDiff,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Skeleton.name]: Skeleton,
    [Toast.name]: Toast,
    [Loading.name]: Loading,
    [Empty.name]: Empty,
    [Popup.name]: Popup,
    [CellGroup.name]: CellGroup,
    [Picker.name]: Picker,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    this.getBlog()
  },
  methods:{
    //点击预览图片
    open(index){
      ImagePreview({images: this.exposureList[index].blogImgList, transition: 'van-slide-up'});
    },
    //点一下赞，再点一下取消
    getGoodStatus(index){
      this.exposureList[index].isUp = !this.exposureList[index].isUp;
    },
    upBlog(id, index){
      this.$axios
          .post("/blog/upBlog", {
            id: id,
            blogUpNum: this.exposureList[index].blogUpNum,
          })
      this.exposureList[index].blogUpNum += 1
    },
    cancelUpBlog(id,index){
      this.$axios
          .post("/blog/cancelUpBlog", {
            id: id,
            blogUpNum: this.exposureList[index].blogUpNum,
          })
      this.exposureList[index].blogUpNum -= 1
    },
    pullFresh(){
      this.showSkeleton = true
      this.pageNum = 1
      this.exposureList = []
      this.getBlog()
      setTimeout(() => {
        Toast({
          message: '刷新成功',
          icon: 'like-o',
        });
        this.loading = false;
      }, 500);
    },
    getBlog() {
      this.showLoading = true
      this.$axios
          .get("/blog/list",{
            params:{
              searchString: '',
              belongPlate: '匿名曝光',
              postedUserMobilePhone: this.postedUserMobilePhone,
              pageNum: this.pageNum++,
              pageQuantity: this.pageQuantity
            }
          })
          .then(successResponse => {
            if (successResponse.data.data.length > 0){
              this.pageTotal = successResponse.data.data[successResponse.data.data.length - 1].pageTotal
              //这个字段是以前每次发送评论都会刷新页面，为了无感刷新而采取的措施，现在发送评论已经不需要刷新页面，所以暂时没有用
              if (this.isSubmitComment){
                for (let i = 0;i < successResponse.data.data.length;i++){
                  if (this.exposureList.length > 10 && this.pageTotal - this.exposureList.length >= 10){
                    this.exposureList.splice(this.exposureList.length - 10 + i,1,successResponse.data.data[i])
                  }else if (this.exposureList.length > 10){
                    this.exposureList.splice((this.pageNum - 2) * this.pageQuantity + i,1,successResponse.data.data[i])
                  }else {
                    this.exposureList.splice(i,1,successResponse.data.data[i])
                  }
                }
              }else {
                for (let i = 0; i < successResponse.data.data.length; i++) {
                  this.exposureList.push(successResponse.data.data[i])
                }
              }
            }
            this.showSkeleton = false
            this.showLoading = false
            this.isSubmitComment = false
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    },
    deleteBlog(item, index){
      Dialog.confirm({
        message: '确实要删除吗',
      })
        .then(() => {
          this.$axios
              .delete("/blog/delete",{
                params:{
                  id: item.id,
                  postedUserMobilePhone: item.postedUserMobilePhone
                }
              })
              .then(() => {
                this.exposureList.splice(index, 1)
                Notify({ type: 'warning', message: '删除成功' });
                //因为删帖会更新帖子数量，所以重新请求用户信息
                this.$axios
                    .get("/user/getUserInfo")
                    .then(successResponse => {
                      this.userInfo = successResponse.data.data
                      sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
                    })
              })
              .catch(failResponse => {
                Notify('登录过期，请重新登录')
                console.log(failResponse)
              })
        })
        .catch(() => {
          // on cancel
        });
    },
    inform(item){
      this.blog = item
      this.informDetails = ''
      this.informType = ''
      this.showDialog2 = true
    },
    postInform(){
      if (this.informType === ''){
        Notify('要先选择举报类型哦')
      }else {
        this.buttonDisabled = true
        this.$axios
            .post("/inform/postInform", {
              informedBlogId: this.blog.id,
              informedUsername: this.blog.postedUsername,
              informedMobilePhone: this.blog.postedUserMobilePhone,
              informDetails : this.informDetails,
              informType: this.informType,
            })
            .then(() => {
              this.showDialog2 = false
              Notify({type: 'success', message:'举报发送成功，等待处理'})
              this.buttonDisabled = false
            })
            .catch(() => {
              Notify('登录过期，请重新登录')
              this.buttonDisabled = false
            })
      }
    },
    fatherPopShare(){
      this.showShare = true;
      this.$emit('showShare', this.showShare)
    },
    fatherPopDialog(postedUserMobilePhone){
      this.showDialog = true;
      this.$emit('showDialog', this.showDialog)
      this.$emit('postedUserMobilePhone', postedUserMobilePhone)
    },
    submitComment(id, index){
      this.completeSubmit = true
      this.exposureList[index].blogComments.push(this.userInfo.username + '：' + this.comment);
      this.exposureList[index].blogCommentsNum++;
      this.$axios
          .post("/blog/postedBlogComment", {
            id: id,
            blogComments: this.exposureList[index].blogComments,
            blogCommentsNum: this.exposureList[index].blogCommentsNum,
          })
          .then(() => {
            this.comment = ''
            this.isComments = false;
            this.completeSubmit = false
            //这个对pageNum和isSubmitComment是以前每次发送评论都会刷新页面，为了无感刷新而采取的措施，现在发送评论已经不需要刷新页面，所以暂时没有用
            this.pageNum--
            this.isSubmitComment = true
          })
          .catch(() => {
            this.completeSubmit = false
            Notify('登录过期，请重新登录')
          })
    },
  }
}
</script>

<style scoped>
.mui-card{
  margin: 0 5px 15px;
  border-radius: 10px;
}
.mui-content{
  padding-top: 5px;
  margin-top: -5px;
  padding-bottom: 55px;
  min-height: 100vh;
  overflow: auto;
}
.mui-content > .mui-card:first-child {
  margin-top: 5px;
}
.mui-card-header{
  height: 40vw;
}
.good{
  animation: makeGood ease-in-out 0.3s;
  color: red;
}
.notGood{
  color: black;
}
@keyframes makeGood {
  0%{
    transform: rotate(-5deg);
    bottom: 0;
  }
  12.5%{
    transform: rotate(-5deg);
    bottom: 1px;
  }
  25%{
    transform: rotate(-10deg);
    bottom: 2px;
  }
  37.5%{
    transform: rotate(-5deg);
    bottom: 3px;
  }
  50%{
    transform: rotate(-5deg);
    bottom: 5px;
  }
  62.5%{
    transform: rotate(-5deg);
    bottom: 3px;
  }
  75%{
    transform: rotate(-5deg);
    bottom: 2px;
  }
  87.5%{
    transform: rotate(-5deg);
    bottom: 1px;
  }
  100%{
    transform: rotate(0);
    bottom: 0;
  }
}
</style>
