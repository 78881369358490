<template>
    <div class="header">
        <ul>
            <li class="kantie">
                <router-link to="/home/maincontent" active-class="kan">看帖</router-link>
            </li>
            <li class="fatie">
                <router-link to="/home/sharemessage" active-class="fa">发帖</router-link>
            </li>
            <li class="ipt"><input type="text" placeholder="搜索关键字"></li>
            <li class="jia" @click="showPopup">+</li>
            <van-popup round v-model:show="show" position="right" :style="{ height: '100%',width:'100px' }">
                <ul style="height: 100%">
                    <li style="width: 100%;height: 90px">
                        <p style="font-size: 16px;line-height: 90px">进入社区</p>
                    </li>
                    <li style="width: 100%" v-for="(item) in communityList" :key="item.id">
                        <van-button @click="changeCommunity(item)" style="border-bottom: 1px solid;width: 100%" square color="#5db7b7">
                            {{ item }}
                        </van-button>
                    </li>
                </ul>
            </van-popup>
        </ul>
    </div>
</template>

<script>
import router from "@/router";
import {Notify, Popup} from 'vant';
import {Cell, CellGroup} from 'vant';

export default {
    name: "Header",
    setup() {


        return {};
    },
    data() {
        return {
            communityList: [],
            show: false,
        }
    },
    components: {
        [Popup.name]: Popup,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
    },
    mounted() {

    },
    methods: {
        showPopup() {
            this.communityList = JSON.parse(sessionStorage.getItem('user')).belongCommunity
            this.show = true
        },
        changeCommunity(item) {
            this.$axios
                .get("/community/switchCommunity", {
                    params: {
                        communityName: item,
                    }
                })
                .then(() => {
                    this.show = false
                    router.go(0)
                    Notify({type: 'success', message: '社区切换成功'})
                })
                .catch(() => {
                    Notify('登录过期，请重新登录')
                })
        },
    }
}
</script>

<style scoped>
.header{
  width: 100%;
  height: 50px;
  background-color: white;
  position: fixed;
  z-index: 998;
}

.header ul {
    height: 50px;
}

.header li {
    list-style: none;
    float: left;
    width: 20%;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.header .ipt {
    width: 46%;
    height: 50px;
}

.header .ipt input {
    height: 31px;
    border-radius: 20px;
    transition: all 0.2s ease;
}

.header .ipt input:focus {
    box-shadow: 0 0 5px 3px paleturquoise inset;
    /*background-color:paleturquoise;*/
    transform: scale(1.05, 1.05);
    transition: all 0.1s;
}

.header .jia {
    width: 14%;
    font-size: 2.4rem;
    font-weight: bolder;
    line-height: 50px;
}

.kantie {
    font-weight: bold;
    font-size: 1rem;
}

.fatie {
    font-weight: bold;
    font-size: 1rem;
}

.header a {
    color: black;
}
</style>
