<template>
  <div class="container">
    <h1 style="font-size: 1.4rem;color: palevioletred;margin-bottom: 20px">这里是泡泡的InfinitySpace！</h1>
    <h3 style="text-align: left;font-size: 1.2rem;color: #8acbcb;margin-bottom: 20px">此站点为毕业设计，历时...</h3>
    <h3 style="text-align: left;font-size: 1.2rem;color: orange;margin-bottom: 20px">5个月？因为...</h3>
    <ul>
      <li style="text-align: left;color: grey">工作太忙啊</li>
      <li style="text-align: left;color: grey">我是个细节怪啊</li>
      <li style="text-align: left;color: grey;margin-bottom: 40px">大概完成了吧...</li>
    </ul>
    <h3 style="font-size: 1.2rem;color: pink;margin-bottom: 20px">有任何建议和想法可以联系我哦</h3>
    <h3 style="font-size: 1.2rem;color: pink">18238264105</h3>
    <h3 style="font-size: 1.2rem;color: pink">1119461672@qq.com</h3>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
.container {
  width: 90%;
  height: 60%;
  position: absolute;
  top: 10%;
  left: 5%;
  background-color: white;
  padding: 2% 5%;
  border-radius: 10px
}

ul {
  padding-left: 20px;
}

ul li {
  line-height: 2.3
}

a {
  color: #20a53a
}
</style>