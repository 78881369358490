<template>
  <div class="main">
    <van-nav-bar
        title="资料设置"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <br>
    <van-form>

      <van-cell-group inset>
        <van-field style="height: 50px" type="textarea" v-model="userInfo.username" label="昵称" placeholder="" maxlength="6" />

        <van-field name="radio" label="性别">
          <template #input>
            <van-radio-group v-model="userInfo.sex" direction="horizontal">
              <van-radio style="height: 35px;width: 50px" name="男">男</van-radio>
              <van-radio style="height: 35px;width: 50px" name="女">女</van-radio>
              <van-radio style="height: 35px;width: 50px" name="受">受?</van-radio>
              <van-radio style="height: 35px;width: 100px" name="雌雄同体">雌雄同体</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field autosize show-word-limit maxlength="20" type="textarea" v-model="userInfo.characterSignature" label="个性签名" placeholder="想被人更快的记住？" />

        <van-field style="height: 50px" v-if="this.userInfo.sex === '女'" type="textarea" v-model="userInfo.age" label="年龄" placeholder="敢问姑娘芳龄" />
        <van-field style="height: 50px" v-if="this.userInfo.sex === '男'" type="textarea" v-model="userInfo.age" label="年龄" placeholder="公子春秋几何" />
        <van-field style="height: 50px" v-if="this.userInfo.sex === '受'" type="textarea" v-model="userInfo.age" label="年龄" placeholder="宝贝今年多大啦" />
        <van-field style="height: 50px" v-if="this.userInfo.sex === '雌雄同体'" type="textarea" v-model="userInfo.age" label="年龄" placeholder="道友修炼几载?" />

        <van-field name="uploader" label="上传头像">
          <template #input>
            <van-uploader :after-read="uploadImg" preview-size="50px" v-model="userHead" :max-count="1" />
          </template>
        </van-field>

        <van-field
            v-model="placeResult"
            is-link
            name="area"
            label="家乡"
            type="textarea"
            placeholder="点击选择省市区"
            @click="showArea = true"
        />
        <van-popup v-model:show="showArea" position="bottom">
          <van-area
              :area-list="areaList"
              @confirm="onConfirm"
              @cancel="showArea = false"
          />
        </van-popup>
      </van-cell-group>

      <div style="margin: 16px;">
        <van-button @click="submit" :disabled="completeSubmit" style="height: 40px" round block type="primary">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import router from "@/router";
import { ref } from 'vue';
import { areaList } from '@vant/area-data';
import {Form, Field, CellGroup, Notify, RadioGroup, Radio, Uploader, Popup, Area, NavBar} from 'vant';
import handleImage from "@/utils/ImageHandlerUtils";

export default {
  name: "UserInfo",
  setup() {
    const onClickLeft = () => history.back();
    //文件上传
    const userHead = ref();
    //省市区选择器
    const placeResult = ref('');
    const showArea = ref(false);
    const onConfirm = (areaValues) => {
      showArea.value = false;
      placeResult.value = areaValues
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
    };
    return {
      placeResult,
      userHead,
      areaList,
      showArea,
      onConfirm,
      onClickLeft
    };
  },
  components:{
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [Area.name]: Area,
    [NavBar.name]: NavBar
  },
  data(){
    return{
      file: '',
      completeSubmit: false,
      userInfo: {},
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods:{
    //file.content意思是获取图片的base64值，van-loader默认处理图片为base64
    async uploadImg(file){
      this.completeSubmit = true
      const result = await handleImage(file)
      file.content = result.base64
      this.file = result.base64
      this.completeSubmit = false
    },

    getUserInfo(){
      //这里很神奇，从sessionStorage里获取的数据既可能是字符串null，也可能是null
      if (sessionStorage.getItem('userInfo') === 'null' || (sessionStorage.getItem('userInfo')??'') === ''){
        this.$axios
            .get("/user/getUserInfo")
            .then(successResponse => {
              this.userInfo = successResponse.data.data
              sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
              this.placeResult = this.userInfo.hometown
            })
            .catch(() => {
              Notify('登录过期，请重新登录')
            })
      } else {
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        this.placeResult = this.userInfo.hometown
      }
    },
    submit(){
      if ((this.userInfo.username??'') === '') {
        Notify('一定要有个好听的名字哦')
      } else if ((this.userInfo.sex??'') === ''){
        Notify('请务必选择性别')
      } else if ((this.userInfo.age??'') === '' || (this.userInfo.age > 150 || this.userInfo.age < 0)) {
        Notify('你是神仙吗？年龄这么填？')
      } else {
        this.completeSubmit = true
        this.userInfo.hometown = this.placeResult
        if (this.file === ''){
          this.userInfo.userHead = JSON.parse(sessionStorage.getItem('userInfo')).userHead
        } else {
          this.userInfo.userHead = this.file
        }
        this.$axios
            .put("/user/editUserInfo", this.userInfo)
            .then(successResponse => {
              if (successResponse.data.code === 2000) {
                router.replace({path:'/home/mine'})
                sessionStorage.removeItem('userInfo')
                Notify({type: 'success', message:'保存成功'})
              } else {
                Notify(successResponse.data.message)
              }
              this.completeSubmit = false
            })
            .catch(() => {
              Notify('登录过期，请重新登录')
              this.completeSubmit = false
            })
      }
    },
  }
}
</script>

<style scoped>
.main{
  height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
}
</style>
