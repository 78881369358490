<template>
  <div style="margin-top: 20px;height: 450px;overflow: auto">
    <van-cell-group inset>
      <van-field
          v-for="(item, index) in applyFromFormat.columns"
          :key="index"
          v-model="columnsValues[index]"
          type="textarea"
          :label="item"
          placeholder="必填"
          style="height: 50px"
          maxlength="150"
      />
    </van-cell-group>

    <van-cell-group inset v-if="(applyFromFormat.pickerTitle??'') !== ''">
      <van-field
          v-model="pickerValue"
          is-link
          type="textarea"
          readonly
          name="picker"
          :label="applyFromFormat.pickerTitle"
          placeholder="必选"
          @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" position="bottom">
        <van-picker
            :columns="applyFromFormat.pickerValues"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>
    </van-cell-group>

    <van-cell-group inset v-if="(applyFromFormat.timePickerTitle??'') !== ''">
      <van-field
          v-model="period"
          type="textarea"
          is-link
          readonly
          :label="applyFromFormat.timePickerTitle"
          placeholder="必选"
          @click="showCalendar = true"
      />
      <van-calendar v-model:show="showCalendar" :show-confirm="false" type="range" @confirm="onConfirm1" />
    </van-cell-group>

    <van-cell-group inset v-if="applyFromFormat.isUpload">
      <van-field name="uploader" label="申请材料">
        <template #input>
          <van-uploader style="width: 60%" upload-icon="" v-model="fileList" :after-read="isEmpty" accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,image/*" upload-text="将所有材料放在一份文件中" :max-count="1" />
        </template>
      </van-field>
    </van-cell-group>

  </div>
</template>

<script>
import { Notify, Skeleton, Picker, Calendar, Field, CellGroup, Uploader, Popup } from 'vant';
import {ref} from "vue";

export default {
  name: "BusinessApply",
  setup() {
    const name = ref('')
    const studentIdNum = ref('')
    const mobilePhone = ref('')
    const pickerValue = ref('')
    const period = ref('');
    const showCalendar = ref(false)
    const showPicker = ref(false)

    const formatDate = (leavePeriod) => `${leavePeriod.getYear() - 100}/${leavePeriod.getMonth() + 1}/${leavePeriod.getDate()}`;
    const onConfirm1 = (values) => {
      const [start, end] = values;
      showCalendar.value = false;
      period.value = `${formatDate(start)}-${formatDate(end)}`;
    };

    const onConfirm = (value) => {
      pickerValue.value = value
      showPicker.value = false
    };
    const fileList = ref([]);

    return {
      name,
      studentIdNum,
      mobilePhone,
      pickerValue,
      showPicker,
      showCalendar,
      period,
      onConfirm,
      onConfirm1,
      fileList,
    };
  },
  data(){
    return{
      showSkeleton: true,
      showDialog: false,
      completeSubmit: false,
      file: '',
      columnsValues: [],
      applyFromFormat: [],
      applyDetailsString: '{',
    }
  },
  props:{
    currentBusinessId: String,
    currentBusinessName: String
  },
  components:{
    [Skeleton.name]: Skeleton,
    [Uploader.name]: Uploader,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Calendar.name]: Calendar,
  },
  methods:{
    getBusinessApplyFrom(){
      this.$axios
          .get("/business/getBusinessApplyFrom", {
            params:{
              id: this.currentBusinessId,
            }
          })
          .then(successResponse => {
            this.applyFromFormat = successResponse.data.data
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    },
    isEmpty(){
      if (this.fileList.length > 0){
        this.file = this.fileList[0].content
      }
    },
    postedBusinessApply(){
      if ((this.columnsValues[0]??'') === ''){
        Notify('请填写完整信息')
      }else {
        this.completeSubmit = true
        Notify({ type: 'primary', message: '正在上传...' });
        //下面是拼接json字符串，后端会解析生成表单结构
        for (let i = 0; i < this.applyFromFormat.columns.length; i++) {
          if (i === this.applyFromFormat.columns.length - 1){
            this.applyDetailsString += '"' + this.applyFromFormat.columns[i] + '":"' + this.columnsValues[i] + '"'
          }else {
            this.applyDetailsString += '"' + this.applyFromFormat.columns[i] + '":"' + this.columnsValues[i] + '",'
          }
        }
        if (this.pickerValue !== ''){
          this.applyDetailsString += ',"' + this.applyFromFormat.pickerTitle + '":"' + this.pickerValue + '"'
        }
        if (this.period !== ''){
          this.applyDetailsString += ',"' + this.applyFromFormat.timePickerTitle + '":"' + this.period + '"'
        }
        if (this.applyFromFormat.isUpload){
          this.applyDetailsString += ',"申请材料":"' + this.pickerValue + '"'
        }
        this.applyDetailsString += '}'
        this.$axios
            .post("/businessApply/postedBusinessApply", {
              businessId: this.currentBusinessId,
              businessName: this.currentBusinessName,
              applyAttachment: this.file,
              applyDetailsString: this.applyDetailsString
            })
            .then(successResponse => {
              if (successResponse.data.code === 200){
                Notify({type: 'success', message:'提交成功'})
              }else if (successResponse.data.code === 503){
                Notify('哎呀网有点慢，再试一次吧')
              }else if (successResponse.data.code === 500){
                Notify('请先登录')
              }
              this.columnsValues = []
              this.pickerValue = ''
              this.file = ''
              this.applyDetailsString = '{'
            })
            .catch(() => {
              Notify('登录过期，请重新登录')
            })
        this.$emit('getComplete')
      }
    },
  },
}
</script>

<style scoped>

</style>