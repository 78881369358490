<template>
  <van-pull-refresh style="background-color: #ffd45d;" pulling-text="别拉我！" loosing-text="畜生 快松开我！" loading-text="哎呦歪" v-model="loading" @refresh="pullFresh">

    <div class="mui-content">
      <van-cell-group>
        <van-cell @click="showDialog(index)" :title="item.title" title-style="text-align: left" :label="item.postedTime"
                  :value="item.content.substring(0, 13)" v-for="(item, index) in announcementList" :key="item.id"/>
      </van-cell-group>
    </div>

  </van-pull-refresh>

  <van-dialog teleport="#app" v-model:show="show" title="标题">
    <van-cell-group>
      <van-cell title="公告范围" :value="announcementList[this.index].belongCommunity" title-style="text-align: left"/>
      <van-cell title="发送人" :value="announcementList[this.index].postedUsername" title-style="text-align: left"/>
      <van-cell title="发送时间" :value="announcementList[this.index].postedTime" title-style="text-align: left"/>
      <van-field
          v-model="announcementList[this.index].content"
          rows="10"
          autosize
          label="公告内容"
          type="textarea"
          readonly
      />
    </van-cell-group>
  </van-dialog>

</template>

<script>
import {ref} from "vue";
import {Notify, PullRefresh, Toast, Dialog, Cell, CellGroup, Field} from "vant";

export default {
  name: "Announcement",
  setup() {
    //下拉刷新缓冲动画
    const loading = ref(false);

    return {
      loading,
    };
  },
  data() {
    return {
      announcementList: [
        // {title: '徐一杰', content: '烤炉模式的城，到黄昏，如同打翻的调色盘一般.'},
        // {title: '徐一杰', content: '静静的看这个世界，最后终于疯了...'},
        // {title: '徐一杰', content: '能和心爱的人一起睡觉，是件幸福的事情；可是，打呼噜怎么办？'}
      ],
      show: false,
      index: 0,
    }
  },
  components: {
    [PullRefresh.name]: PullRefresh,
    [Toast.name]: Toast,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
  },
  mounted() {
    this.getAnnouncement()
  },
  methods: {
    showDialog(index) {
      this.show = true
      this.index = index
    },
    pullFresh() {
      this.getAnnouncement()
      setTimeout(() => {
        Toast({
          message: '刷新成功',
          icon: 'like-o',
        });
        this.loading = false;
      }, 500);
    },
    getAnnouncement() {
      this.$axios
          .get("/announcement/list")
          .then(successResponse => {
            this.announcementList = successResponse.data.data
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    }
  }
}
</script>

<style scoped>
.mui-content {
  padding-top: 5px;
  padding-bottom: 50px;
  min-height: calc(100vh - 100px);
  overflow: auto;
  background-color: #f2f4f5;
}

.mui-table-view {
  margin-bottom: 5px;
}

.mui-content > .mui-table-view:first-child {
  margin-top: 0;
}
</style>
