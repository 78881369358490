<template>
  <div class="chatting">
    <van-nav-bar
        :title=chattingUsername
        right-text="更多"
        left-arrow
        fixed
        @click-left="onClickLeft"
        @click-right="onClickRight"
    >
      <template #right>
        <van-icon name="wap-nav" size="21" />
      </template>
    </van-nav-bar>


    <div style="height: 80vh">
      <h4>可输入对方手机号来@</h4>
      <div id="message"></div>
    </div>

    <div class="foot">
      <input style="width: 88%" type="text" v-model="postedMobilePhone" placeholder="请输入私聊手机号">
      <input type="text" v-model="postedMsg">
      <van-button style="width: 12%;height: 40px;margin-right: 1%" @click="send" icon="guide-o" round type="primary"></van-button>
    </div>
  </div>
</template>

<script>
import {NavBar, Notify} from 'vant';
import { Toast } from 'vant';
import { Icon } from 'vant';
import {sendNotify} from "@/js/globalWebSocket";
export default {
  emits: ['showChattingDialog'],
  name: "Chatting",
  setup() {
    const onClickRight = () => Toast('敬请期待');

    return {
      onClickRight,
    };
  },
  data() {
    return {
      webSocket: null,
      message: '',
      postedMsg: '',
      postedMobilePhone: '',
      messageLib: {},
      chattingUsername:'群聊',
      showChattingDialog: true,
      disabledPullFresh: false,
      messageLibList: [],
      userInfo: {},
      storageKey: 'groupMessageLib'
    }
  },
  components:{
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Toast.name]: Toast
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    // this.connectWebSocket()
    //心跳检测
    this.timer = setInterval(this.start, 55000);
  },
  methods: {
    getMessageLib(){
      let messageBox = document.getElementById('message');
      while (messageBox.hasChildNodes()){
        messageBox.removeChild(messageBox.firstChild)
      }
      this.messageLibList = []
      if (JSON.parse(sessionStorage.getItem(this.storageKey)) === null) {
        this.$axios
            .get("/message/getMessageLib")
            .then(successResponse => {
              for (let i = 0; i < successResponse.data.data.length; i++) {
                this.messageLibList.push({
                  sendUsername: successResponse.data.data[i].sendUsername,
                  sendMobilePhone: successResponse.data.data[i].sendMobilePhone,
                  message: successResponse.data.data[i].message,
                  messageTime: successResponse.data.data[i].messageTime,
                })
              }
              this.pushMessage(messageBox)
              sessionStorage.setItem(this.storageKey, JSON.stringify(this.messageLibList))
            })
            .catch(() => {
              Notify('登录过期，请重新登录')
            })
      } else {
        this.messageLibList = JSON.parse(sessionStorage.getItem(this.storageKey))
        this.pushMessage(messageBox)
      }
    },
    //推送消息到屏幕
    pushMessage(messageBox) {
      let length = this.messageLibList.length
      let cssCode = '';
      for (let i = length - 1; i > -1; i--) {
        let sendMobilePhoneLength = this.messageLibList[i].sendMobilePhone.length
        //判断是否是自己发送的消息，自己的消息放右边，对方放左边，系统消息居中
        if (sendMobilePhoneLength === 11 && this.messageLibList[i].sendMobilePhone === this.userInfo.mobilePhone) {
          cssCode = '<div style="float: right;width: 100%;margin-bottom: 15px"><p style="text-align:right;margin-right: 20px">' + this.messageLibList[i].sendUsername + '</p>' + '<div style="min-height: 30px;max-width: 60%;word-break: break-all;float: right;margin-right: 10px;padding: 5px 15px;border-radius: 20px;background-color: pink">' + '<div style="text-align:left">' + this.messageLibList[i].message + '</div>' + '</div></div>';
          messageBox.innerHTML += cssCode;
        } else if (sendMobilePhoneLength === 11 && this.messageLibList[i].sendMobilePhone !== this.userInfo.mobilePhone) {
          cssCode = '<div style="float: left;width: 100%;margin-bottom: 15px"><p style="text-align:left;margin-left: 20px">' + this.messageLibList[i].sendUsername + '</p>' + '<div style="min-height: 30px;max-width: 60%;word-break: break-all;float: left;margin-left: 10px;padding: 5px 15px;border-radius: 20px;background-color: pink">' + '<div style="text-align:left">' + this.messageLibList[i].message + '</div>' + '</div></div>';
          messageBox.innerHTML += cssCode;
        }
        this.nextTick()
      }
    },
    //消息自动滚动到底部
    nextTick(){
      this.$nextTick(() => {
        let msg = document.getElementById('message')
        // 滚动高度
        msg.scrollTop = msg.scrollHeight
      })
    },
    onClickLeft(){
      this.showChattingDialog = false
      this.$emit('showChattingDialog', this.showChattingDialog)
    },
    connectWebSocket(){
      //判断当前浏览器是否支持WebSocket
      if ('WebSocket' in window) {
        // this.webSocket = new WebSocket('ws://127.0.0.1:8081/undersea/myWebsocket/' + this.userInfo.mobilePhone);
        if (this.webSocket == null) {
          this.webSocket = new WebSocket('wss://undersea.xuyijie.icu/undersea/myWebsocket/' + this.userInfo.mobilePhone);
        }
      } else {
        Notify('该设备不支持websocket！')
      }
      //连接发生错误的回调方法
      this.webSocket.onerror = function() {
        this.message="连接错误";
        //document.getElementById('message').innerHTML += this.message + '<br/>';
      };
      //连接成功建立的回调方法
      // this.webSocket.onopen = function() {
      //   this.message="Loc MSG: 成功建立连接";
      //   document.getElementById('message').innerHTML += this.message + '<br/>';
      // }
      //接收到消息的回调方法
      this.webSocket.onmessage = function(event) {
        let messageArray = event.data
        func1(messageArray)
      }
      //func1和func2是为了解决websocket无法直接调用vue的方法的问题的
      let func2 = function func3(messageArray) {
        //在此处即可同时使用websocket的数据和data数据，method函数
        let cssCode = '';
        let messageBox = document.getElementById('message');
        let mobilePhoneAndUsername = messageArray.substring(0, messageArray.lastIndexOf("："))
        let mobilePhone = mobilePhoneAndUsername.substring(0, 11)
        let username = mobilePhoneAndUsername.substring(11, mobilePhoneAndUsername.length)
        let message = messageArray.substring(messageArray.lastIndexOf("：")).replace('：','')
        //判断是否是自己发送的消息，自己的消息放右边，对方放左边，系统消息居中
        if (mobilePhoneAndUsername === '系统消息' || mobilePhoneAndUsername.startsWith('恭喜')) {
          document.getElementById('message').innerHTML += messageArray + '<br/>';
        }else if (mobilePhone !== this.userInfo.mobilePhone){
          cssCode = '<div style="float: left;width: 100%;margin-bottom: 15px"><p style="text-align:left;margin-left: 20px">' + username + '</p>' + '<div style="min-height: 30px;max-width: 60%;word-break: break-all;float: left;margin-left: 10px;padding: 5px 15px;border-radius: 20px;background-color: pink">' + '<div style="text-align:left">' + message + '</div>' + '</div></div>';
          messageBox.innerHTML += cssCode;
        }else if (mobilePhone === this.userInfo.mobilePhone){
          // cssCode = '<div style="float: right;width: 100%;margin-bottom: 15px"><p style="text-align:right;margin-right: 20px">' + username + '</p>' + '<div style="min-height: 30px;max-width: 60%;word-break: break-all;float: right;margin-right: 10px;padding: 5px 15px;border-radius: 20px;background-color: pink">' + '<div style="text-align:left">' + message + '</div>' + '</div></div>';
          // messageBox.innerHTML += cssCode;
        }
        this.nextTick()
      }
      let func1 = func2.bind(this)

      //连接关闭的回调方法
      this.webSocket.onclose = function() {
        this.message="Loc MSG:关闭连接";
        //document.getElementById('message').innerHTML += this.message + '<br/>';
      }
      //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      // window.onbeforeunload = function() {
      //   this.webSocket.close();
      // }
    },
    //关闭连接
    closeWebSocket() {
      this.webSocket.close();
    },
    //发送消息
    send() {
      if (this.message === '连接错误' || this.message === 'Loc MSG:关闭连接') {
        this.connectWebSocket()
        console.log('连接断开， 尝试重连')
      }
      this.messageLib.type = '0';
      this.messageLib.message = this.postedMsg
      this.messageLib.sendMobilePhone = this.userInfo.mobilePhone
      this.messageLib.sendUsername = this.userInfo.username
      this.messageLib.receiveMobilePhone = this.postedMobilePhone
      if (this.postedMobilePhone !== ''){
        //群内@
        this.messageLib.type = '@';
        sendNotify(JSON.stringify(this.messageLib))
      }
      let messageLibString = JSON.stringify(this.messageLib)
      this.webSocket.send(messageLibString);
      //推送消息到屏幕
      let messageBox = document.getElementById('message');
      let cssCode = '<div style="float: right;width: 100%;margin-bottom: 15px"><p style="text-align:right;margin-right: 20px">' + this.userInfo.username + '</p>' + '<div style="min-height: 30px;max-width: 60%;word-break: break-all;float: right;margin-right: 10px;padding: 5px 15px;border-radius: 20px;background-color: pink">' + '<div style="text-align:left">' + this.postedMsg + '</div>' + '</div></div>';
      messageBox.innerHTML += cssCode;
      this.nextTick()
      this.postedMsg = ''
      let messageLibList = JSON.parse(sessionStorage.getItem(this.storageKey))
      messageLibList.unshift(this.messageLib)
      sessionStorage.setItem(this.storageKey, JSON.stringify(messageLibList))
    },
    // 心跳检测, 每隔一段时间检测连接状态，如果处于连接中，
    // 就向server端主动发送消息，来重置server端与客户端的最大连接时间，如果已经断开了，发起重连。
    start() {
        if (this.message === '连接错误' || this.message === 'Loc MSG:关闭连接') {
          this.connectWebSocket()
          console.log('连接断开， 尝试重连')
        } else {
          this.messageLib = {}
          this.messageLib.type = '心跳检测'
          this.webSocket.send(JSON.stringify(this.messageLib));
        }
    }
  }

}
</script>

<style scoped>
.chatting{
  background-color: white;
  padding-top: 50px;
  height: 100%;
}
#message{
  height: 75%;
  margin-top:10px;
  overflow: auto;
}
.foot{
  position: fixed;
  bottom: 0;
}
.foot input{
  border-radius: 20px;
  width: 75%;
  height: 40px;
  margin-right: 1%;
  margin-left: 1%
}
</style>
