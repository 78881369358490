import {Notify} from "vant";
let globalWebSocket = null;
export function connectGlobal(){
    if ('WebSocket' in window) {
        // globalWebSocket = new WebSocket('ws://127.0.0.1:8081/undersea/globalWebsocket/' + JSON.parse(sessionStorage.getItem('userInfo')).mobilePhone);
        globalWebSocket = new WebSocket('wss://undersea.xuyijie.icu/undersea/globalWebsocket/' + JSON.parse(sessionStorage.getItem('userInfo')).mobilePhone);
    } else {
        Notify('该设备不支持websocket！')
    }
    globalWebSocket.onopen = function() {
        console.log('建立连接')
    }
    globalWebSocket.onmessage = function(event) {
        Notify({ type: 'primary', message: event.data });
        console.log('收到消息:' + event.data)
    }
    globalWebSocket.onerror = function() {
        console.log('通信发生错误！')
    }
    globalWebSocket.onclose = function() {
        console.log('连接关闭')
    }
    //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
    window.onbeforeunload = function() {
        globalWebSocket.close();
    }
}

export function sendNotify(message){
    if (globalWebSocket == null) {
        connectGlobal();
    }
    globalWebSocket.send(message);
}
