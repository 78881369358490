<template>
  <div style="height: 450px;overflow: auto">
    <van-empty v-if="communityList.length < 1" description="还没有加入过社区哦">
      <van-button round type="primary" class="bottom-button" @click="this.$router.push({name: 'CommunityCertification'})">加一个？</van-button>
    </van-empty>

    <van-swipe-cell v-else v-for="(item, index) in communityList" :key="index">
      <!--      <template #left>-->
      <!--        <van-button square type="primary" text="选择" />-->
      <!--      </template>-->
      <van-cell-group>
        <van-cell :title="item" title-style="text-align: left"/>
      </van-cell-group>
      <template #right>
        <van-button @click="deleteCommunity" square type="danger" text="退出社区"/>
      </template>
    </van-swipe-cell>
  </div>
</template>

<script>
import {Skeleton, SwipeCell, Cell, CellGroup, Notify, Empty} from 'vant';

export default {
  name: "MyCommunities",
  setup() {
    const onClickLeft = () => history.back();

    return {
      onClickLeft,
    };
  },
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Skeleton.name]: Skeleton,
    [SwipeCell.name]: SwipeCell,
    [Empty.name]: Empty,
  },
  data() {
    return {
      completeSubmit: false,
      communityList: [],
      user: {},
    }
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.communityList = this.user.belongCommunity
  },
  methods: {
    deleteCommunity() {
      Notify('就不让你退')
    },
  },
}
</script>

<style scoped>

</style>