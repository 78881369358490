<template>
  <div style="height: 100vh;background-color: white">
    <van-empty v-if="!isVerify" description="正在验证..."/>
    <van-empty v-else :description='"验证成功！" + remainSecond + "秒后自动跳转登录..."'>
      <van-button round type="warning" class="bottom-button" @click="this.$router.replace({name: 'Index'})">
        立即登录
      </van-button>
    </van-empty>
  </div>
</template>

<script>
//vue-router里的方法只能在setup中使用
import { useRouter, useRoute } from 'vue-router';
import {Empty, Notify} from 'vant';
import {onMounted, ref, watch} from "vue";
import axios from "axios";

export default {
  name: "RegisterEmailVerification",
  components: {
    [Empty.name]: Empty,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const remainSecond =  ref(3)
    const isVerify = ref(false)
    const email = ref('')
    const code = ref('')

    onMounted(() => {
      email.value = route.query.email
      code.value = route.query.code
      registerEmailVerification()
    })

    const registerEmailVerification = () => {
      axios.get(`/user/registerEmailVerification?email=${email.value}&code=${code.value}`)
          .then(success => {
            if (success && success.data.code === 2000) {
              Notify({type: 'success', message: success.data.message})
              isVerify.value = true
              second()
            } else {
              Notify({type: 'danger', message: success.data.message})
            }
          })
          .catch(() => {
            Notify('很抱歉验证失败！请刷新页面来重新验证')
          })
    }

    const second = () => {
      if (remainSecond.value > 0) {
        setInterval(() => {
          remainSecond.value--
        }, 1000)
      }
    }

    watch(remainSecond, (newValue) => {
      if (newValue === 0) {
        router.replace({name: 'Index'})
      }
    });

    return {
      remainSecond,
      isVerify,
      email,
      registerEmailVerification,
      second
    }
  },
}
</script>

<style scoped>

</style>
