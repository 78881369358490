<template>
  <div class="main">
    <van-nav-bar
        title="创建事务"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <br>

    <van-cell-group inset>
      <van-field
          v-model="community"
          type="textarea"
          is-link
          style="height: 45px"
          label="社区"
          placeholder="选择社区"
          @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
            title="社区"
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
        />
      </van-popup>
      <van-field style="height: 45px" type="textarea" v-model="businessName" label="事务名称" placeholder="请输入事务名称" />
    </van-cell-group>

    <van-divider>表单字段 点击+可新增&emsp;<van-stepper :show-input="false" @change="vForColumn" v-model="value" /></van-divider>
    <van-cell-group inset>
      <van-field v-for="index in columnsNum" :key="index" style="height: 45px" type="textarea" v-model="columnValues[index - 1]" label="表单字段" placeholder="提交事务需要输入的字段" />
    </van-cell-group>

    <van-divider>是否配置下拉选项框&emsp;<van-switch v-model="checked" size="24px" /></van-divider>
    <van-cell-group v-if="checked" inset>
      <van-field style="height: 45px" type="textarea" v-model="pickerTitle" label="选修框标题" placeholder="请输入选修框标题" />
      <van-field style="height: 150px" type="textarea" v-model="pickerValuesString" label="选修框字段" placeholder="字段使用逗号隔开" />
    </van-cell-group>

    <van-divider>是否需要时间选择器&emsp;<van-switch v-model="checked3" size="24px" /></van-divider>
    <van-cell-group v-if="checked3" inset>
      <van-field style="height: 45px" type="textarea" v-model="timePickerTitle" label="选修框标题" placeholder="请输入时间选择器标题" />
    </van-cell-group>

    <van-divider>是否需要文件上传&emsp;<van-switch v-model="checked2" size="24px" /></van-divider>

    <br>

    <div style="margin: 16px;">
      <van-button @click="submit" :disabled="completeSubmit" style="height: 40px" round block type="primary">
        创建事务
      </van-button>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { ref } from 'vue';
import { Field, CellGroup, Notify, Popup, Divider, Stepper, Switch, NavBar, Picker } from 'vant';

export default {
  name: "BusinessEdit",
  setup() {
    const onClickLeft = () => history.back();
    const identityNumber = ref('')

    const showPicker = ref(false);
    const community = ref('')
    const columns = ['温州商学院', '信阳', '温州', '碧海名居']

    const value = ref(0)
    const checked = ref(false);
    const checked2 = ref(false);
    const checked3 = ref(false);

    const onConfirm = (value) => {
      community.value = value
      showPicker.value = false;
    };

    return {
      identityNumber,
      onClickLeft,
      columns,
      showPicker,
      onConfirm,
      community,
      value,
      checked,
      checked2,
      checked3,
    };
  },
  components:{
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Divider.name]: Divider,
    [Stepper.name]: Stepper,
    [Switch.name]: Switch,
  },
  data(){
    return{
      completeSubmit: false,
      businessName: '',
      columnsNum: 1,
      columnValues: [],
      pickerValuesString: '',
      pickerTitle: '',
      timePickerTitle: '',
    }
  },
  methods:{
    vForColumn(){
      this.columnsNum = this.value
    },
    submit(){
      if (this.community === ''){
        Notify('要先选择创建事务的社区哦')
      }if (this.businessName === ''){
        Notify('事务名称必填')
      }else {
        this.completeSubmit = true
        this.$axios
            .post("/business/establishBusiness", {
              businessName: this.businessName,
              businessIcon: 'underway',
              belongCommunity : this.community,
              columns: this.columnValues,
              pickerValues: this.pickerValuesString.replaceAll(" ", "").replaceAll("，", ",").split(","),
              pickerTitle: this.pickerTitle,
              timePickerTitle: this.timePickerTitle,
              isUpload : this.checked2,
              founder: JSON.parse(sessionStorage.getItem('userInfo')).mobilePhone
            })
            .then(() => {
              router.go(-1)
              Notify({type: 'success', message:'创建成功'})
              this.completeSubmit = false
            })
            .catch(() => {
              Notify('登陆过期，请重新登录')
              this.completeSubmit = false
            })
      }
    },
  },
}
</script>

<style scoped>
.main{
  height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
}
</style>