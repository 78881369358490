<template>
  <div>
    <van-nav-bar
        title=封禁用户
        left-text="返回"
        fixed
        left-arrow
        style="padding-top: 50px"
        @click-left="onClickLeft"
    />

    <van-pull-refresh style="background-color: #ffd45d;padding-top: 95px" pulling-text="别拉我！" loosing-text="畜生 快松开我！" loading-text="哎呦歪" v-model="loading" @refresh="pullFresh">
      <div style="min-height: calc(100vh - 140px);background-color: #f2f4f5;padding-top: 5px">
        <input class="ipt" type="text" placeholder="搜索关键字" v-model="searchString">
        <van-skeleton style="height: 65px;background-color: #e2e5ea;" v-if="showSkeleton" avatar-size="35px" title-width="100%" round title avatar :row="1" />
        <van-swipe-cell v-else v-for="(item, index) in memberList" :key="index">
          <template #left>
            <van-button @click="handleReleasePunish(item, index)" square type="primary" text="撤销封禁" />
          </template>
          <van-cell v-if="searchString === '' || item.username.includes(searchString) || item.communityRole.includes(searchString)" @click="getUserInfoAndRelationship(item.mobilePhone)" :title="item.username" :value="item.communityRole" title-style="text-align: left" />
          <template #right>
            <van-button @click="deleteFriend" square type="danger" text="移出社区" />
          </template>
        </van-swipe-cell>
      </div>
    </van-pull-refresh>

    <van-dialog :show-confirm-button="false" style="min-height: 30%;width: 85%"  title="请填写解除封禁原因" v-model:show="showDialog2">
      <van-field type="textarea" v-model="releasePunishReason" maxlength="150" />
      <van-button @click="releasePunish" :disabled="buttonDisabled" style="width: 50%;position: absolute;bottom: 0;left: 0" icon="checked" type="primary">确定</van-button>
      <van-button @click="this.showDialog2 = false" style="width: 50%;position: absolute;bottom: 0" color="indianred" icon="clear" type="warning">取消</van-button>
    </van-dialog>

    <van-dialog :show-confirm-button="false" style="min-height: 70%;width: 85%" v-model:show="showDialog" title="用户资料">
      <div class="main">
        <div v-if="dialogShowSkeleton" class="imgAndName">
          <van-skeleton avatar-size="50px" title-width="100%" round title avatar :row="1" />
        </div>

        <div class="imgAndName" v-else>
          <img :src="userInfo.userHead" height="50" width="50"/>
          <h1>{{ userInfo.username }}</h1>
        </div>
      </div>
      <van-grid class="grid">
        <van-grid-item>
          <p style="font-size: 1.5rem">{{ userInfo.communityNum }}</p>
          <p>TA的社区</p>
        </van-grid-item>
        <van-grid-item>
          <p style="font-size: 1.5rem">{{ userInfo.blogNum }}</p>
          <p>TA的帖子</p>
        </van-grid-item>
        <van-grid-item>
          <p style="font-size: 1.5rem">{{ userInfo.friendNum }}</p>
          <p>我的好友</p>
        </van-grid-item>
        <van-grid-item>
          <p style="font-size: 1.5rem">{{ userInfo.businessNum }}</p>
          <p>事务记录</p>
        </van-grid-item>
      </van-grid>
      <van-cell-group style="text-align: left">
        <van-cell title="性别" :value="userInfo.sex"/>
        <van-cell title="家乡" :value="userInfo.homeTown"/>
        <van-cell title="个性签名" :value="userInfo.characterSignature"/>
        <van-cell title="关系" :value="userInfo.isFriend"/>
      </van-cell-group>
      <div style="width: 100%;position: absolute;bottom: 45px;left: 0">
        <van-button @click="sendFriendApply(userInfo.mobilePhone)" :disabled="addFriendButtonDisabled" style="width: 50%;" icon="plus" type="primary">加好友</van-button>
        <van-button @click="sendSecretMessage" style="width: 50%;" color="palevioletred" icon="envelop-o" type="primary">私信TA</van-button>
      </div>

      <van-button @click="this.showDialog = false" style="width: 100%;position: absolute;bottom: 0;left: 0" type="default">知道了</van-button>

    </van-dialog>

  </div>
</template>

<script>
import {
  NavBar,
  SwipeCell,
  Cell,
  CellGroup,
  Grid,
  GridItem,
  Notify,
  Skeleton,
  Dialog,
  Toast,
  PullRefresh,
  Icon,
  Field
} from 'vant';
import {sendNotify} from "@/js/globalWebSocket";
import {ref} from "vue";

export default {
  name: "PunishUser",
  setup() {
    const onClickLeft = () => history.back();
    const loading = ref(false)

    return {
      onClickLeft,
      loading,
    };
  },
  components:{
    [NavBar.name]: NavBar,
    [SwipeCell .name]: SwipeCell,
    [Cell .name]: Cell,
    [CellGroup .name]: CellGroup,
    [Grid .name]: Grid,
    [GridItem .name]: GridItem,
    [Skeleton .name]: Skeleton,
    [Dialog.Component.name]: Dialog.Component,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Field.name]: Field,
  },
  data(){
    return{
      showSkeleton: true,
      dialogShowSkeleton: true,
      addFriendButtonDisabled: false,
      buttonDisabled: false,
      showDialog: false,
      showDialog2: false,
      memberList: [],
      userInfo: [],
      user: {},
      searchString: '',
      releasePunishReason: '',
      item: {},
      index: 0,
    }
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.getMemberList()
  },
  methods:{
    pullFresh(){
      this.showSkeleton = true
      this.loading = true
      this.getMemberList()
      setTimeout(() => {
        Toast({
          message: '刷新成功',
          icon: 'like-o',
        });
        this.loading = false;
      }, 500);
    },
    getMemberList(){
      this.$axios
          .get("/inform/getPunishedUser")
          .then(successResponse => {
            this.memberList = successResponse.data.data
            this.showSkeleton = false
          })
          .catch(failResponse => {
            Notify('登陆过期，请重新登录')
            console.log(failResponse)
          })
    },
    handleReleasePunish(item, index){
      this.item = item
      this.index = index
      this.showDialog2 = true
    },
    releasePunish(){
      this.item.releasePunishReason = this.releasePunishReason
      this.$axios
          .post("/inform/releasePunishUser", this.item)
          .then(() => {
            this.memberList.splice(this.index, 1)
            Notify({type: 'success', message: '撤销封禁成功'})
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
      this.showDialog2 = false
    },
    deleteFriend(){
      Notify('就不让你删')
    },
    getUserInfoAndRelationship(mobilePhone){
      this.showDialog = true
      this.dialogShowSkeleton = true
      this.addFriendButtonDisabled = true
      if (this.showDialog){
        this.$axios
            .get("/user/getUserInfoAndRelationship",{
              params:{
                mobilePhone: mobilePhone
              }
            })
            .then(successResponse => {
              this.userInfo = successResponse.data.data
              this.addFriendButtonDisabled = this.userInfo.isFriend === '好友';
              this.dialogShowSkeleton = false
            })
            .catch(() => {
              Notify('登录过期，请重新登录')
            })
      }
    },
    sendFriendApply(mobilePhone){
      Dialog.confirm({
        message: '发送好友请求',
      })
          .then(() => {
            // on confirm
            let message = {}
            message.type = '好友申请'
            message.message = '申请添加您为好友'
            message.sendMobilePhone = localStorage.getItem("mobilePhone")
            message.receiveMobilePhone = mobilePhone
            sendNotify(JSON.stringify(message))
            // Notify({ type: 'primary', message: '耐心等待回信哟' });
          })
          .catch(() => {
            // on cancel
          });
    },
    sendSecretMessage(){
      Notify({ type: 'primary', message: '暂不开放' });
    },
  },
  // watch:{
  //   searchString(){
  //     this.getMemberList()
  //   }
  // },
}
</script>

<style scoped>
/*弹出框布局*/
.main{
  margin-top: 20px;
  height: 100%;
  width: 100%;
}
.imgAndName{
  height: 50px;
  width: 100%;
  margin: 0 auto;
}
.imgAndName img{
  margin-left: 20px;
  border-radius: 50%;
}
.imgAndName h1{
  position: relative;
  bottom: 14px;
  display: inline-block;
  font-size: 2rem;
  margin: 0 20px;
}
.grid{
  margin-top: 20px;
  width: 100%;
}
.ipt{
  margin: 0;
  border: 0;
  box-shadow: 0 0 4px 2px #ccc inset;
  transition:all 0.3s ease;
}
input:focus{
  box-shadow: 0 0 5px 3px lightpink inset;
  /*background-color:paleturquoise;*/
  /*transform:scale(1.05,1.05);*/
  transition:all 0.5s;
}
</style>