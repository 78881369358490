<template>
  <div class="index" :class="{registerClass : flag === false}">
    <div class="a">
      <button @click="changeLoginFlag">登录</button>
      <button @click="changeRegisterFlag">注册</button>
    </div>
    <div class="b">
      <transition mode="out-in">
        <Login v-if="flag"></Login>
        <Register v-else @changeFlag="changeLoginFlag"></Register>
      </transition>
    </div>
    <div style="position: fixed;bottom: 10px;left: calc(50% - 105px)">
      <a href="https://beian.miit.gov.cn/" target=_blank><p>浙ICP备2021039379-1号</p></a>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33030402001209">
        <img src="@/assets/beianIcon.png" style="float:left;" alt=""/>
        <p style="float:left;">浙公网安备33030402001209号</p>
      </a>
    </div>
  </div>
</template>

<script>
import Login from "./components/Login";
import Register from "./components/Register";

export default {
  name: "index",
  components: {
    Login: Login,
    Register: Register,
  },
  data() {
    return {
      flag: true
    }
  },
  methods: {
    changeLoginFlag() {
      this.flag = true;
    },
    changeRegisterFlag() {
      this.flag = false;
    },
  }
}
</script>

<style scoped>
.index {
  width: 90%;
  height: 400px;
  position: absolute;
  top: 80px;
  left: 5%;
  padding-top: 30px;
  text-align: center;
  background-color: white;
  border-radius: 17px;
  transition: 0.5s all;
}

.registerClass {
  width: 90%;
  height: 570px;
  position: absolute;
  top: 50px;
  left: 5%;
  padding-top: 30px;
  text-align: center;
  background-color: white;
  border-radius: 17px;
  transition: 0.5s all;
}

.a {
  text-align: center;
  margin: 0 auto;
}

.a button {
  text-decoration: none;
  font-size: 1.5rem;
  display: inline-block;
  width: 90px;
  height: 40px;
  color: plum;
  text-align: center;
  border: 0;
  border-radius: 7px;
  margin: 0 5px;
  transition: all 0.2s;
}

.a button:hover {
  transform: scale(1.1);
}

.a button:active {
  transform: scale(0.9);
}

.b {
  margin: 20px auto;
  text-align: center;
}

.a > :nth-child(1) {
  font-size: 1.5rem;
  width: 100px;
  background-color: #eeb4be;
  color: white;
}

.a > :nth-child(2) {
  font-size: 1.5rem;
  width: 100px;
  background-color: #6abb91;
  color: white;
}

.v-enter-from, .v-leave-to {
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.3s ease-in-out;
}

.v-enter-active, .v-leave-active {
  transition: all 0.3s ease-in-out;
}
</style>
