<template>
  <div class="mainContent">
    <van-nav-bar
        title=我的帖子
        left-arrow
        fixed
        @click-left="onClickLeft"
    >
    </van-nav-bar>

    <van-dropdown-menu :overlay="false" active-color="palevioletred">
      <van-dropdown-item style="position: absolute;top: 90px" v-model="value1" :options="option1"/>
    </van-dropdown-menu>
    <Communication v-if="this.value1 === 0" @showShare="getShowShare" :postedUserMobilePhone="postedUserMobilePhone"></Communication>
    <Exposure v-if="this.value1 === 1" @showShare="getShowShare" :postedUserMobilePhone="postedUserMobilePhone"></Exposure>
    <Announcement v-if="this.value1 === 2"></Announcement>

    <van-share-sheet
        v-model:show="showShare"
        title="立即分享给好友"
        :options="options"
        @select="onSelect"
    />

  </div>
</template>

<script>
import {ref} from "vue";
import {NavBar, ShareSheet, Toast} from 'vant';
import {DropdownMenu, DropdownItem} from 'vant';
import Announcement from "@/views/Home/components/MainContent/components/Announcement";
import Exposure from "@/views/Home/components/MainContent/components/Exposure";
import Communication from "@/views/Home/components/MainContent/components/Communication";

export default {
  name: "MyBlogs",
  setup() {
    //切换tabs
    const activeName = ref('a');
    //分享
    const options = [
      {name: '微信', icon: 'wechat'},
      {name: 'QQ', icon: 'qq'},
      {name: '复制链接', icon: 'link'},
      {name: '二维码', icon: 'qrcode'},
    ];

    return {
      activeName,
      options,
    };
  },
  data() {
    return {
      showShare: false,
      blogNum: JSON.parse(sessionStorage.getItem('userInfo')).blogNum,
      postedUserMobilePhone: JSON.parse(sessionStorage.getItem('userInfo')).mobilePhone,
      value1: 0,
      option1: [{text: '社区交流', value: 0}, {text: '匿名举报', value: 1}, {text: '通知公告', value: 2}],
    }
  },
  components: {
    Communication,
    Exposure,
    Announcement,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [ShareSheet.name]: ShareSheet,
    [NavBar.name]: NavBar,
  },
  methods: {
    onSelect(option) {
      Toast(option.name);
      if (option.name === 'QQ') {
        window.open('https://connect.qq.com/widget/shareqq/index.html?url=undersea.xuyijie.icu', "_blank")
      }
      this.showShare = false
    },
    getShowShare(param) {
      this.showShare = param
    },
    onClickLeft() {
      this.$emit('showMyBlogsDialog', false)
    },
  },
};
</script>

<style scoped>
.mainContent {
  min-height: 500px;
  margin-top: 40px;
}
</style>
