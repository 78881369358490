<template>
  <div>
    <van-nav-bar
        title=添加好友
        fixed
        left-arrow
        @click-left="onClickLeft"
    />

    <van-pull-refresh style="background-color: #ffd45d;padding-top: 45px" pulling-text="别拉我！" loosing-text="畜生 快松开我！" loading-text="哎呦歪" v-model="loading" @refresh="pullFresh">
      <div style="min-height: calc(100vh - 140px);background-color: #f2f4f5;padding-top: 5px">
        <input class="ipt" type="text" placeholder="搜索关键字" v-model="searchString">
        <van-skeleton style="height: 65px;background-color: #e2e5ea;" v-if="showSkeleton" avatar-size="35px" title-width="100%" round title avatar :row="1" />
        <van-swipe-cell v-else v-for="(item, index) in userList" :key="index">
          <van-cell v-if="searchString === '' || item.username.includes(searchString) || item.mobilePhone.includes(searchString)" @click="getUserInfoAndRelationship(item.mobilePhone)" :title="item.username" :value="item.sex" title-style="text-align: left" />
          <template #right>
            <van-button @click="sendFriendApply(item.mobilePhone)" square type="danger" text="加为好友" />
          </template>
        </van-swipe-cell>
      </div>
    </van-pull-refresh>

    <van-dialog teleport="#app" :show-confirm-button="false" style="min-height: 70%;width: 85%" v-model:show="showDialog" title="用户资料">
      <div class="main">
        <div v-if="dialogShowSkeleton" class="imgAndName">
          <van-skeleton avatar-size="50px" title-width="100%" round title avatar :row="1" />
        </div>

        <div class="imgAndName" v-else>
          <img :src="userInfo.userHead" height="50" width="50"/>
          <h1>{{ userInfo.username }}</h1>
        </div>
      </div>
      <van-grid class="grid">
        <van-grid-item>
          <p style="font-size: 1.5rem">{{ userInfo.communityNum }}</p>
          <p>TA的社区</p>
        </van-grid-item>
        <van-grid-item>
          <p style="font-size: 1.5rem">{{ userInfo.blogNum }}</p>
          <p>TA的帖子</p>
        </van-grid-item>
        <van-grid-item>
          <p style="font-size: 1.5rem">{{ userInfo.friendNum }}</p>
          <p>我的好友</p>
        </van-grid-item>
        <van-grid-item>
          <p style="font-size: 1.5rem">{{ userInfo.businessNum }}</p>
          <p>事务记录</p>
        </van-grid-item>
      </van-grid>
      <van-cell-group style="text-align: left">
        <van-cell title="性别" :value="userInfo.sex"/>
        <van-cell title="家乡" :value="userInfo.hometown"/>
        <van-cell title="个性签名" :value="userInfo.characterSignature"/>
        <van-cell title="关系" :value="userInfo.isFriend"/>
      </van-cell-group>
      <div style="width: 100%;position: absolute;bottom: 45px;left: 0">
        <van-button @click="sendFriendApply(userInfo.mobilePhone)" :disabled="addFriendButtonDisabled" style="width: 50%;" icon="plus" type="primary">加好友</van-button>
        <van-button @click="sendSecretMessage" style="width: 50%;" color="palevioletred" icon="envelop-o" type="primary">私信TA</van-button>
      </div>

      <van-button @click="this.showDialog = false" style="width: 100%;position: absolute;bottom: 0;left: 0" type="default">知道了</van-button>

    </van-dialog>

  </div>
</template>

<script>
import {NavBar, SwipeCell, Cell, CellGroup, Grid, GridItem, Notify, Skeleton, Dialog, Toast, PullRefresh, Icon} from 'vant';
import {sendNotify} from "@/js/globalWebSocket";
import {ref} from "vue";
export default {
  name: "AddFriend",
  setup() {
    const loading = ref(false)

    return {
      loading,
    };
  },
  components:{
    [NavBar.name]: NavBar,
    [SwipeCell .name]: SwipeCell,
    [Cell .name]: Cell,
    [CellGroup .name]: CellGroup,
    [Grid .name]: Grid,
    [GridItem .name]: GridItem,
    [Skeleton .name]: Skeleton,
    [Dialog.Component.name]: Dialog.Component,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
  },
  data(){
    return{
      showSkeleton: true,
      dialogShowSkeleton: true,
      addFriendButtonDisabled: false,
      showDialog: false,
      userList: [],
      userInfo: [],
      user: {},
      searchString: '',
    }
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.getUserList()
  },
  methods:{
    onClickLeft(){
      this.$emit('showAddFriendDialog')
    },
    pullFresh(){
      this.showSkeleton = true
      this.loading = true
      this.getUserList()
      setTimeout(() => {
        Toast({
          message: '刷新成功',
          icon: 'like-o',
        });
        this.loading = false;
      }, 500);
    },
    getUserList(){
      this.$axios
          .get("/friend/getAllUsers")
          .then(successResponse => {
            this.userList = successResponse.data.data
            this.showSkeleton = false
          })
          .catch(failResponse => {
            Notify('登陆过期，请重新登录')
            console.log(failResponse)
          })
    },
    getUserInfoAndRelationship(mobilePhone){
      this.showDialog = true
      this.dialogShowSkeleton = true
      this.addFriendButtonDisabled = true
      if (this.showDialog){
        this.$axios
            .get("/user/getUserInfoAndRelationship",{
              params:{
                mobilePhone: mobilePhone
              }
            })
            .then(successResponse => {
              this.userInfo = successResponse.data.data
              this.addFriendButtonDisabled = this.userInfo.isFriend === '好友';
              this.dialogShowSkeleton = false
            })
            .catch(() => {
              Notify('登陆过期，请重新登录')
            })
      }
    },
    sendFriendApply(mobilePhone){
      Dialog.confirm({
        message: '发送好友请求',
      })
          .then(() => {
            // on confirm
            let message = {}
            message.type = '好友申请'
            message.message = '申请添加您为好友'
            message.sendMobilePhone = this.user.mobilePhone
            message.sendUsername = this.user.username
            message.receiveMobilePhone = mobilePhone
            sendNotify(JSON.stringify(message))
            // Notify({ type: 'primary', message: '耐心等待回信哟' });
          })
          .catch(() => {
            // on cancel
          });
    },
    sendSecretMessage(){
      Notify({ type: 'primary', message: '暂不开放' });
    },
  },
}
</script>

<style scoped>
/*弹出框布局*/
.main{
  margin-top: 20px;
  height: 100%;
  width: 100%;
}
.imgAndName{
  height: 50px;
  width: 100%;
  margin: 0 auto;
}
.imgAndName img{
  margin-left: 20px;
  border-radius: 50%;
}
.imgAndName h1{
  position: relative;
  bottom: 14px;
  display: inline-block;
  font-size: 2rem;
  margin: 0 20px;
}
.grid{
  margin-top: 20px;
  width: 100%;
}
.ipt{
  margin: 0;
  border: 0;
  box-shadow: 0 0 4px 2px #ccc inset;
  transition:all 0.3s ease;
}
input:focus{
  box-shadow: 0 0 5px 3px lightpink inset;
  /*background-color:paleturquoise;*/
  /*transform:scale(1.05,1.05);*/
  transition:all 0.5s;
}
</style>
