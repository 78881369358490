<template>
  <div class="main">
    <van-nav-bar
        :title="communityName + '事务处理'"
        left-text="返回"
        fixed
        style="padding-top: 50px"
        left-arrow
        @click-left="onClickLeft"
    />

    <van-pull-refresh style="padding-top: 45px;background-color: #ffd45d" pulling-text="别拉我！" loosing-text="畜生 快松开我！" loading-text="哎呦歪" v-model="loading" @refresh="pullFresh">
      <div style="min-height: calc(100vh - 140px);background-color: #f2f4f5;padding-top: 5px">
        <van-skeleton style="height: 70px;background-color: #e2e5ea;" v-if="showSkeleton" avatar-size="45px" title-width="100%" round title avatar :row="3" />
        <van-cell-group v-else v-for="(item, index) in businessProcessingList" :key="index">
          <van-cell :class="item.processingResult === '暂未处理' ? 'notRead':'read'" :title="item.businessName" :value="item.applyUsername" label="查看详情" @click="goProcessBusiness(index)" title-style="text-align: left" />
        </van-cell-group>
      </div>
    </van-pull-refresh>

    <van-dialog :show-confirm-button="false" style="min-height: 70%;width: 85%" v-model:show="showDialog" :title="businessName">
      <van-cell-group v-for="(value, key, index) in businessApplyDetails.applyDetails" :key="index" style="text-align: left">
        <van-cell v-if="key === '申请材料' && value !== ''" :title="key" value="点击查看" :url="value" />
        <van-cell v-else :title="key" :value="value"/>
      </van-cell-group>
      <van-cell title="申请时间" :value="businessApplyDetails.applyTime" title-style="text-align: left"/>
      <div style="width: 100%;position: absolute;bottom: 45px;left: 0">
        <van-button @click="agreeBusinessApply" :disabled="buttonDisabled || businessApplyDetails.applyTime" style="width: 50%;" icon="checked" type="primary">同意</van-button>
        <van-button @click="refuseBusinessApply" :disabled="businessApplyDetails.applyTime" style="width: 50%;" color="indianred" icon="clear" type="warning">驳回</van-button>
      </div>
      <van-button @click="showDialog = false" style="width: 100%;position: absolute;bottom: 0;left: 0" type="default">
        {{ businessApplyDetails.applyTime ? businessApplyDetails.processingResult : '稍后处理' }}</van-button>
    </van-dialog>

    <van-dialog :show-confirm-button="false" style="min-height: 30%;width: 85%"  title="请填写驳回理由" v-model:show="showDialog2">
      <van-field type="textarea" v-model="refusedReason" maxlength="150" />
      <van-button @click="submitBusinessApplyProcess" :disabled="buttonDisabled" style="width: 50%;position: absolute;bottom: 0;left: 0" icon="checked" type="primary">确定</van-button>
      <van-button @click="this.showDialog2 = false" style="width: 50%;position: absolute;bottom: 0" color="indianred" icon="clear" type="warning">取消</van-button>
    </van-dialog>

  </div>
</template>

<script>
//vue-router里的方法只能在setup中使用
import { useRoute } from 'vue-router';
import {Skeleton, Cell, CellGroup, NavBar, Notify, Toast, PullRefresh, Icon, Dialog, Field} from 'vant';
import {ref} from "vue";

export default {
  name: "BusinessProcessingList",
  setup() {
    const onClickLeft = () => history.back();
    //下拉刷新
    const loading = ref(false);
    const route = useRoute()
    const communityName = ref('')
    const getRouteParams = () => {
      if ((route.query.communityName??'') !== ''){
        communityName.value = route.query.communityName
      }
    }

    return {
      onClickLeft,
      loading,
      getRouteParams,
      route,
      communityName,
    };
  },
  components:{
    [NavBar.name]: NavBar,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Skeleton.name]: Skeleton,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
  },
  data(){
    return{
      showSkeleton: true,
      showDialog: false,
      //驳回理由弹窗
      showDialog2: false,
      buttonDisabled: false,
      businessProcessingList: [],
      businessApplyDetails: {},
      businessName: '',
      businessApplyId: '',
      refusedReason: '',
      applyUsername: '',
      applyMobilePhone: '',
      applyEmail: '',
      //乐观锁版本号
      version: 0,
      //事务申请列表下标
      index: 0,
      //处理结果
      processingResult: '',
      //操作成功提醒内容
      dialogMessage: '',
    }
  },
  mounted() {
    this.getRouteParams()
    this.getBusinessProcessingList()
  },
  methods:{
    getBusinessProcessingList(){
      this.showSkeleton = true
      this.$axios
          .get("/businessApply/getBusinessApply",{
            params:{
              businessBelongCommunity: this.communityName
            }
          })
          .then(successResponse => {
            this.businessProcessingList = successResponse.data.data
            this.showSkeleton = false
          })
          .catch(() => {
            Notify('登陆过期，请重新登录')
          })
    },
    pullFresh(){
      this.showSkeleton = true
      this.loading = true
      this.getBusinessProcessingList()
      setTimeout(() => {
        Toast({
          message: '刷新成功',
          icon: 'like-o',
        });
        this.loading = false;
      }, 500);
    },
    goProcessBusiness(index){
      this.index = index
      this.showDialog = true
      this.businessName = this.businessProcessingList[index].businessName
      this.businessApplyId = this.businessProcessingList[index].id
      this.applyUsername = this.businessProcessingList[index].applyUsername
      this.applyEmail = this.businessProcessingList[index].applyEmail
      this.applyMobilePhone = this.businessProcessingList[index].applyMobilePhone
      this.version = this.businessProcessingList[index].version
      this.$axios
          .get("/businessApply/getBusinessApplyDetails",{
            params:{
              id: this.businessApplyId,
            }
          })
          .then(successResponse => {
            this.businessApplyDetails = successResponse.data.data
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    },
    agreeBusinessApply() {
      this.processingResult = '已同意'
      this.dialogMessage = '已同意申请'
      this.submitBusinessApplyProcess()
    },
    refuseBusinessApply(){
      this.processingResult = '已拒绝'
      this.showDialog2 = true
      this.dialogMessage = '已驳回申请'
    },
    submitBusinessApplyProcess(){
      Dialog.confirm({
        message: '确定提交吗？',
      })
      .then(() => {
        this.buttonDisabled = true
        this.$axios
            .post("/businessApply/processBusinessApply",{
              id: this.businessApplyId,
              processingResult: this.processingResult,
              refusedReason: this.refusedReason,
              applyUsername: this.applyUsername,
              applyEmail: this.applyEmail,
              applyMobilePhone: this.applyMobilePhone,
              version: this.version,
            })
            .then(successResponse => {
              this.showDialog = false
              this.showDialog2 = false
              this.businessProcessingList[this.index].processingResult = this.processingResult
              this.buttonDisabled = false
              if (successResponse.data.code === 5007) {
                Notify({ type: 'warning', message: '此申请已被其他管理员处理' });
                this.getBusinessProcessingList()
              } else {
                Notify({ type: 'success', message: this.dialogMessage });
              }
            })
            .catch(() => {
              Notify('登录过期，请重新登录')
            })
      })
      .catch(() => {
        // on cancel
      });
    },
  },
}
</script>

<style scoped>
.main{
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
}
.notRead{
  background-color: lightblue
}
</style>
