<template>
  <van-pull-refresh :disabled="disabledPullFresh" pulling-text="别拉我！" loosing-text="畜生 快松开我！" loading-text="哎呦歪" v-model="loading" @refresh="pullFresh">

    <div class="mui-content">
      <ul class="mui-table-view mui-table-view-chevron">
        <li @click="goFriendsApply" class="mui-table-view-cell mui-media" >
          <a class="mui-navigate-right">
            <div class="mui-media-body" style="float: left">
              <van-icon name="smile" color="deepskyblue" size="1.4rem"/>
              <span style="margin-left: 5px;text-align: left;font-size: 1.1rem">好友申请</span>
            </div>
          </a>
        </li>
        <li @click="goFriendsList" class="mui-table-view-cell mui-media" >
          <a class="mui-navigate-right">
            <div class="mui-media-body" style="float: left">
              <van-icon name="friends" color="deepskyblue" size="1.4rem"/>
              <span style="margin-left: 5px;text-align: left;font-size: 1.1rem">好友列表</span>
            </div>
          </a>
        </li>
        <li @click="goChatting" class="mui-table-view-cell mui-media" >
          <a class="mui-navigate-right">
            <div class="mui-media-body" style="float: left">
              <van-icon name="comment-circle" color="deepskyblue" size="1.4rem"/>
              <span style="margin-left: 5px;text-align: left;font-size: 1.1rem">全区群聊</span>
            </div>
          </a>
        </li>
      </ul>

      <van-swipe-cell v-for="(item, index) in messageList" :key="index">
        <van-skeleton style="height: 65px;background-color: #e2e5ea;" v-if="showSkeleton" avatar-size="35px" title-width="100%" round title avatar :row="1" />
        <van-cell v-else @click="goSingleChatting(item.receiveMobilePhone, item.receiveUsername, item.receiveUserHead, index)" title-style="text-align: left;font-size: 1rem" :title="item.receiveUsername" :label="item.lastMessageTime" :value="item.lastMessage.substring(0, 10)" />
        <template #right>
          <van-button style="height: 100%" square type="danger" text="删除" @click="deleteMessageList" />
        </template>
      </van-swipe-cell>

      <van-dialog :show-confirm-button="false" style="height: 85%;width: 90%;margin-top: 40px;overflow: auto" :show-cancel-button="false" v-model:show="showFriendsApplyDialog">
        <FriendsApply @showFriendsApplyDialog="getShowFriendsApplyDialog" ref="friendsApply"></FriendsApply>
      </van-dialog>

      <van-dialog :show-confirm-button="false" style="height: 85%;width: 90%;margin-top: 40px;overflow: auto" :show-cancel-button="false" v-model:show="showFriendsListDialog">
        <FriendsList :isMyFriendList="'0'" @showAddFriendDialog="getShowAddFriendDialog" @showFriendsListDialog="getShowFriendsListDialog" @changeSingleChattingDialog="getChangeSingleChattingDialog" ref="friendsList"></FriendsList>
      </van-dialog>
      <van-dialog :show-confirm-button="false" style="height: 85%;width: 90%;margin-top: 40px;overflow: auto" :show-cancel-button="false" v-model:show="showAddFriendDialog">
        <AddFriend @showAddFriendDialog="getShowAddFriendDialog"></AddFriend>
      </van-dialog>

      <van-dialog v-if="show" :show-confirm-button="false" style="width: 90%;margin-top: 40px" :show-cancel-button="false" v-model:show="showChattingDialog">
        <Chatting @showChattingDialog="getShowChattingDialog" ref="chatting"></Chatting>
      </van-dialog>

      <van-dialog v-if="show" :show-confirm-button="false" style="width: 90%;margin-top: 40px" :show-cancel-button="false" v-model:show="showSingleChattingDialog">
        <SingleChatting :receiveMobilePhone="receiveMobilePhone" :receiveUsername="receiveUsername" :receiveUserHead="receiveUserHead" @showSingleChattingDialog="getShowSingleChattingDialog" ref="singleChatting"></SingleChatting>
      </van-dialog>

    </div>

  </van-pull-refresh>

</template>

<script>
import { Dialog } from 'vant';
import {Notify, PullRefresh, SwipeCell, Cell, Toast, Icon} from 'vant';
import {ref} from "vue";
import { Skeleton } from 'vant';
import Chatting from "./components/Chatting";
import FriendsList from "./components/FriendsList";
import FriendsApply from "./components/FriendsApply";
import SingleChatting from "./components/SingleChatting";
import AddFriend from "./components/AddFriend";
export default {
  name: "Message",
  setup(){
    //下拉刷新缓冲动画
    const loading = ref(false);

    return {
      loading,
    };
  },
  data(){
    return{
      messageList: [
        {receiveUsername: '徐一杰', receiveMobilePhone:'18238264105', lastMessage: '烤炉模式的城，如同打翻的调色盘一般', lastMessageTime: '2022-03-26 13:34'},
      ],
      showSkeleton: true,
      showFriendsApplyDialog: false,
      showFriendsListDialog: false,
      showAddFriendDialog: false,
      showChattingDialog: false,
      showSingleChattingDialog: false,
      disabledPullFresh: false,
      receiveMobilePhone: '',
      receiveUsername: '',
      receiveUserHead: '',
      index: 0,
      show: false,
    }
  },
  components:{
    AddFriend,
    SingleChatting,
    FriendsApply,
    FriendsList,
    Chatting,
    [SwipeCell.name]: SwipeCell,
    [Cell.name]: Cell,
    [PullRefresh.name]: PullRefresh,
    [Toast.name]: Toast,
    [Skeleton.name]: Skeleton,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  mounted() {
    this.getMessageList()
  },
  methods:{
    pullFresh(){
      this.getMessageList()
      setTimeout(() => {
        Toast({
          message: '刷新成功',
          icon: 'like-o',
        });
        this.loading = false;
      }, 500);
    },
    getMessageList(){
      this.$axios
          .get("/message/getMessageList")
          .then(successResponse => {
            this.messageList = [
              {receiveUsername: '徐一杰', receiveMobilePhone:'18238264105', lastMessage: '烤炉模式的城，如同打翻的调色盘一般', lastMessageTime: '2022-03-26 13:34'},
            ]
            for (let i = 0;i < successResponse.data.data.length;i++){
              successResponse.data.data[i].lastMessageTime = successResponse.data.data[i].lastMessageTime.substring(0, 16).replace('T', ' ')
              this.messageList.push(successResponse.data.data[i])
            }
            this.showSkeleton = false
          })
          .catch(() => {
            Notify('登陆过期，请重新登录')
          })
    },
    deleteMessageList(){
      Notify('就不让你删')
    },
    goFriendsApply(){
      this.showFriendsApplyDialog = true
      this.disabledPullFresh = true
      this.$nextTick(() => {
        this.$refs.friendsApply.getFriendApply()
      })
    },
    goFriendsList(){
      this.showFriendsListDialog = true
      this.disabledPullFresh = true
      this.$nextTick(() => {
        this.$refs.friendsList.getFriendList()
      })
    },
    goChatting(){
      this.show = true
      setTimeout(this.goChatting1, 1)
    },
    goChatting1(){
      this.showChattingDialog = true
      this.disabledPullFresh = true
      this.$nextTick(() => {
        this.$refs.chatting.getMessageLib()
        this.$refs.chatting.connectWebSocket()
      })
    },
    goSingleChatting(receiveMobilePhone, receiveUsername, receiveUserHead, index){
      this.show = true
      this.index = index
      this.receiveMobilePhone = receiveMobilePhone
      this.receiveUsername = receiveUsername
      this.receiveUserHead = receiveUserHead
      setTimeout(this.goSingleChatting1, 1)
    },
    goSingleChatting1(){
      this.showSingleChattingDialog = true
      this.disabledPullFresh = true
      this.$nextTick(() => {
        this.$refs.singleChatting.getSingleMessageLib()
        this.$refs.singleChatting.connectWebSocket()
      })
    },
    getShowFriendsApplyDialog(param){
      this.showFriendsApplyDialog = param
      this.disabledPullFresh = false
    },
    getShowChattingDialog(param){
      this.showChattingDialog = param
      this.disabledPullFresh = false
      setTimeout(this.destroyDialog, 300)
    },
    //这里再加一层show的原因是dialog关闭是不会自动销毁，导致下次打开时dom元素不变，延时是为了让动画不那么突兀
    destroyDialog(){
      this.show = false
    },
    getShowSingleChattingDialog(lastMessage){
      this.showSingleChattingDialog = false
      this.disabledPullFresh = false
      if (this.index !== '' && lastMessage !== ''){
        this.messageList[this.index].lastMessage = lastMessage
      }else {
        this.getMessageList()
      }
      setTimeout(this.destroyDialog, 300)
    },
    getShowFriendsListDialog(param){
      this.showFriendsListDialog = param
      this.disabledPullFresh = false
    },
    getChangeSingleChattingDialog(param1, param2, param3){
      this.showFriendsListDialog = false
      this.goSingleChatting(param1, param2, param3)
    },
    getShowAddFriendDialog(){
      this.showFriendsListDialog = false
      this.showAddFriendDialog = !this.showAddFriendDialog
    },
  }
}
</script>

<style scoped>
.mui-content{
  margin-top: 50px;
  padding-top: 5px;
  padding-bottom: 55px;
  min-height: calc(100vh - 100px);
  width: 100%;
  background-color: #f2f4f5;
}
.mui-content > .mui-table-view:first-child {
  margin-top: 0;
}
</style>
