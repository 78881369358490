/* eslint-disable no-console */

import {register} from 'register-service-worker'
// import * as registration from "register-service-worker";

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound() {
            console.log('New content is downloading.')
        },
        updated() {
            console.log('New content is available; please refresh.')
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
}

//如需卸载pwa，请先取消注释下方这一块代码，打包到线上，运行，打开网页几次，目的是清除缓存
// caches.keys()
//     .then(keys =>
//         Promise.all(
//             keys.map(async key => console.log("caches.delete", key, await caches.delete(key)))))
//     .then(async () => {
//         console.log("registration.unregister", await registration.unregister);
//     })
//     .then(() => console.log("DONE"))
//     .catch(console.error);

//然后等待24小时，再取消注释下方的代码，同时注释掉上面的注册代码，打包到线上，运行，此时pwa被卸载，才可以删除pwa的相关配置并卸载pwa包，为了保险，这段代码请在main.js里也加一份
// const serviceWorker = navigator.serviceWorker;
//
// serviceWorker.getRegistrations ? serviceWorker.getRegistrations().then(function(sws) {
//     sws.forEach(function(sw) {
//         sw.unregister().then(r => console.log(r));
//         console.log('sw unregister 1');
//     });
// }) : serviceWorker.getRegistration && serviceWorker.getRegistration().then(function(sw) {
//     sw && sw.unregister();
//     console.log('sw unregister 2');
// });
