<template>
    <div class="business">
      <h2 style="text-align: left;font-size: 16px;margin: 30px 10px 10px;color: grey">
        社区事务
      </h2>

      <van-grid v-if="!showLoading" :clickable="true" square>
        <van-grid-item @click="goBusinessApply(item.id, item.businessName)" v-for="(item) in businessList" :key="item.id" :icon="item.businessIcon" :text=item.businessName />
      </van-grid>
      <van-loading v-else color="#1989fa" />

      <van-dialog :show-confirm-button="false" style="height: 550px;width: 85%" v-model:show="showDialog" :title="currentBusinessName">
        <BusinessApply ref="businessApply" :currentBusinessName="currentBusinessName" :currentBusinessId="currentBusinessId" @getComplete="complete"></BusinessApply>
        <div style="width: 100%;position: fixed;bottom: 0;left: 0;">
          <van-button @click="postedBusinessApply" :disabled="completeSubmit" style="width: 50%;" icon="passed" type="primary">提交</van-button>
          <van-button @click="cancelShowDialog" style="width: 50%;" color="indianred" icon="close" type="primary">取消</van-button>
        </div>
      </van-dialog>

    </div>
</template>

<script>
import { Grid, GridItem, Notify, Dialog, Loading } from 'vant';
import BusinessApply from "./BusinessApplyForm/BusinessApply";
export default {
  name: "Business",
  data(){
    return{
      showDialog: false,
      showLoading: true,
      completeSubmit: false,
      businessList: [],
      currentBusinessId: '',
      currentBusinessName: '',
    }
  },
  components:{
    BusinessApply,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Loading.name]: Loading,
    [Dialog.Component.name]: Dialog.Component,
  },
  mounted() {
    this.getBusinessList()
  },
  methods:{
    getBusinessList(){
      this.$axios
          .get("/business/getBusinessList")
          .then(successResponse => {
            this.businessList = []
            for (let i = 0;i < successResponse.data.data.length;i++){
              this.businessList.push(successResponse.data.data[i])
            }
            this.showLoading = false
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    },
    goBusinessApply(param1, param2){
      this.currentBusinessId = param1
      this.currentBusinessName = param2
      this.showDialog = true
      this.$nextTick(() => {
        this.$refs.businessApply.getBusinessApplyFrom()
      })
    },
    postedBusinessApply(){
      this.completeSubmit = false
      this.$refs.businessApply.postedBusinessApply()
    },
    cancelShowDialog(){
      this.showDialog = false
    },
    complete(){
      this.showDialog = false
      this.completeSubmit = false
    },
  },
}
</script>

<style scoped>
.business{
  padding-top: 50px;
  padding-bottom: 50px;
  height: 100%;
}
</style>
