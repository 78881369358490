<template>
  <div class="mainContent">

    <van-tabs v-model:active="activeName" swipeable sticky offset-top="50px" line-width="33%" color="pink" lazy-render>
      <van-tab title="社区交流" name="社区交流">
        <Communication @showShare="getShowShare" @showDialog="getShowDialog" @postedUserMobilePhone="getPostedUserMobilePhone"></Communication>
      </van-tab>
      <van-tab title="匿名曝光" name="匿名曝光">
        <Exposure @showShare="getShowShare" @showDialog="getShowDialog" @postedUserMobilePhone="getPostedUserMobilePhone"></Exposure>
      </van-tab>
      <van-tab title="通知公告" name="通知公告">
        <Announcement></Announcement>
      </van-tab>
    </van-tabs>

    <van-share-sheet
        v-model:show="showShare"
        title="立即分享给好友"
        :options="options"
        @select="onSelect"
    />

    <van-dialog :show-confirm-button="false" style="min-height: 70%;width: 85%" v-model:show="showDialog" title="用户资料">
        <div class="main">
          <div v-if="showSkeleton" class="imgAndName">
            <van-skeleton avatar-size="50px" title-width="100%" round title avatar :row="1" />
          </div>

          <div class="imgAndName" v-else>
            <img :src="userInfo.userHead" height="50" width="50"/>
            <h1>{{ userInfo.username }}</h1>
          </div>
        </div>
        <van-grid class="grid">
          <van-grid-item>
            <p style="font-size: 1.5rem">{{ userInfo.communityNum }}</p>
            <p>TA的社区</p>
          </van-grid-item>
          <van-grid-item>
            <p style="font-size: 1.5rem">{{ userInfo.blogNum }}</p>
            <p>TA的帖子</p>
          </van-grid-item>
          <van-grid-item>
            <p style="font-size: 1.5rem">{{ userInfo.friendNum }}</p>
            <p>我的好友</p>
          </van-grid-item>
          <van-grid-item>
            <p style="font-size: 1.5rem">{{ userInfo.businessNum }}</p>
            <p>事务记录</p>
          </van-grid-item>
        </van-grid>
        <van-cell-group style="text-align: left">
          <van-cell title="性别" :value="userInfo.sex"/>
          <van-cell title="家乡" :value="userInfo.hometown"/>
          <van-cell title="个性签名" :value="userInfo.characterSignature"/>
          <van-cell title="关系" :value="userInfo.isFriend"/>
        </van-cell-group>
      <div style="width: 100%;position: absolute;bottom: 45px;left: 0">
        <van-button @click="sendFriendApply" :disabled="addFriendButtonDisabled" style="width: 50%;" icon="plus" type="primary">加好友</van-button>
        <van-button @click="sendSecretMessage" style="width: 50%;" color="palevioletred" icon="envelop-o" type="primary">私信TA</van-button>
      </div>

      <van-button @click="this.showDialog=false" style="width: 100%;position: absolute;bottom: 0;left: 0" type="default">知道了</van-button>

    </van-dialog>

  </div>
</template>

<script>
//vue-router里的方法只能在setup中使用
import {useRoute} from "vue-router";
import {sendNotify} from "@/js/globalWebSocket";
import {Notify, Skeleton, Field} from 'vant';
import { Grid, GridItem } from 'vant';
import { Cell, CellGroup } from 'vant';
import {ref} from "vue";
import { Dialog } from 'vant';
import {ShareSheet, Toast} from 'vant';
import { Tab, Tabs } from 'vant';
import Announcement from "./components/Announcement";
import Exposure from "./components/Exposure";
import Communication from "./components/Communication";

export default {
  name: "MainContent",
  setup(){
    //切换tabs
    const activeName = ref('社区交流');
    //分享
    const options = [
      { name: '微信', icon: 'wechat' },
      { name: 'QQ', icon: 'qq' },
      { name: '复制链接', icon: 'link' },
      { name: '二维码', icon: 'qrcode' },
    ];
    //路由传参接收
    const route = useRoute()
    const getRouteParams = () => {
      if ((route.query.activeName??'') !== ''){
        activeName.value = route.query.activeName
      }
    }

    return {
      activeName,
      options,
      route,
      getRouteParams,
    };
  },
  data(){
    return{
      showShare: false,
      showDialog: false,
      showSkeleton: true,
      userInfo: [],
      postedUserMobilePhone: '',
      addFriendButtonDisabled: true,
    }
  },
  components: {
    Communication,
    Exposure,
    Announcement,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [ShareSheet.name]: ShareSheet,
    [Dialog.Component.name]: Dialog.Component,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Skeleton.name]: Skeleton,
    [Field.name]: Field,
  },
  mounted() {
    this.getRouteParams()
  },
  watch:{
    showDialog() {
      if (this.showDialog){
        this.getUserInfoAndRelationship()
      }
    }
  },
  methods:{
    onSelect (option){
      Toast(option.name);
      if (option.name === 'QQ'){
        window.open('https://im.qq.com/index',"_blank")
      }
      this.showShare = false
    },
    getShowShare(param){
      this.showShare = param
    },
    getShowDialog(param){
      this.showDialog = param
    },
    getPostedUserMobilePhone(param){
      this.postedUserMobilePhone = param
    },
    getUserInfoAndRelationship(){
      this.showSkeleton = true
      this.addFriendButtonDisabled = true
      if (this.showDialog){
        this.$axios
            .get("/user/getUserInfoAndRelationship",{
              params:{
                mobilePhone: this.postedUserMobilePhone
              }
            })
            .then(successResponse => {
              this.userInfo = []
              this.userInfo = successResponse.data.data
              this.addFriendButtonDisabled = this.userInfo.isFriend === '好友';
              this.showSkeleton = false
            })
      }
    },
    sendFriendApply(){
      Dialog.confirm({
        message: '发送好友请求',
      })
      .then(() => {
        let message = {}
        message.type = '好友申请'
        message.message = '申请添加您为好友'
        message.sendUsername = JSON.parse(sessionStorage.getItem('userInfo')).username
        message.sendMobilePhone = localStorage.getItem("mobilePhone")
        message.receiveMobilePhone = this.postedUserMobilePhone
        sendNotify(JSON.stringify(message))
        // Notify({ type: 'primary', message: '耐心等待回信哟' });
      })
      .catch(() => {

      });
    },
    sendSecretMessage(){
      Notify({ type: 'primary', message: '此功能暂不对普通用户开放' });
    },
  },

};
</script>

<style scoped>
.mainContent{
  padding-top: 50px;
  padding-bottom: 40px;
  height: 100vh;
}
/*弹出框布局*/
.main{
  margin-top: 20px;
  height: 100%;
  width: 100%;
}
.imgAndName{
  height: 50px;
  width: 100%;
  margin: 0 auto;
}
.imgAndName img{
  margin-left: 20px;
  border-radius: 50%;
}
.imgAndName h1{
  position: relative;
  bottom: 14px;
  display: inline-block;
  font-size: 2rem;
  margin: 0 20px;
}
.grid{
  margin-top: 20px;
  width: 100%;
}
</style>
