<template>
  <div>
    <h2>注册</h2>
    <input class="a" type="text" v-model="username" placeholder="给自己起个好听的名字吧">
    <input class="a" type="text" inputmode="email" v-model="email" placeholder="输入邮箱">
    <input class="a" type="text" inputmode="tel" v-model="mobilePhone" placeholder="输入手机号作为账号">
    <input class="a" type="password" v-model="password" placeholder="请输入密码">
    <input class="a" type="password" v-model="rePassword" placeholder="请再次输入密码">
    <button value="注册" @click="register" :disabled="disabled">注册</button>
  </div>
</template>

<script>
import {SM4Util} from '@/utils/sm4';
import {Notify} from "vant";

export default {
  name: "Register",
  data() {
    return {
      username: '',
      email: '',
      mobilePhone: '',
      password: '',
      rePassword: '',
      disabled: false
    }
  },
  components: {
    [Notify.Component.name]: Notify.Component
  },
  methods: {
    register() {
      this.disabled = true
      let username = this.username.replaceAll(' ', '')
      let mobilePhone = this.mobilePhone.replaceAll(' ', '')
      let password = this.password.replaceAll(' ', '')
      const phoneReg = new RegExp(/^\d{11}$/);
      if (username === '') {
        Notify('还没取个好听的名字呢')
        this.disabled = false
      } else if (username.length > 6) {
        Notify('昵称最多6个字哦')
        this.disabled = false
      } else if (!this.email.includes('@')) {
        Notify('请输入正确的邮箱')
        this.disabled = false
      } else if (!phoneReg.test(mobilePhone)) {
        Notify('仅支持中国大陆手机号')
        this.disabled = false
      } else if (password === '') {
        Notify('密码呢？')
        this.disabled = false
      } else if (password !== this.rePassword.replaceAll(' ', '')) {
        Notify('两次输入的密码不同')
        this.disabled = false
      } else {
        // sm4加密
        const sm4 = new SM4Util();
        this.$axios
            .post("/user/register", {
              username: username,
              email: this.email.replaceAll(' ', ''),
              mobilePhone: mobilePhone,
              password: sm4.encryptDefault_CBC(password),
            })
            .then(successResponse => {
              if (successResponse.data.code === 2000) {
                this.$parent.flag = true
                this.$emit('changeFlag')
                Notify({type: 'success', message: '注册成功，点击邮件内链接验证邮箱'})
              } else if (successResponse.data.code === 5013) {
                Notify('此邮箱已被占用')
              } else if (successResponse.data.code === 5014) {
                Notify('这个手机号好像已经注册过了哦')
              } else if (successResponse.data.code === 5005) {
                Notify('校验未通过，请检查邮箱手机号等')
              }
              this.disabled = false
            })
            .catch(() => {
              Notify('哎呀网有点慢，好像没有保存上呢，稍后再点一次吧')
              this.disabled = false
            })
      }
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 2rem;
}

.a {
  width: 90%;
  height: 35px;
  border-radius: 7px;
  border: 1px solid;
}

input {
  margin-top: 20px;
  outline: 0 solid;
  display: inline-block;
  transition: all 0.3s ease;
}

button {
  width: 90%;
  height: 35px;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border: 1px solid;
  outline: 0 solid;
  display: block;
  margin: 20px auto;
  transition: all 0.05s ease;
}

input:focus {
  box-shadow: 0 0 30px pink;
  background-color: paleturquoise;
  transform: scale(1.05, 1.05);
  transition: all 0.3s ease;
}

button:focus {
  box-shadow: 5px 5px 10px pink;
  background-color: paleturquoise;
}

button:hover {
  box-shadow: 5px 5px 10px pink;
  background-color: paleturquoise;
  transform: scale(1.05, 1.05);
  transition: all 0.5s ease;
}

button:active {
  box-shadow: 0 0 10px yellow;
  background-color: pink;
  transform: scale(1, 1);
  transition: all 0.05s ease;
}

@keyframes change {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
