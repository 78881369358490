<template>
  <div style="height: 100%;overflow: auto">
    <div class="header">
      <router-link tag="div" :to="{name:'UserInfo'}">
        <div v-if="showSkeleton" style="width: 100%;height: 80px;position:absolute;padding-top: 140px;">
          <van-skeleton avatar-size="60px" title-width="100%" round title avatar :row="1" />
        </div>

        <div class="imgAndName" v-else>
          <img :src="userInfo.userHead" height="60" width="60"/>
          <h1>{{ userInfo.username }}</h1>
          <van-icon class="arrow" name="arrow" />
        </div>
      </router-link>
      <van-grid class="grid">
        <van-grid-item @click="showMyCommunities">
          <p style="font-size: 1.5rem">{{ userInfo.communityNum }}</p>
          <p>我的社区</p>
        </van-grid-item>
        <van-grid-item @click="showMyBlogs">
          <p style="font-size: 1.5rem">{{ userInfo.blogNum }}</p>
          <p>我的帖子</p>
        </van-grid-item>
        <van-grid-item @click="showMyFriends">
          <p style="font-size: 1.5rem">{{ userInfo.friendNum }}</p>
          <p>我的好友</p>
        </van-grid-item>
        <van-grid-item @click="showMyBusiness">
          <p style="font-size: 1.5rem">{{ userInfo.businessNum }}</p>
          <p>事务记录</p>
        </van-grid-item>
      </van-grid>
    </div>

      <van-grid :clickable="true" class="grid2">
        <van-grid-item icon="wap-nav" text="个人资料" :to="{name:'UserInfo',params:{userInfo: JSON.stringify(this.userInfo)}}" />
        <van-grid-item to="/home/mine/writeLetterToMe" icon="smile-comment" text="给我写信" />
        <van-grid-item icon="gift" to="/home/mine/myMail" text="我的信件" />
        <van-grid-item to="/home/mine/accountSystemSetting" icon="setting" text="账号设置" />
        <van-grid-item v-if="this.user.isSuperAdmin || this.userInfo.isCommunityAdmin" icon="manager" to="/home/mine/administration" text="管理员选项" />
        <van-grid-item v-else icon="manager" @click="showPermissionDenied" text="管理员选项" />
        <van-grid-item v-if="this.user.isSuperAdmin || this.userInfo.isCommunityAdmin" icon="todo-list" to="/home/mine/LoginLog" text="登录记录" />
        <van-grid-item v-else icon="todo-list" @click="showPermissionDenied" text="登录记录" />
        <van-grid-item v-if="this.user.isSuperAdmin || this.userInfo.isCommunityAdmin" icon="eye" to="/home/mine/OperationLog" text="操作记录" />
        <van-grid-item v-else icon="eye" @click="showPermissionDenied" text="操作记录" />
        <van-grid-item to="/home/mine/404" icon="photo-o" text="404模拟" />
        <van-grid-item icon="invitation" url="https://xuyijie.icu" text="Infinity" />
      </van-grid>

    <van-button class="button" @click="show = true" round block type="warning">
      退出登录
    </van-button>
    <van-popup v-model:show="show" position="bottom" round :style="{ height: '25%' }">
      <p style="font-size: 1.2rem;margin-top: 20px">真的要退出吗</p>
      <van-button class="button" @click="loginOut" style="position: absolute;bottom: 50%;left: 2.5%;width: 95%" block round color="indianred" type="warning">
        真的
      </van-button>
      <van-button class="button" @click="cancelLoginOut" style="position: absolute;bottom: 15%;left: 2.5%;width: 95%" round block type="primary">
        假的
      </van-button>
    </van-popup>

    <van-dialog :show-confirm-button="false" style="overflow: auto;max-height: 85%;width: 90%;margin-top: 40px" title="我的社区" v-model:show="showMyCommunitiesDialog">
      <MyCommunities style="padding-bottom: 45px" ref="myCommunities"></MyCommunities>
      <div style="width: 100%;position: absolute;bottom: 0;left: 0">
        <van-button @click="this.$router.push({name: 'CommunityCertification'})" style="width: 50%;" icon="invitation" type="primary">加入社区</van-button>
        <van-button @click="this.showMyCommunitiesDialog = false" style="width: 50%;" color="indianred" icon="clear" type="warning">返回</van-button>
      </div>
    </van-dialog>

    <van-dialog v-if="destroy" :show-confirm-button="false" style="overflow: auto;max-height: 85%;width: 90%;margin-top: 40px" v-model:show="showMyBlogsDialog">
      <MyBlogs @showMyBlogsDialog="getShowMyBlogsDialog" ref="myBlogs"></MyBlogs>
    </van-dialog>

    <van-dialog v-if="destroy" :show-confirm-button="false" style="height: 85%;width: 90%;margin-top: 40px" :show-cancel-button="false" v-model:show="showFriendsListDialog">
      <FriendsList :isMyFriendList="'1'" @showFriendsListDialog="getShowFriendsListDialog" @changeSingleChattingDialog="getChangeSingleChattingDialog" ref="myFriends"></FriendsList>
    </van-dialog>
    <van-dialog v-if="destroy" :show-confirm-button="false" style="width: 90%;margin-top: 40px" :show-cancel-button="false" v-model:show="showSingleChattingDialog">
      <SingleChatting :receiveMobilePhone="toUserMobilePhone" :receiveUsername="toUsername" :receiveUserHead="toUserHead" @showSingleChattingDialog="getShowSingleChattingDialog" ref="singleChatting"></SingleChatting>
    </van-dialog>

    <van-dialog v-if="destroy" :show-confirm-button="false" style="overflow: auto;max-height: 85%;width: 90%;margin-top: 40px" title="事务记录" v-model:show="showMyBusinessDialog">
      <MyBusiness @showMyBusinessDialog="getShowMyBusinessDialog" ref="myBusiness"></MyBusiness>
    </van-dialog>

  </div>
</template>

<script>
import router from "@/router";
import {Icon, Notify, Dialog, Grid, GridItem, Popup, Skeleton} from 'vant';
import MyCommunities from "./components/MyCommunities";
import FriendsList from "@/views/Message/components/FriendsList";
import SingleChatting from "../Message/components/SingleChatting";
import MyBlogs from "./components/MyBlogs";
import MyBusiness from "./components/MyBusiness";
export default {
  name: "Mine",
  components:{
    MyBusiness,
    MyBlogs,
    SingleChatting,
    FriendsList,
    MyCommunities,
    [Icon.name]: Icon,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Skeleton.name]: Skeleton,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component,
  },
  data(){
    return{
      showMyCommunitiesDialog: false,
      showFriendsListDialog: false,
      showSingleChattingDialog: false,
      showMyBlogsDialog: false,
      showMyBusinessDialog: false,
      destroy: false,
      username: '',
      userHeadUrl: '',
      communityNum: 0,
      blogNum: 0,
      friendNum: 0,
      businessNum: 0,
      sex: '',
      age: 0,
      placeResult: '',
      characterSignature: '',
      userInfo: {},
      showSkeleton: true,
      show: false,
      user: {},
      toUserMobilePhone: '',
      toUsername: '',
      toUserHead:'',
    }
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.getUserInfo()
  },
  methods:{
    showPermissionDenied(){
      Notify({type: 'warning', message: '这是超管的功能哦！'})
    },
    getUserInfo(){
      //这里很神奇，从sessionStorage里获取的数据既可能是字符串null，也可能是null
      if (sessionStorage.getItem('userInfo') === 'null' || (sessionStorage.getItem('userInfo')??'') === ''){
        this.$axios
            .get("/user/getUserInfo")
            .then(successResponse => {
              this.userInfo = successResponse.data.data
              this.showSkeleton = false
              sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
            })
            .catch(() => {
              Notify('登录过期，请重新登录')
            })
      }else {
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        this.showSkeleton = false
      }
    },
    loginOut() {
      this.$axios
          .put("/user/loginOut")
          .then(() => {
            router.replace('/index')
            Notify({type: 'success', message: '拜拜~下次见~'})
          })
          .catch(() => {
            router.replace('/index')
            Notify({type: 'success', message: '拜拜~下次见~'})
          })
    },
    cancelLoginOut(){
      this.show = false
      Notify({message: '爱你😘', color: '#ad0000', background: '#ffe1e1',});
    },
    showMyCommunities(){
      this.showMyCommunitiesDialog = true
    },
    showMyFriends(){
      this.destroy = true
      setTimeout(this.showMyFriends1, 1)
    },
    showMyFriends1(){
      this.showFriendsListDialog = true
      this.$nextTick(() => {
        this.$refs.myFriends.getFriendList()
      })
    },
    showMyBusiness(){
      this.destroy = true
      setTimeout(this.showMyBusiness1, 1)
    },
    showMyBusiness1(){
      this.showMyBusinessDialog = true
      this.$nextTick(() => {
        this.$refs.myBusiness.getMyBusinessApply()
      })
    },
    getShowMyBlogsDialog(param){
      this.showMyBlogsDialog = param
      setTimeout(this.destroyDialog, 300)
    },
    getShowMyBusinessDialog(param){
      this.showMyBusinessDialog = param
      setTimeout(this.destroyDialog, 300)
    },
    //这里再加一层destroy的原因是dialog关闭是不会自动销毁，导致一些问题
    destroyDialog(){
      this.destroy = false
    },
    getShowFriendsListDialog(param){
      this.showFriendsListDialog = param
      setTimeout(this.destroyDialog, 300)
    },
    getChangeSingleChattingDialog(param1, param2, param3){
      this.showFriendsListDialog = false
      this.goSingleChatting(param1, param2, param3)
    },
    goSingleChatting(toUserMobilePhone, toUsername, toUserHead){
      this.toUserMobilePhone = toUserMobilePhone
      this.toUsername = toUsername
      this.toUserHead = toUserHead
      this.showSingleChattingDialog = true
      this.$nextTick(() => {
        this.$refs.singleChatting.getSingleMessageLib()
        this.$refs.singleChatting.connectWebSocket()
      })
    },
    getShowSingleChattingDialog(){
      this.showSingleChattingDialog = false
      setTimeout(this.destroyDialog, 300)
    },
    showMyBlogs(){
      this.destroy = true
      setTimeout(this.showMyBlogs1, 1)
    },
    showMyBlogs1(){
      this.showMyBlogsDialog = true
    },
  }
}
</script>

<style scoped>
a{
  text-decoration: none;
  color: white;
}
.header{
  width: 100%;
  margin-top: 50px;
  height: 220px;
  background-image: linear-gradient(100deg, #fcc9d2, #e092e0, #c5bc5c, orange);
}
.imgAndName{
  height: 100%;
  float: left;
  padding-top: 140px;
}
.header img{
  float: left;
  margin-left: 20px;
  border-radius: 50%;
}
.header h1{
  float: left;
  font-size: 2rem;
  height: 80px;
  line-height: 60px;
  margin: 0 20px;
}
.arrow{
  font-size: 2rem;
  position: absolute;
  line-height: 60px;
  right: 5%;
}
.grid{
  height: 60px;
  width: 100%;
  position: absolute;
  top: 270px;
}
.grid2{
  width: 100%;
  margin-top: 110px;
}
.button{
  position: absolute;
  bottom: 60px;
}
a:active{
  color: white;
}
</style>