<template>
  <div>
    <van-nav-bar
        title=操作记录
        left-text="返回"
        fixed
        left-arrow
        style="padding-top: 50px"
        @click-left="onClickLeft"
    />

    <van-pull-refresh style="background-color: #ffd45d;padding-top: 95px" pulling-text="别拉我！" loosing-text="畜生 快松开我！" loading-text="哎呦歪" v-model="loading" @refresh="pullFresh">

      <div style="min-height: calc(100vh - 140px);background-color: #f2f4f5;padding-top: 5px;">
        <van-cell-group>
          <van-cell @click="showDialog(index)" :title="'操作人：' + displayUsername(item)" title-style="text-align: left" :label="item.operationTime" :value="'模块：' + item.operationModule" v-for="(item, index) in operationLogList" :key="item.id" />
        </van-cell-group>
        <div style="height: 50px;line-height: 50px;margin-bottom: 50px">
          <p v-if="(this.operationLogList.length < this.pageTotal) && !this.showLoading" @click="getOperationLog">点击加载更多</p>
          <p v-if="this.operationLogList.length >= this.pageTotal">我也是有底线的</p>
          <van-loading v-if="this.showLoading" color="#1989fa" />
        </div>
      </div>

    </van-pull-refresh>

    <van-dialog teleport="#app" v-model:show="show" title="操作详情">
      <van-cell-group>
        <van-cell title="操作人" :value="displayUsername(operationLogList[this.index])" title-style="text-align: left" />
        <van-cell title="操作时间" :value="operationLogList[this.index].operationTime" title-style="text-align: left" />
        <van-cell title="操作IP" :value="operationLogList[this.index].operationIp" title-style="text-align: left" />
        <van-cell title="操作模块" :value="operationLogList[this.index].operationModule" title-style="text-align: left" />
        <van-cell title="操作详情" :value="operationLogList[this.index].operationDescription" title-style="text-align: left" />
        <van-field
            v-model="operationLogList[this.index].requestUrl"
            rows="1"
            autosize
            label="请求路径"
            type="textarea"
            readonly
        />
        <van-cell title="请求方式" :value="operationLogList[this.index].requestMethod" title-style="text-align: left" />
        <van-cell title="请求时长" :value="operationLogList[this.index].operationPeriod + 'ms'" title-style="text-align: left" />
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import {ref} from "vue";
import {Notify, PullRefresh, Toast, Dialog, Cell, CellGroup, Field, NavBar, Loading} from "vant";
export default {
  name: "OperationLog",
  setup(){
    const onClickLeft = () => history.back();
    //下拉刷新
    const count = ref(0);
    const loading = ref(false);

    return {
      onClickLeft,
      count,
      loading,
    };
  },
  data(){
    return{
      operationLogList: [],
      usernameList: [],
      show: false,
      index: 0,
      pageNum: 1,
      pageQuantity: 10,
      pageTotal: 0,
      searchString: '',
      showLoading: false,
    }
  },
  components: {
    [PullRefresh.name]: PullRefresh,
    [Toast.name]: Toast,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Loading.name]: Loading,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
  },
  mounted() {
    this.getOperationLog()
  },
  methods:{
    showDialog(index){
      this.show = true
      this.index = index
    },
    pullFresh(){
      this.pageNum = 1
      this.operationLogList = []
      this.getOperationLog()
      setTimeout(() => {
        Toast({
          message: '刷新成功',
          icon: 'like-o',
        });
        this.loading = false;
      }, 500);
    },
    getOperationLog(){
      this.showLoading = true
      this.$axios
          .get("/loginAndOperationLog/getOperationLog", {
            params:{
              searchString: '',
              pageNum: this.pageNum++,
              pageQuantity: this.pageQuantity
            }
          })
          .then(successResponse => {
            this.pageTotal = successResponse.data.data.total
            let operationLogUserIdList = []
            for (let i = 0;i < successResponse.data.data.records.length;i++){
              const operationLog = successResponse.data.data.records[i]
              this.operationLogList.push(operationLog)
              operationLogUserIdList.push(operationLog.operatorUserId)
            }
            operationLogUserIdList = new Set(operationLogUserIdList)
            operationLogUserIdList.forEach(item => {
              this.getUserInfo(item)
            })
            this.showLoading = false
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    },
    getUserInfo(userId) {
      this.$axios
          .get("/user/getUserInfoById", {
            params:{
              id: userId,
            }
          })
          .then(successResponse => {
            const data = successResponse.data.data
            this.usernameList.push({id: data.id, username: data.username})
          })
    },
    displayUsername(operationLog) {
      let username = ''
      this.usernameList.some(item => {
        if (item.id === operationLog.operatorUserId) {
          username = item.username
          return true
        }
      })
      return username
    }
  }
}
</script>

<style scoped>

</style>
