<template>
  <div id="home">
    <Header></Header>

    <router-view v-slot="{ Component }">
      <transition name="scale" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "./components/Footer";
import Header from "./components/Header";
import {connectGlobal, sendNotify} from "@/js/globalWebSocket";
export default {
  name: "Home",
  components:{
    Header,
    Footer,
  },
  data(){
    return {
      // transitionName:'',
    }
  },
  mounted() {
    //连接全局通知
    setTimeout(connectGlobal, 3000)
    //心跳检测
    this.timer = setInterval(this.start, 55000);
  },
  // watch: {//使用watch 监听$router的变化
  //   $route(to, from) {
  //     //如果to索引大于from索引,判断为前进状态,反之则为后退状态
  //     if(to.meta.index > from.meta.index){
  //       //设置动画名称
  //       this.transitionName = 'van-slide-right';
  //     }else{
  //       this.transitionName = 'van-slide-left';
  //     }
  //   }
  // },
  methods:{
    // 心跳检测, 每隔一段时间检测连接状态，如果处于连接中，
    // 就向server端主动发送消息，来重置server端与客户端的最大连接时间，如果已经断开了，发起重连。
    start() {
      let message = {}
      message.type = '心跳检测'
      sendNotify(JSON.stringify(message))
    }
  }
}
</script>

<style scoped>
 #home{
   height: 100%;
   background-color: #f2f4f5;
 }

 .scale-enter-active,
 .scale-leave-active {
   transition: all 0.2s ease;
 }
 .scale-enter-from,
 .scale-leave-to {
   opacity: 0;
   transform: scale(0.98);
 }
</style>
