<template>
  <div class="shareMessage">
    <van-nav-bar
        title="发帖"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <br>

    <van-cell-group inset>
      <van-field
          v-model="belongPlate"
          type="textarea"
          style="height: 55px"
          is-link
          readonly
          name="picker"
          label="版块"
          placeholder="点击选择发贴版块"
          @click="showPickerPlate = true"
      />
      <van-popup v-model:show="showPickerPlate" position="bottom">
        <van-picker
            :columns="columns"
            @confirm="onConfirmPlate"
            @cancel="showPickerPlate = false"
        />
      </van-popup>

      <van-field v-if="this.belongPlate === '通知公告'" style="height: 55px" type="textarea" v-model="title"
                 label="标题" placeholder="请输入公告标题" maxlength="10"/>

      <van-field
          v-if="this.belongPlate === '通知公告'"
          v-model="belongCommunityString"
          type="textarea"
          style="height: 55px"
          is-link
          readonly
          name="picker"
          label="社区"
          placeholder="不选则通知管理的全部社区"
          @click="showPickerCommunity = true"
      />
      <van-popup v-model:show="showPickerCommunity" position="bottom">
        <van-picker
            :columns="columnsCommunity"
            @confirm="onConfirmCommunity"
            @cancel="showPickerCommunity = false"
        />
      </van-popup>

      <van-field
          v-model="content"
          rows="10"
          autosize
          label="正文"
          type="textarea"
          maxlength="150"
          placeholder="请输入正文"
          show-word-limit
      />
    </van-cell-group>

    <br>

    <van-uploader v-model="fileList" :before-read="beforeRead" :after-read="afterRead" upload-text="最多可选9张"
                  :max-count="9"/>

    <div style="margin: 16px;">
      <van-button :disabled="completeSubmit" v-if="this.belongPlate === '通知公告'" @click="submitAnnouncement"
                  style="height: 40px" round block type="primary">
        发布
      </van-button>
      <van-button :disabled="completeSubmit" v-else @click="submit" style="height: 40px" round block type="primary">
        发布
      </van-button>
    </div>

  </div>
</template>

<script>
import router from "@/router";
//修复苹果手机图片旋转问题,npm install compressorjs
import Compressor from 'compressorjs';
import {compressImage, convertBase64UrlToBlob} from "@/utils/CompressImageUtils";
import {reactive, ref} from "vue";
import {CellGroup, Field, Form, NavBar, Notify, Picker, Popup, Toast, Uploader} from "vant";
import heic2any from "heic2any";

export default {
  name: "ShareMessage",
  setup() {
    //上传的图片列表
    const fileList = ref([
      // {
      //   url: 'https://img.yzcdn.cn/vant/leaf.jpg',
      //   status: 'uploading',
      //   message: '上传中...',
      // },
    ]);
    //弹出选择器
    const belongPlate = ref('');
    const belongCommunityString = ref('');
    const showPickerPlate = ref(false);
    const showPickerCommunity = ref(false);
    const isDisablePicker = ref(true);
    const columns = reactive([{text: '社区交流'}, {text: '匿名曝光'}, {text: '通知公告', disabled: isDisablePicker}]);
    const columnsCommunity = reactive([]);
    const imageTypeList = ["image/png", "image/jpg", "image/jpeg", "image/gif", "image/bmp", "image/xbm", "image/tif", "image/tiff", "image/jfif", "image/webp", "image/svg", "image/svgz", "image/ico", "image/pjpeg", "image/avif", "image/pjp"]

    const onConfirmPlate = (value) => {
      belongPlate.value = value.text;
      showPickerPlate.value = false;
    };
    const onConfirmCommunity = (value) => {
      belongCommunityString.value = value;
      showPickerCommunity.value = false;
    };
    //普通输入框
    const onClickLeft = () => history.back();
    const title = ref('');
    const content = ref('');
    //修复苹果手机图片旋转问题
    const beforeRead = (file) =>
        new Promise((resolve) => {
          console.log(file)
          console.log(resolve)
          resolve()
          // compressorjs 默认开启 checkOrientation 选项
          // 在afterRead里面对苹果照片进行旋转修复，因为这个Compressor不支持heic图片，要先转码
          // new Compressor(file, {
          //   quality: 1, //这个东西设小一点也能压缩图片，但是效果不好，我们不用，设为1
          //   success: resolve,
          //   error(err) {
          //     console.log(err.message);
          //     Notify('成本原因，暂不支持heic格式图片')
          //   },
          // });
        });

    return {
      onClickLeft,
      title,
      content,
      fileList,
      imageTypeList,
      belongPlate,
      belongCommunityString,
      columns,
      columnsCommunity,
      onConfirmPlate,
      onConfirmCommunity,
      isDisablePicker,
      showPickerPlate,
      showPickerCommunity,
      beforeRead,
    };
  },
  data() {
    return {
      imgList: [],
      user: {},
      userInfo: {},
      completeSubmit: false,
    }
  },
  components: {
    [Uploader.name]: Uploader,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Form.name]: Form,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Toast.name]: Toast
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if (this.user.isSuperAdmin && this.userInfo.isCommunityAdmin) {
      this.isDisablePicker = false
    }
  },
  methods: {
    afterRead() {
      this.completeSubmit = true
      //如果不赋值为空，第二次添加的时候会把前面添加过的图片再次上传一次
      this.imgList = []
      if (this.fileList.length > 0) {
        for (let i = 0; i < this.fileList.length; i++) {
          //进行图片类型 heic 转换
          this.exchangeImageType(i, this.afterTransferImageType)
        }
      }
      // console.log(this.fileList.length)
      // console.log(this.fileList)
      // console.log(this.imgList)
    },

    //进行图片类型（heic）转换
    exchangeImageType(i, callback) {
      //判断是不是heic图片，base64的前缀为octet-stream，文件type一般为''
      if (this.imageTypeList.includes(this.fileList[i].file.type)) {
        Notify({type: 'primary', message: '正在压缩图片，请稍等'})
        callback(i, this.fileList[i].content)
      } else {
        Notify({type: 'primary', message: '正在进行heic图片转码，请等待'})
        let blob = convertBase64UrlToBlob(this.fileList[i].content);
        heic2any({
          blob,  // 将heic转换成一个buffer数组的图片
          toType: 'image/png', //要转化成具体的图片格式，可以是png/gif，实测都是转为png
          quality: 1   // 图片的质量，参数在0-1之间
        }).then(result => {
          let file = new FileReader();
          file.readAsDataURL(result);
          file.onload = function (imageFile) {
            let imgBase64 = imageFile.target.result;
            callback(i, imgBase64)
            Notify({type: 'success', message: '转码成功，不支持heic缩略图显示，发布后可查看'})
          };
        }).catch(error => {
          console.log(error)
          Notify({type: 'danger', message: '转码失败'})
          callback(i, this.fileList[i].content)
        })
      }
    },

    //FileReader的onload回调函数，解决异步数据获取问题
    async afterTransferImageType(i, result) {
      this.fileList[i].content = result
      //修复苹果手机图片旋转问题
      this.fileList[i].file = await this.fixRotate(this.fileList[i])
      //图片压缩
      this.compressAndAddFile(this.fileList[i]);
      this.completeSubmit = false
    },

    fixRotate(file) {
      return new Promise(resolve => {
        new Compressor(convertBase64UrlToBlob(file.content), {
          quality: 1, //这个东西设小一点也能压缩图片，但是效果不好，我们不用，设为1
          success(data) {
            resolve(data)
          },
          error(err) {
            console.log(err.message);
            resolve(file.file)
          },
        });
      })
    },

    //压缩图片上传
    compressAndAddFile(file) {
      if (file.file.size > 1024000) {
        compressImage(file.content).then(result => {
          // console.log('压缩后的结果', result);
          // console.log('压缩前大小', file.content.length);
          // console.log('压缩后大小', result.length);
          if (result.length > file.content.length) {
            //压缩后比原来更大，则将原图上传
            this.imgList.push(file.content)
          } else {
            //压缩后比原来小，上传压缩后的
            this.imgList.push(result)
          }
        }).catch(failResponse => {
          console.log(failResponse)
          this.imgList.push(file.content)
        })
      } else {
        this.imgList.push(file.content)
      }
    },

    submit() {
      if (this.belongPlate === '') {
        Notify('需要选择所属板块哦')
      } else if (this.content === '') {
        Notify('不可以发送空白帖哟')
      } else {
        this.completeSubmit = true
        Notify({type: 'primary', message: '正在上传...'});
        this.$axios
            .post("/blog/posted", {
              belongPlate: this.belongPlate,
              blogContent: this.content,
              blogImg: this.imgList,
              postedUserHead: this.userInfo.userHead,
            })
            .then(successResponse => {
              if (successResponse.data.code === 2000) {
                Notify({type: 'success', message: '发表成功'})
                //因为发帖会更新帖子数量，所以重新请求用户信息
                this.$axios
                    .get("/user/getUserInfo")
                    .then(successResponse => {
                      this.userInfo = successResponse.data.data
                      sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
                      //vue-router在4.1版本以后，路由传参出现了大变动，一般来讲，name搭配params传参会失败
                      router.replace({path: 'MainContent', query: {activeName: this.belongPlate}})
                    })
                    .catch(() => {
                      Notify('登录过期，请重新登录')
                    })
              } else if (successResponse.data.code === 4001) {
                Notify('登录过期，重新登录')
                router.replace('/')
              }
              this.completeSubmit = false
            })
            .catch(() => {
              this.completeSubmit = false
              Notify('登录过期，请重新登录')
            })
      }
    },

    getCommunityList() {
      this.$axios
          .get("/community/getCommunity")
          .then(successResponse => {
            for (let i = 0; i < successResponse.data.data.length; i++) {
              this.columnsCommunity.push(successResponse.data.data[i].communityName)
            }
          })
          .catch(() => {
            Notify('登录过期，请重新登录')
          })
    },

    submitAnnouncement() {
      if (this.title.trim() === '') {
        Notify('公告需要有标题')
      } else if (this.content === '') {
        Notify('不可以发送空白公告哟')
      } else {
        this.completeSubmit = true
        let belongCommunity = []
        if (this.belongCommunityString === '') {
          if (this.user.isSuperAdmin) {
            belongCommunity.push('全区公告')
          } else {
            belongCommunity = this.user.belongCommunity
          }
        } else {
          belongCommunity.push(this.belongCommunityString)
        }
        this.$axios
            .post("/announcement/posted", {
              title: this.title,
              belongCommunity: belongCommunity,
              content: this.content,
              img: this.imgList
            })
            .then(successResponse => {
              if (successResponse.data.code === 2000) {
                router.replace({path: 'MainContent', query: {activeName: this.belongPlate}})
                Notify({type: 'success', message: '发表成功'})
                this.userInfo.blogNum++
                sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
              } else if (successResponse.data.code === 4001) {
                Notify('登录过期，重新登录')
                router.replace('/')
              }
              this.completeSubmit = false
            })
            .catch(() => {
              this.completeSubmit = false
              Notify('登录过期，请重新登录')
            })
      }
    }
  },
  watch: {
    belongPlate(newQuestion, oldQuestion) {
      if (newQuestion === '通知公告') {
        this.getCommunityList()
      }
      console.log(oldQuestion)
    }
  },
}
</script>

<style scoped>
.shareMessage {
  background-color: #f2f4f5;
  height: 800px;
  width: 100%;
  padding-top: 50px;
}

h5 {
  margin: 5px 7px;
}

.van-uploader {
  width: 100%;
  text-align: left;
  padding: 15px;
}
</style>
